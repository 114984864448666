import { useState, useEffect } from 'react'
import axios from 'axios'

interface User {
  id: number
  name: string
}

const useOfficeUsers = (officeId?: number) => {
  const [users, setUsers] = useState<User[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get<User[]>(
          '/api/v1/drivers/office_drivers',
          {
            params: { office_id: officeId },
          }
        )
        setUsers(response.data)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchUsers()
  }, [officeId])

  return { users, loading, error }
}

export default useOfficeUsers
