import * as React from 'react'
import { Stack, StackProps } from '@mui/material'
import { theme } from 'components/theme'

// 項目グルーピング形式の詳細画面用FlexBox
// Deprecated: Use Stack with responsive direction directly
export const DetailFlexBox: React.FC<{
  children?: React.ReactNode | string
}> = (props) => {
  console.warn('DetailFlexBox is deprecated. Use Stack with responsive direction directly.')
  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      alignItems="center"
      {...props}
    >
      {props.children}
    </Stack>
  )
}
