import * as React from 'react'
import {
  ADMIN_ROUTES,
  COMPANIES,
  TABLE_DEFAULT_ROWS,
  LOCAL_STORAGE_PAGE_KEY,
} from 'commons/constants'
import EnhancedTable, { HeadCell } from 'components/atoms/TableComponents'
import {
  getLocalStorageDisplayLimit,
  getLocalStoragePageNumber,
} from 'commons/table'
import SearchForm from './SearchForm'
import { Order } from 'commons/array'

const headCells: readonly HeadCell<Company>[] = [
  {
    id: 'name',
    label: COMPANIES.LABELS.NAME,
    type: 'string',
  },
  {
    id: 'parentCompanyName',
    label: COMPANIES.LABELS.PARENT_COMPANY_NAME,
    type: 'string',
  },
  {
    id: 'zipCode',
    label: COMPANIES.LABELS.ZIP_CODE,
    type: 'string',
  },
  {
    id: 'address',
    label: COMPANIES.LABELS.ADDRESS,
    type: 'string',
  },
  {
    id: 'phoneNumber',
    label: COMPANIES.LABELS.PHONE_NUMBER,
    type: 'string',
  },
  {
    id: 'faxNumber',
    label: COMPANIES.LABELS.FAX_NUMBER,
    type: 'string',
  },
  {
    id: 'email',
    label: COMPANIES.LABELS.EMAIL,
    type: 'string',
  },
  {
    id: 'bankAccountNumber',
    label: COMPANIES.LABELS.BANK_ACCOUNT_NUMBER,
    type: 'string',
  },
  {
    id: 'paymentStatementReferenceDateTypeText',
    label:
      COMPANIES.LABELS.PAYMENT_STATEMENT_REFERENCE_DATE_TYPE.split('：')[1],
    type: 'string',
  },
  {
    id: 'annualStartMonth',
    label: COMPANIES.LABELS.ANNUAL_START_MONTH,
    type: 'string',
  },
  {
    id: 'invoiceRegistrationNumber',
    label: COMPANIES.LABELS.INVOICE_REGISTRATION_NUMBER,
    type: 'string',
  },
]

export default function CompaniesTable(props: {
  data: Company[]
  totalCount: number
  paginateParams: PaginateParams
  setPaginateParams: React.Dispatch<
    React.SetStateAction<Record<string, unknown>>
  >
  searchParams: SearchCompany
  defaultSearchParams: SearchCompany
  setSearchParams: React.Dispatch<React.SetStateAction<SearchCompany>>
  focusId?: number
  isLoading?: boolean
}) {
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Company>('id')
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(TABLE_DEFAULT_ROWS)
  const rows = props.data
  const totalCount = props.totalCount

  React.useEffect(() => {
    //「ページあたりの行数」をloalStorageから取得してセット
    const displayLimit = getLocalStorageDisplayLimit()
    const pageNumber = getLocalStoragePageNumber(
      LOCAL_STORAGE_PAGE_KEY.COMPANIES,
      true
    )
    const newPaginateParams = {
      pageNumber: pageNumber,
      displayLimit: displayLimit,
    }
    props.setPaginateParams(newPaginateParams)
    setRowsPerPage(displayLimit)
    setPage(pageNumber)
  }, [])

  return (
    <EnhancedTable<Company>
      {...props}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      page={page}
      setPage={setPage}
      rows={rows}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      headCells={headCells}
      localStoragePageKey={LOCAL_STORAGE_PAGE_KEY.COMPANIES}
      totalCount={totalCount}
      basePath={ADMIN_ROUTES.COMPANIES_INDEX}
      isNewVersion
      searchForm={
        <SearchForm
          searchParams={props.searchParams}
          defaultSearchParams={props.defaultSearchParams}
          setSearchParams={props.setSearchParams}
          setPaginateParams={props.setPaginateParams}
          setPage={setPage}
          isLoading={props.isLoading}
        />
      }
    />
  )
}
