import { useQuery } from '@tanstack/react-query'
import { createQueryKey } from 'commons/functions'
import { useErrorHandler } from 'react-error-boundary'
import axios from 'commons/axiosClient'

export type Params = {
  page: number
  officeId: number
  userIds: number[]
  fiscalYear?: number
}

export type Attendance = {
  educationId: number
  educationTitle: string
  attended: boolean
  educationMonth?: number
}

export type UserAttendance = {
  userName: string
  attendances: Attendance[]
}

export type EducationUserAttendancesResponse = {
  userAttendances: UserAttendance[]
  totalPages: number
}
export default function useEducationUserAttendances({
  page,
  officeId,
  userIds,
  fiscalYear,
}: Params) {
  const handleError = useErrorHandler()
  const queryKey = createQueryKey('education_attendances', {
    page,
    officeId,
    userIds,
    fiscalYear,
  })

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await fetchEducationUserAttendances({
        page,
        officeId,
        userIds,
        fiscalYear,
      })
      return response.data as EducationUserAttendancesResponse
    },
    onError: handleError,
  })

  return [data, isLoading] as const
}

function fetchEducationUserAttendances({
  page,
  officeId,
  userIds,
  fiscalYear,
}: Params) {
  return axios.get('/api/v1/educations/user_attendances', {
    params: {
      page,
      office_id: officeId,
      user_ids: userIds,
      fiscal_year: fiscalYear,
    },
  })
}
