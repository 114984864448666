import * as React from 'react'
import { Box, BoxProps, Stack, StackProps } from '@mui/material'
import { styled } from '@mui/styles'

// Deprecated: Use Box with display="flex" directly
export function FlexBox(props: BoxProps) {
  console.warn('FlexBox is deprecated. Use Box with display="flex" directly.')
  return <Box display="flex" {...props}>{props.children}</Box>
}

// Deprecated: Use Stack with direction="row" directly
export function FlexBoxRow(props: BoxProps) {
  console.warn('FlexBoxRow is deprecated. Use Stack with direction="row" directly.')
  return (
    <Stack 
      direction="row" 
      flexWrap="wrap" 
      justifyContent={props.justifyContent || "start"}
      {...props}
    >
      {props.children}
    </Stack>
  )
}

// Deprecated: Use Stack with direction="column" directly
export function FlexBoxColumn(props: BoxProps) {
  console.warn('FlexBoxColumn is deprecated. Use Stack with direction="column" directly.')
  return (
    <Stack 
      direction="column" 
      {...props}
    >
      {props.children}
    </Stack>
  )
}

export function BoxBorderBottom(props: BoxProps) {
  const BoxBorderBottomElement = styled(Box)({
    borderBottom: 'solid',
    width: 'fit-content',
  })
  return (
    <BoxBorderBottomElement {...props}>{props.children}</BoxBorderBottomElement>
  )
}

export function MarginBox(props: BoxProps) {
  const MarginBoxElement = styled(Box)({
    margin: '1rem',
  })
  return <MarginBoxElement {...props}>{props.children}</MarginBoxElement>
}
