import * as React from 'react'
import { CustomVideoPlayer } from 'components/atoms/CustomVideoPlayer'
import CustomCenteringModal from 'components/atoms/CustomCenteringModal'
import { createEducationLogs } from 'components/apis/educations'
import { useErrorHandler } from 'react-error-boundary'
import ConfirmCloseDialog from 'components/atoms/ConfirmCloseDialog'
import MoveEducationTestDialog from 'components/atoms/MoveEducationTestDialog'
import type FilePlayer from 'react-player/file'
import ReactPlayer from 'react-player'
import { UserContext } from 'providers/UserProvider'
import { LOCAL_STORAGE_USER_ID } from 'commons/constants'
import { ToggleButton } from 'components/atoms/ToggleButtonComponents'
import { toastOnError } from 'commons/toaster'
import { getPlayedDuration } from 'components/apis/educationVideoLogs'
import { styled, useTheme } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { Alert } from '@mui/material'
import { palette } from 'components/theme'

type Props = {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  education: Education
  selectUserId?: number
  style?: React.CSSProperties
}

const NoneType = 0
const ConfirmType = 1
const VideoPlayerType = 2
const MoveEducationTestType = 3

type DialogType =
  | typeof NoneType
  | typeof ConfirmType
  | typeof VideoPlayerType
  | typeof MoveEducationTestType

const EndedSecFromLast = 1.5

function CustomEducationVideoPlayerModal({
  isModalOpen,
  setIsModalOpen,
  education,
  selectUserId,
  style,
}: Props) {
  const [dialogType, setDialogType] = React.useState<DialogType>(NoneType)
  const [playing, setPlaying] = React.useState<boolean>(false)
  const [ended, setEnded] = React.useState<boolean>(false)
  const [isLoop, setLoop] = React.useState<boolean>(false)
  const [isVideoLogDoneInLoop, setVideoLogDoneInLoop] =
    React.useState<boolean>(true)
  const videoRef = React.useRef<FilePlayer>(null)
  const [playedSeconds, setPlayedSeconds] = React.useState(0) // 再生済秒数
  const eventIntervalMaximumSeconds = 5 // maximum interval between firings of onProgress and onSeek events
  const endBufferSeconds = 5.0 // 終了判定のバッファ秒数
  const [showAlertDialog, setShowAlertDialog] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const theme = useTheme()
  const [choosenVideoUrl, setChoosenVideoUrl] = React.useState<string | null>(
    null
  )

  // Playerが表示されるまでdurationを取得できないため初期値を設定する
  const initVideoDuration = 1
  // 動画全体の長さを取得する
  const [videoDuration, setVideoDuration] = React.useState(initVideoDuration)
  const handleDuration = (duration: number) => {
    setVideoDuration(duration)
  }

  const handleError = useErrorHandler()
  const [disablePlaybackRate, setDisablePlaybackRate] =
    React.useState<boolean>(true)

  const user = React.useContext(UserContext)
  const userId: number = user.isDriverCommonAccount
    ? parseInt(localStorage.getItem(LOCAL_STORAGE_USER_ID))
    : selectUserId || user.id

  const AlertDialog = () => {
    const AlertCloseButton = styled('button')({
      background: 'transparent',
      padding: 0,
      border: 0,
      cursor: 'pointer',
      marginLeft: '8px',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: '20px',
      color: palette.text.gray.pale,
      position: 'absolute',
      top: 0,
      right: 5,
    })

    return (
      <>
        <Alert
          severity="info"
          sx={{
            position: 'relative',
            border: `1px solid gray`,
            fontSize: 15,
            padding: '0 10px',
            width: '920px',
            [theme.breakpoints.down('md')]: {
              width: '90vw',
            },
          }}
        >
          視聴を中断する場合は必ず左上の×ボタンから中断ください。(タブやブラウザを閉じた場合は視聴記録が保存されません。)
        </Alert>
        <AlertCloseButton
          onClick={() => {
            setShowAlertDialog(false)
          }}
        >
          ×
        </AlertCloseButton>
      </>
    )
  }

  const VideoCloseButton = () => {
    const ClearButton = styled('button')({
      background: 'transparent',
      padding: 0,
      border: 0,
      cursor: 'pointer',
      marginLeft: '8px',
      display: 'flex',
      alignItems: 'center',
    })

    function handleClose(): void {
      if (ended) {
        setIsModalOpen(false)
      } else {
        setDialogType(ConfirmType)
      }
    }

    return (
      <ClearButton
        onClick={() => {
          handleClose()
        }}
      >
        <CancelIcon
          color="error"
          sx={{
            fontSize: 55,
            [theme.breakpoints.down('md')]: {
              fontSize: 40,
            },
            [theme.breakpoints.down('sm')]: {
              fontSize: 30,
            },
            [theme.breakpoints.down('xs')]: {
              fontSize: 25,
            },
          }}
        />
      </ClearButton>
    )
  }

  function customOpen() {
    setEnded(false)
    setDialogType(VideoPlayerType)
    setPlaying(true)
  }

  function onClose() {
    const duration = videoRef.current.getCurrentTime() || 0

    if (duration > videoDuration - EndedSecFromLast) {
      setDialogType(NoneType)
      setIsModalOpen(false)
      return
    }

    // 再生を途中で終了する場合にはログを作成する
    createEducationLogs(education.id, {
      contentType: 'video',
      status: 'doing',
      playedDuration: duration,
      userId,
    })
      .then(() => {
        setDialogType(NoneType)
        setIsModalOpen(false)
        setChoosenVideoUrl(null)
      })
      .catch((e) => handleError(e.response?.status))
  }

  function onCancel() {
    setDialogType(VideoPlayerType)
  }

  function onUpdate({ status }: VideoPlayerEventProps) {
    // 再生開始時にはログを作成しない
    // 最後までスキップした際にonSeekの処理の前に実行されるため処理を抜ける
    if (
      videoRef.current.getCurrentTime() === 0 ||
      videoDuration - playedSeconds > endBufferSeconds
    ) {
      return
    }

    createEducationLogs(education.id, {
      contentType: 'video',
      status: status,
      userId,
    })
      .then(() => {
        if (status === 'done') {
          setEnded(true)
          if (education.category === 'exam') {
            setDialogType(MoveEducationTestType)
          }
        }
      })
      .catch((e) => handleError(e.response?.status))
  }

  function settingLoop() {
    setLoop(!isLoop)
  }

  // ReactPlayerはloop=trueの場合、onEndedが発火しないためループ再生時は
  // 動画の再生時間から視聴済みを判定する。
  // @see https://github.com/cookpete/react-player#callback-props
  function onEndedInLoop() {
    const currentTime = videoRef.current.getCurrentTime()

    // update 'playedSeconds' with the current time if onProgress fires within 'eventIntervalMaximumSeconds'
    if (
      currentTime - playedSeconds <= eventIntervalMaximumSeconds &&
      currentTime > playedSeconds
    ) {
      if (videoDuration === playedSeconds) {
        setPlayedSeconds(playedSeconds)
      } else if (playedSeconds < currentTime) {
        setPlayedSeconds(currentTime)
      }
    }

    if (
      isVideoLogDoneInLoop &&
      isLoop &&
      videoRef.current &&
      currentTime > videoDuration - EndedSecFromLast
    ) {
      createEducationLogs(education.id, {
        contentType: 'video',
        status: 'done',
        userId,
      })
        .then(() => {
          setVideoLogDoneInLoop(false)
        })
        .catch((e) => handleError(e.response?.status))
    }
  }

  const handleSeek = () => {
    if (
      videoRef.current.getCurrentTime() - playedSeconds >
      eventIntervalMaximumSeconds
    ) {
      // When playedSeconds falls within the range of 0 to 1, it is mistakenly interpreted as minutes instead of seconds, leading to unintended processing. To rectify this, it should be set to 0 seconds.
      // Reference link：https://github.com/cookpete/react-player/blob/795b19614977fbe2b89f6fd14503d1bfb121a722/src/Player.js#L157
      videoRef.current.seekTo(playedSeconds < 1 ? 0 : playedSeconds) // disallow seeking beyond 'eventIntervalMaximumSeconds'

      toastOnError('未視聴の位置へスキップすることはできません。')
    }
  }

  React.useEffect(() => {
    if (!education || !videoDuration || !isModalOpen) {
      return
    }

    // 視聴秒数を取得したいユーザーのID
    // 共通アカウントの場合はモーダルで選択したユーザーをLocalStorageで保持している
    const userId =
      Number(localStorage.getItem(LOCAL_STORAGE_USER_ID)) || user.id

    getPlayedDuration(education.id, userId)
      .then((response) => {
        const playedSeconds = response.data.playedDuration
        if (playedSeconds == null) {
          // 視聴済み
          setPlayedSeconds(videoDuration)
          setDisablePlaybackRate(false)
        } else {
          setPlayedSeconds(playedSeconds)
          setDisablePlaybackRate(true)
        }
      })
      .catch((e) => {
        handleError(e.response.status)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [education, videoDuration, isModalOpen])

  React.useEffect(() => {
    if (isModalOpen) {
      setShowAlertDialog(true)
    } else {
      setIsLoading(true)
    }
  }, [isModalOpen])

  return (
    <>
      {isModalOpen && showAlertDialog && dialogType != MoveEducationTestType && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: '1005',
          }}
        >
          <AlertDialog />
        </div>
      )}
      <CustomCenteringModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        customOpen={customOpen}
        style={EducationVideoPlayerModalStyle}
        shouldCloseOnOverlayClick={false}
      >
        {!isLoading && (
          <>
            {dialogType === VideoPlayerType && choosenVideoUrl != null && (
              <div style={{ margin: '0 auto 0 0' }}>
                <VideoCloseButton />
              </div>
            )}
            {dialogType === ConfirmType && (
              <ConfirmCloseDialog onClose={onClose} onCancel={onCancel} />
            )}
            {dialogType === MoveEducationTestType && (
              <MoveEducationTestDialog
                education={education}
                onClose={onClose}
              />
            )}

            {/**
             * マウントの度に動画を再取得することを防ぐため、
             * dialogType が VideoPlayerType でない場合は高さを0pxにすることで非表示にしている。
             * */}
            {choosenVideoUrl ? (
              <>
                <CustomVideoPlayer
                  videoRef={
                    videoRef as unknown as React.MutableRefObject<ReactPlayer>
                  }
                  videoUrl={choosenVideoUrl}
                  onUpdate={onUpdate}
                  playing={dialogType === VideoPlayerType && playing}
                  isLoop={isLoop}
                  style={style}
                  onProgress={onEndedInLoop}
                  onDuration={handleDuration}
                  onSeek={handleSeek}
                  disablePlaybackRate={disablePlaybackRate}
                />
              </>
            ) : (
              <>
                {education.customVideos.map((video) => (
                  <div key={video.id}>
                    <a
                      onClick={() => {
                        setChoosenVideoUrl(video.video_url)
                      }}
                      style={{
                        color: '#0000EE',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {video.title}
                    </a>
                  </div>
                ))}
              </>
            )}

            {dialogType === VideoPlayerType && (
              <ToggleButton
                value="loop"
                label="ループ再生"
                onChangeSwitch={settingLoop}
                formControlLabelStyle={{
                  [theme.breakpoints.down('sm')]: {
                    display: 'none',
                  },
                }}
                switchStyle={{
                  [theme.breakpoints.down('sm')]: {
                    display: 'none',
                  },
                }}
              />
            )}
          </>
        )}
      </CustomCenteringModal>
    </>
  )
}

const EducationVideoPlayerModalStyle = {
  aspectRatio: '16 / 9',
  margin: 'auto',
  padding: '20px 3px',
}

export default CustomEducationVideoPlayerModal
