import * as React from 'react'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { Box, IconButton, Modal, Typography, Button, Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { EDUCATION_ANNUAL_PLANS } from 'commons/constants'
import { FieldArrayWithId, useFormContext } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import {
  CustomLabel,
  CustomInput,
  CustomTextArea,
  WithDescriptionTooltipIcon,
  YearMounthDayDatePicker,
  CustomSelect,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'

import { Videos } from './Videos'
import { Section } from './Section'
import { Title } from './Title'
import { useQuery } from '@tanstack/react-query'
import { fetchEducationVideoList } from 'components/apis/educations'
import { toISOStringWithTimezone } from 'commons/functions'

export const Subject: React.FC<{
  index: number
  data: FieldArrayWithId<EducationAnnualPlan, 'subjects', 'id'>
  onDelete: () => void
  customEducations: Education[]
}> = ({ index, data, customEducations, onDelete }) => {
  const { register, control } = useFormContext<EducationAnnualPlan>()
  const { data: videoOptions } = useQuery(['educations', 'video_options'], () =>
    fetchEducationVideoList().then((res) => res.data)
  )
  const [showConfirmModal, setShowConfirmModal] = React.useState(false)

  const customSubjectOptions = customEducations.map((subject) => ({
    value: subject.id,
    label: subject.title,
  }))

  return (
    <Section key={index}>
      <BackGroundWhite>
        <Box key={index}>
          <FlexBox
            style={{ marginBottom: '16px', justifyContent: 'space-between' }}
          >
            <FlexBox>
              {data.education.category === 'exam' && (
                <Title>
                  {index + 1}. {data?.education?.title}
                </Title>
              )}
              {data.education.category === 'custom' && (
                <Controller
                  name={`subjects.${index}.education.id`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomSelect
                      options={customSubjectOptions}
                      {...field}
                      onChange={(option: { value: string }) =>
                        field.onChange(option.value)
                      }
                      value={customSubjectOptions.find(
                        (option) => option.value === data.education.id
                      )}
                    />
                  )}
                />
              )}
              <WithDescriptionTooltipIcon
                text={`${EDUCATION_ANNUAL_PLANS.LABELS.SCHEDULE}、
                     ${EDUCATION_ANNUAL_PLANS.LABELS.ORGANIZER}、
                     ${EDUCATION_ANNUAL_PLANS.LABELS.LOCATION}、
                     ${EDUCATION_ANNUAL_PLANS.LABELS.CONTENT}は受講履歴(PDF出力)にも反映されます。`}
              ></WithDescriptionTooltipIcon>
            </FlexBox>
            {data.education.category === 'custom' && (
              <>
                <IconButton
                  onClick={() => setShowConfirmModal(true)}
                  aria-label="delete"
                >
                  <CloseIcon />
                </IconButton>
                <Modal
                  open={showConfirmModal}
                  onClose={() => setShowConfirmModal(false)}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 400,
                      bgcolor: 'background.paper',
                      borderRadius: 2,
                      boxShadow: 24,
                      p: 4,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="h6" component="h2" gutterBottom>
                      削除確認
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 3 }}>
                      本当に削除してもよろしいですか？
                    </Typography>
                    <Box
                      sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          onDelete()
                          setShowConfirmModal(false)
                        }}
                      >
                        はい
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => setShowConfirmModal(false)}
                      >
                        いいえ
                      </Button>
                    </Box>
                  </Box>
                </Modal>
              </>
            )}
          </FlexBox>
          <CustomLabel>
            {EDUCATION_ANNUAL_PLANS.LABELS.SCHEDULE}
            <Grid container spacing={2}>
              <Grid item>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  開始日
                </Typography>
                <Controller
                  name={`subjects.${index}.scheduleDateFrom`}
                  defaultValue={data.scheduleDateFrom}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <YearMounthDayDatePicker
                      onChange={(date) => {
                        if (date instanceof Date) {
                          onChange(toISOStringWithTimezone(date).split('T')[0])
                        }
                      }}
                      selected={value ? new Date(value) : null}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  終了日
                </Typography>
                <Controller
                  name={`subjects.${index}.scheduleDateTo`}
                  defaultValue={data.scheduleDateTo}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <YearMounthDayDatePicker
                      onChange={(date) => {
                        if (date instanceof Date) {
                          onChange(toISOStringWithTimezone(date).split('T')[0])
                        }
                      }}
                      selected={value ? new Date(value) : null}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CustomLabel>
          <CustomLabel>
            {EDUCATION_ANNUAL_PLANS.LABELS.ORGANIZER}
            <CustomInput
              {...register(`subjects.${index}.organizer`)}
              defaultValue={data.organizer}
            />
          </CustomLabel>
          <CustomLabel>
            {EDUCATION_ANNUAL_PLANS.LABELS.LOCATION}
            <CustomInput
              {...register(`subjects.${index}.location`)}
              defaultValue={data.location}
            />
          </CustomLabel>
          <CustomLabel>
            {EDUCATION_ANNUAL_PLANS.LABELS.CONTENT}
            <CustomTextArea
              rows={4}
              sx={{ maxWidth: '500px', whiteSpace: 'pre-wrap', resize: 'none' }}
              {...register(`subjects.${index}.content`)}
              defaultValue={data.content}
            />
          </CustomLabel>
          <CustomLabel>
            {`${EDUCATION_ANNUAL_PLANS.LABELS.VIDEO}(任意でご設定ください)`}
            <label style={{ padding: '5px' }}>
              ※ 動画の内容は
              <a href="/admin/educations/videos/" target="_blank">
                動画教材
              </a>
              からご確認ください。
            </label>
            <Videos
              subjectId={index}
              videoOptions={videoOptions}
              control={control}
            />
          </CustomLabel>
        </Box>
      </BackGroundWhite>
    </Section>
  )
}
