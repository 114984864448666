import React from 'react'
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { format } from 'date-fns'
import { AwardsAndPunishment } from 'types/awardsAndPunishment'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { HeadingText } from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'

type Props = {
  awardsAndPunishments: AwardsAndPunishment[]
}

const AwardsAndPunishments: React.FC<Props> = ({ awardsAndPunishments }) => {
  return (
    <BackGroundWhite>
      <FlexBox
        justifyContent="space-between"
        alignItems="center"
        marginBottom="16px"
      >
        <HeadingText>賞罰</HeadingText>
      </FlexBox>
      <Box sx={{ overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>賞罰年月日</TableCell>
              <TableCell>内容</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {awardsAndPunishments.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{format(new Date(item.date), 'yyyy/MM/dd')}</TableCell>
                <TableCell>{item.content}</TableCell>
              </TableRow>
            ))}
            {awardsAndPunishments.length === 0 && (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  データがありません
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </BackGroundWhite>
  )
}

export default AwardsAndPunishments