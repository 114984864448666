import React from 'react'
import { Box } from '@mui/material'
import {
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form'
import { useFieldArray } from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { HeadingText } from 'components/atoms/FormComponents'
import { AddButtonOutlined } from 'components/atoms/ButtonComponenets'
import AwardsAndPunishmentCard from './AwardsAndPunishmentCard'

type Props = {
  register: UseFormRegister<Driver>
  control: Control<Driver>
  errors: FieldErrors<Driver>
  setValue: UseFormSetValue<Driver>
  onInputKeyDown?: (e: React.KeyboardEvent) => void
}

const AwardsAndPunishments: React.FC<Props> = ({
  control,
  register,
  errors,
  setValue,
  onInputKeyDown,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'awardsAndPunishments',
  })

  const appendEmptyItem = () => {
    const emptyItem = {
      year: undefined,
      month: undefined,
      day: undefined,
      content: '',
    }
    append(emptyItem, { shouldFocus: false })
  }

  const deleteItem = (targetIndex: number) => {
    remove(targetIndex)
  }

  return (
    <BackGroundWhite>
      <HeadingText>賞罰</HeadingText>
      {fields.map((field, index) => (
        <Box key={field.id} sx={{ marginBottom: 2 }}>
          <AwardsAndPunishmentCard
            index={index}
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            onInputKeyDown={onInputKeyDown}
            onRemove={() => deleteItem(index)}
          />
        </Box>
      ))}
      <AddButtonOutlined onClick={appendEmptyItem}>
        賞罰を追加
      </AddButtonOutlined>
    </BackGroundWhite>
  )
}

export default AwardsAndPunishments
