import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { IconButton } from '@mui/material'

interface AttendanceTablePaginationProps {
  page: number
  totalPages: number
  onPageChange: (newPage: number) => void
  isLoading?: boolean
}

export const AttendanceTablePagination: React.FC<
  AttendanceTablePaginationProps
> = ({ page, totalPages, onPageChange, isLoading }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: '16px',
        gap: '8px',
      }}
    >
      <IconButton onClick={() => onPageChange(page - 1)} disabled={page <= 1}>
        <ArrowBackIcon />
      </IconButton>

      {!isLoading && (
        <span>
          {page} / {totalPages}
        </span>
      )}

      <IconButton
        onClick={() => onPageChange(page + 1)}
        disabled={page >= totalPages}
      >
        <ArrowForwardIcon />
      </IconButton>
    </div>
  )
}
