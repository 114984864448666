export const DRIVER_LINK_LIST_ITEMS = {
  PROFILE_IMAGE: {
    TEXT: 'プロフィール画像',
    ID: 'profile-image',
  },
  PROFILE: {
    TEXT: '基本情報',
    ID: 'profile',
  },
  EMERGENCY_CONTACTS: {
    TEXT: '緊急連絡先',
    ID: 'emergency-contacts',
  },
  FAMILY_PROFILES: {
    TEXT: '家族構成',
    ID: 'family-profiles',
  },
  INSURANCES: {
    TEXT: '保険',
    ID: 'insurances',
  },
  CAREERS: {
    TEXT: '入社情報',
    ID: 'careers',
  },
  CHARGED_VEHICLES: {
    TEXT: '担当車両',
    ID: 'charged-vehicles',
  },
  LICENSES: {
    TEXT: '運転免許証',
    ID: 'licenses',
  },
  PERSONAL_HISTORIES: {
    TEXT: '履歴',
    ID: 'personal-histories',
  },
  PERSONNEL_CHANGE_HISTORIES: {
    TEXT: '社内歴',
    ID: 'personnel-change-histories',
  },
  DRIVING_EXPERIENCES: {
    TEXT: '運転経験',
    ID: 'driving-experiences',
  },
  MEDICAL_RECORDS: {
    TEXT: '健康診断',
    ID: 'medical-records',
  },
  APTITUDE_TEST_RECORDS: {
    TEXT: '適正診断',
    ID: 'aptitude-test-records',
  },
  DIRECTION_RECORDS: {
    TEXT: '特別指導',
    ID: 'direction-records',
  },
  VIOLATION_HISTORIES: {
    TEXT: '違反歴',
    ID: 'violation-histories',
  },
  AWARDS_AND_PUNISHMENTS: {
    TEXT: '賞罰',
    ID: 'awards-and-punishments',
  },
  MEMO: {
    TEXT: 'メモ',
    ID: 'memo',
  },
}

export const VEHICLE_LINK_LIST_ITEMS = {
  VEHICLE_IMAGE: {
    TEXT: '車両画像',
    ID: 'vehicle-image',
  },
  OPERATION_STATUS: {
    TEXT: '稼働状況',
    ID: 'operation-status',
  },
  EQUIPMENT: {
    TEXT: '装備',
    ID: 'equipment',
  },
  LICENSES: {
    TEXT: '免許区分',
    ID: 'license',
  },
  BASIC_INFO: {
    TEXT: '基本情報',
    ID: 'basic-info',
  },
  BODY_INFO: {
    TEXT: '荷台情報',
    ID: 'body-info',
  },
  TOOLS: {
    TEXT: '車内設備',
    ID: 'tools',
  },
  PURCHASE: {
    TEXT: '購入',
    ID: 'purchase',
  },
  LEASE: {
    TEXT: 'リース',
    ID: 'lease',
  },
  TAX_BASE: {
    TEXT: '自動車税',
    ID: 'tax-base',
  },
  TAX_WEIGHT: {
    TEXT: '自動車重量税',
    ID: 'tax-weight',
  },
  INSURANCE_COMPULSORY: {
    TEXT: '自賠責保険',
    ID: 'insurance-compulsory',
  },
  INSURANCE_VOLUNTARY: {
    TEXT: '任意保険',
    ID: 'insurance-voluntary',
  },
  INSPECTIONS_ANNUALLY: {
    TEXT: '定期点検(車検)',
    ID: 'inspections-annually',
  },
  INSPECTIONS_THREE_MONTHS: {
    TEXT: '定期点検(3ヶ月)',
    ID: 'inspections-three-months',
  },
  INSPECTIONS_OTHERS: {
    TEXT: '定期点検(その他)',
    ID: 'inspections-others',
  },
  MAINTENANCES: {
    TEXT: '修理・整備記録',
    ID: 'maintenances',
  },
  PARTS_REPLACED: {
    TEXT: '交換部品',
    ID: 'parts-replaced',
  },
  PARTS_PURCHASED: {
    TEXT: '購入部品',
    ID: 'parts-purchased',
  },
  GREEN_MANAGEMENT: {
    TEXT: 'グリーン経営認証',
    ID: 'green-management',
  },
  MEMO: {
    TEXT: 'メモ',
    ID: 'memo',
  },
}

export const DELIVERY_REQUEST_LINK_LIST_ITEMS = {
  CARGO_OWNER: {
    TEXT: '荷主',
    ID: 'cargo-owner',
  },
  DELIVERY_SCHEDULE: {
    TEXT: '配送スケジュール',
    ID: 'delivery-schedule',
  },
  DELIVERY_TYPE: {
    TEXT: '配送種別',
    ID: 'delivery-type',
  },
  DESTINATIONS: {
    TEXT: '配送ルート',
    ID: 'destinations',
  },
  CARGO_INFO: {
    TEXT: '荷物情報',
    ID: 'cargo-info',
  },
  BASIC_FEE: {
    TEXT: '基本運賃',
    ID: 'basic-fee',
  },
  DELEGATION_FEE: {
    TEXT: '委託運賃',
    ID: 'delegation-fee',
  },
  INHOUSE_CHARGE_PERSON_INFO: {
    TEXT: '自社担当者情報',
    ID: 'inhouse-charge-person-info',
  },
  MEMO: {
    TEXT: 'その他・特記事項',
    ID: 'memo',
  },
}

export const PROPOSAL_DELIVERY_LINK_LIST_ITEMS = {
  CARGO_OWNER: {
    TEXT: '荷主',
    ID: 'cargo-owner',
  },
  DELIVERY_SCHEDULE: {
    TEXT: '配送スケジュール',
    ID: 'delivery-schedule',
  },
  DESTINATIONS: {
    TEXT: '配送ルート',
    ID: 'destinations',
  },
  CARGO_INFO: {
    TEXT: '荷物情報',
    ID: 'cargo-info',
  },
  DELEGATION_VEHICLE_REQUIREMENT: {
    TEXT: '車両情報',
    ID: 'delegation-vehicle-requirement',
  },
  BASIC_FEE: {
    TEXT: '基本運賃',
    ID: 'basic-fee',
  },
  DELEGATION_FEE: {
    TEXT: '委託運賃',
    ID: 'delegation-fee',
  },
  INHOUSE_CHARGE_PERSON_INFO: {
    TEXT: '自社担当者情報',
    ID: 'inhouse-charge-person-info',
  },
  MEMO: {
    TEXT: 'その他・特記事項',
    ID: 'memo',
  },
  SEND_TARGET: {
    TEXT: '送信先選択',
    ID: 'send-target',
  },
  PROPOSAL_STATUS: {
    TEXT: '打診状況',
    ID: 'proposal-status',
  },
}

export const FooterMenus: Menu[] = [
  {
    title: '利用規約',
    url: 'https://lp.yourtruck.jp/xware_terms',
  },
  {
    title: 'プライバシーポリシー',
    url: 'https://lp.yourtruck.jp/xware_privacy',
  },
  {
    title: '企業情報',
    url: 'https://www.xmile.co.jp/',
  },
  {
    title: 'サービス',
    url: 'https://logipoke.com/',
  },
]

export const FORM_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSfeKK_ms7mKGZXUubu3MOeJel5D-1m1z4DI8wfaxEm5hAbeXg/viewform'

export const VALIDATION_MESSAGES = {
  required: 'この項目は必須入力です',
  patternKatakana: 'この項目はカタカナで入力してください',
  loginIdDuplicate: 'ログインIDは既に存在します',
  emailFormat: 'メールアドレスの形式で入力してください',
  faxNumberFormat: 'FAX番号の形式で入力してください',
  score: '評価点を入力してください',
}

export const SUBMIT_BUTTON_TEXT = {
  TESTS_SHOW: '回答する',
}

export const REQIRED_TEXT = '必須'

export const NOTIFICATION_MESSAGE = {
  NOT_FOUND: '現在アラート通知はありません。',
  NOT_CREATED: '作成したアラートはありません。',
  BILLS: {
    APPROVE_REQUIRED:
      '未承認の請求書です。承認状況が承認済に更新されると、メール送信が可能になります。',
  },
  PAYMENT_STATEMENTS: {
    APPROVE_REQUIRED:
      '未承認の支払明細書です。承認状況が承認済に更新されると、メール送信が可能になります。',
  },
}

export const DELIVERY_FEE_TEXT =
  '※有料道路利用料のみ税込金額、それ以外の項目は税抜金額を入力ください。'

export const EDUCATION_LIST_TEXT =
  '各行末尾の括弧内には、年間計画で登録された実施予定月が表示されます。(実施予定月が未登録の場合は表示されません。)'

export const SUBSCRIPTION_DELIVERY_TERM_TEXT = '指定可能な期間は最大3ヶ月です。'

// テーブルの設定値
export const TABLE_DEFAULT_ROWS = 100 // 1ページの初期表示数
export const TABLE_ROWS_PER_PAGE = [10, 50, 100] // 行数の変更オプション

export const ROUTE_PATH = '/'

export const ROUTES = {
  ACCIDENTS_INDEX: '/accidents/',
  ACCIDENTS_NEW: '/accidents/new/',
  ACCIDENTS_EDIT: '/accidents/edit/',
  BILLS_INDEX: '/bills/',
  DRIVERS_INDEX: '/drivers/',
  DRIVERS_NEW: '/drivers/new/',
  DRIVERS_EDIT: '/drivers/edit/',
  DELIVERY_REQUESTS_INDEX: '/delivery_requests/',
  DELIVERY_REQUESTS_NEW: '/delivery_requests/new/',
  ALCOHOL_CHECKS_INDEX: '/alcohol_checks/',
  ALCOHOL_CHECKS_NEW: '/alcohol_checks/new/',
  USERS_UPDATE_ATTRIBUTES: '/users/update_attributes/',
  NOTIFICATIONS_INDEX: '/notifications/',
  VEHICLES_INDEX: '/vehicles/',
  REFUELINGS_INDEX: '/refuelings/',
  REFUELINGS_NEW: '/refuelings/new/',
  PARTNER_COMPANIES_INDEX: '/partner_companies/',
  EDUCATION_ATTENDANCES_INDEX: '/educations/attendances/',
  ARTICLES_INDEX: '/articles/',
  TIMECARD_LEAVE_REQUEST_NEW: '/timecard/management/leave_requests/new/',
}

export const ADMIN_ROUTES = {
  ACCIDENTS_INDEX: '/admin/accidents/',
  ACCIDENTS_NEW: '/admin/accidents/new/',
  ACCIDENTS_EDIT: '/admin/accidents/edit/',
  ALCOHOL_CHECKS_INDEX: '/admin/alcohol_checks/',
  ALCOHOL_CHECKS_NEW: '/admin/alcohol_checks/new/',
  DRIVERS_INDEX: '/admin/drivers/',
  DRIVERS_NEW: '/admin/drivers/new/',
  DRIVERS_EDIT: '/admin/drivers/edit/',
  VEHICLES_INDEX: '/admin/vehicles/',
  VEHICLES_NEW: '/admin/vehicles/new/',
  VEHICLES_EDIT: '/admin/vehicles/edit/',
  REFUELINGS_INDEX: '/admin/refuelings/',
  REFUELINGS_NEW: '/admin/refuelings/new/',
  CO2_EMISSION_FACTORS_INDEX: '/admin/refuelings/co2_emission_factors',
  REFUELING_CARDS_INDEX: '/admin/refuelings/refueling_cards/',
  REFUELING_CARDS_NEW: '/admin/refuelings/refueling_cards/new/',
  SERVICE_STATIONS_INDEX: '/admin/refuelings/service_stations/',
  SERVICE_STATIONS_NEW: '/admin/refuelings/service_stations/new/',
  COMPANIES_INDEX: '/admin/companies/',
  COMPANIES_NEW: '/admin/companies/new/',
  PARTNER_COMPANIES_INDEX: '/admin/partner_companies/',
  PARTNER_COMPANIES_NEW: '/admin/partner_companies/new/',
  DELIVERY_REQUESTS_INDEX: '/admin/delivery_requests/',
  DELIVERY_REQUESTS_NEW: '/admin/delivery_requests/new/',
  SUBSCRIPTION_DELIVERY_REQUESTS_INDEX:
    '/admin/subscription_delivery_requests/',
  SUBSCRIPTION_DELIVERY_REQUESTS_NEW:
    '/admin/subscription_delivery_requests/new/',
  DELIVERY_REQUESTS_STATUS: '/admin/delivery_requests/delivery_statuses/',
  DELIVERY_DETAILS: '/admin/delivery_details/',
  PROPOSAL_DELIVERIES_INDEX: '/admin/proposal_deliveries/',
  PROPOSAL_DELIVERIES_NEW: '/admin/proposal_deliveries/new',
  DELIVERY_REQUESTS_SETTINGS: '/admin/delivery_requests/settings/',
  NOTIFICATION_NEW: '/admin/notifications/new/',
  NOTIFICATIONS_INDEX: '/admin/notifications/',
  EDUCATION_ANNUAL_PLANS_INDEX: '/admin/educations/annual_plans/',
  EDUCATION_ANNUAL_PLANS_NEW: '/admin/educations/annual_plans/new/',
  EDUCATION_ATTENDANCES_INDEX: '/admin/educations/attendances/',
  EDUCATION_VIDEO_LOGS_INDEX: '/admin/educations/video_logs/',
  BILLS_INDEX: '/admin/bills/',
  BILLS_NEW: '/admin/bills/new/',
  PAYMENT_STATEMENTS_INDEX: '/admin/payment_statements/',
  PAYMENT_STATEMENTS_NEW: '/admin/payment_statements/new/',
  ARTICLES_INDEX: '/admin/articles/',
  ARTICLES_MAINTENANCES_INDEX: '/admin/articles/maintenances/',
  ARTICLES_MAINTENANCES_NEW: '/admin/articles/maintenances/new/',
  CSV_SETTING_INDEX: '/admin/csv_settings/',
  TIMECARD_CATEGORY_INDEX: '/admin/timecard/categories/',
  TIMECARD_CATEGORY_NEW: '/admin/timecard/categories/new',
}

export const EXECUTIVES_ROUTES = {
  FUND_CONTACTS_NEW: '/executives/fund_contacts/new',
  FUND_CONTACTS_COMPLETE: '/executives/fund_contacts/complete',
}

export const API_ENDPOINTS = {
  ACCIDENTS: '/api/v1/accidents/',
  DRIVERS: '/api/v1/drivers/',
  DRIVERS_LIST: '/api/v1/drivers/list',
  DRIVERS_SORT_LIST: '/api/v1/drivers/sort_list',
  DRIVERS_ROLE_LIST: '/api/v1/drivers/role_list',
  DRIVERS_CSV_IMPORT: '/api/v1/drivers/csv/import',
  DRIVERS_CSV_EXPORT: '/api/v1/drivers/csv/export',
  DRIVERS_LOGIN_ID_DUPLICATE: '/api/v1/drivers/check_login_id_duplicate',
  DRIVERS_SUBSCRIBED_FEATURE: '/api/v1/drivers/subscribed_feature',
  DRIVERS_LOCKED_DRIVERS_LIST: '/api/v1/drivers/locked_drivers_list',
  DRIVERS_EDUCATION_TEST_HISTORY: '/api/v1/drivers/education/tests/',
  DRIVERS_CHARGED_VEHICLE: '/api/v1/drivers/charged_vehicle',
  VEHICLES: '/api/v1/vehicles/',
  VEHICLES_OPTIONS: '/api/v1/vehicles/options/',
  VEHICLES_UPDATE_ATTRIBUTES: '/api/v1/vehicles/update_attributes',
  VEHICLES_WITH_INSPECTION_PLANS: '/api/v1/vehicles/inspection_plans/',
  VEHICLES_CSV_IMPORT: '/api/v1/vehicles/csv/import',
  VEHICLES_CSV_EXPORT: '/api/v1/vehicles/csv/export',
  VEHICLES_TRAILERS: '/api/v1/vehicles/trailers',
  VEHICLES_PAYMENT_HISTORIES_CSV_EXPORT:
    '/api/v1/vehicles/payment_histories/csv/export',
  REFUELINGS: '/api/v1/refuelings/',
  REFUELINGS_LATEST: '/api/v1/refuelings/latest',
  REFUELING_CARDS: '/api/v1/refueling_cards/',
  EDUCATIONS: '/api/v1/educations/',
  EDUCATIONS_VIDEO_INDEX: '/api/v1/educations/video_index',
  EDUCATIONS_VIDEO_LIST: '/api/v1/educations/video_list',
  EDUCATIONS_MONTHLY_VIDEOS: '/api/v1/educations/monthly_videos',
  EDUCATION_TESTS: '/api/v1/educations/tests/',
  EDUCATION_ATTENDANCES: '/api/v1/user_educations/',
  EDUCATION_COMMENTS: '/api/v1/education_comments/',
  EDUCATION_VIDEO_LOGS: '/api/v1/education_video_logs/',
  EDUCATION_VIDEO_LOGS_VIEWED_MONTH:
    '/api/v1/education_video_logs/is_viewed_within_a_month',
  USER_EDUCATION_CSV_EXPORT: '/api/v1/user_education/csv/export',
  EDUCATION_SETTINGS: '/api/v1/education_settings/',
  EDUCATION_SETTINGS_UPDATE_ATTRIBUTES:
    '/api/v1/education_settings/update_attributes',
  COMPANIES: '/api/v1/companies/',
  COMPANIES_SUBSCRIBED_FEATURE: '/api/v1/companies/subscribed_feature',
  COMPANY_START_DATES: '/api/v1/company_start_dates',

  ALCOHOL_CHECKS: '/api/v1/alcohol_checks/',
  ALCOHOL_CHECKS_RESULT_RANGE: '/api/v1/alcohol_checks/result_range',
  ALL_OFFICE_LIST: '/api/v1/companies/all_office_list',
  ALL_COMPANY_LIST: '/api/v1/companies/all_company_list',
  VARIABLE_OFFICE_LIST: '/api/v1/companies/variable_office_list/',
  GROUP_PARENT_COMPANY_LIST: '/api/v1/companies/group_parent_company_list',
  EMERGENCY_CONTACTS: '/api/v1/emergency_contacts/',
  APTITUDE_TESTS: '/api/v1/aptitude_tests/',

  VIOLATION_HISTORIES: '/api/v1/violation_histories/',
  AWARDS_AND_PUNISHMENTS: '/api/v1/awards_and_punishments/',
  NOTIFICATIONS: '/api/v1/notifications/',
  NOTIFICATIONS_TYPE_LIST: '/api/v1/notifications/notification_type_options/',
  EDUCATION_ANNUAL_PLANS: '/api/v1/education_annual_plans/',
  BILLS: '/api/v1/bills/',
  BILLS_REPORTS_SEND_MAIL: '/api/v1/bills/reports/send_mail/',
  BILL_SETTING: '/api/v1/bill_setting/',
  PAYMENT_STATEMENTS: '/api/v1/payment_statements/',

  CONTACT_ADDRESSES: '/api/v1/contact_addresses/',
  CONTACT_ADDRESSES_BULK_UPDATE: '/api/v1/contact_addresses/bulk_update/',
  DELIVERY_REQUESTS: '/api/v1/delivery_requests/',
  SUBSCRIPTION_DELIVERY_REQUESTS: '/api/v1/subscription_delivery_requests/',
  DELIVERY_REQUEST_DETAILS: '/api/v1/delivery_requests/details/',
  DELIVERY_REQUEST_DELEGATION_DETAILS:
    '/api/v1/delivery_requests/delegation_details/',
  DELIVERY_REQUEST_SHEDULES: '/api/v1/delivery_request_schedules/',
  DELIVERY_REQUEST_SHEDULES_BULK_UPDATE_STATUS:
    '/api/v1/delivery_request_schedules/bulk_update_status/',
  DELIVERY_REQUESTS_OPTIONS: '/api/v1/delivery_requests/options/',
  PROPOSAL_DELIVERIES: '/api/v1/delivery_requests/proposal_deliveries/',
  DELIVERY_REUQEST_SETTINGS: '/api/v1/delivery_request_settings/',
  PREVIEW_REQUEST_TEMPLATE:
    '/api/v1/delivery_requests/documents/preview_request_template',
  SEND_REQUEST_TEMPLATE:
    '/api/v1/delivery_requests/documents/send_request_template',
  PROPOSAL_DOCUMENTS: '/api/v1/delivery_requests/proposal_documents/',
  SEND_PROPOSAL_TEMPLATE:
    '/api/v1/delivery_requests/proposal_documents/send_proposal_template',
  ARTICLES: '/api/v1/articles/',
  USER_ARTICLES: '/api/v1/user_articles/',

  FUND_CONTACTS: '/api/v1/fund_contacts/',
  FUND_CONTACT_TYPE_LIST: '/api/v1/fund_contacts/contact_type_list/',
  FUND_CONTACTS_CSV_EXPORT: '/api/v1/fund_contacts/csv/export/',
  CSV_HEADER_ASSOCIATIONS: '/api/v1/csv_header_associations/',
  CSV_TEMPLATE: '/api/v1/csv_templates/',
}
export const ADMIN_API_ENDPOINTS = {
  DRIVERS: '/api/v1/admin/drivers/',
  DRIVERS_ROLE_LIST: '/api/v1/admin/drivers/role_list',
  DRIVERS_CSV_IMPORT: '/api/v1/admin/drivers/csv/import',
  VEHICLES: '/api/v1/admin/vehicles/',
  VEHICLES_OPTIONS: '/api/v1/admin/vehicles/options/',
  VEHICLES_UPDATE_ATTRIBUTES: '/api/v1/admin/vehicles/update_attributes',
  EDUCATIONS: '/api/v1/admin/educations/',
  EDUCATION_ATTENDANCES: '/api/v1/admin/user_educations/',
  ALCOHOL_CHECKS: '/api/v1/admin/alcohol_checks/',
  PARTNER_COMPANIES: '/api/v1/admin/partner_companies/',
  EMERGENCY_CONTACTS: '/api/v1/admin/emergency_contacts/',
  APTITUDE_TESTS: '/api/v1/admin/aptitude_tests/',
  DIRECTION_RECORDS: '/api/v1/admin/direction_records/',
  VIOLATION_HISTORIES: '/api/v1/admin/violation_histories/',
}

// 以下、各モデル固有の情報
export const ACCIDENTS = {
  LABELS: {
    COMPANY: '会社',
    OFFICE: '事業所',
    CONTENT: '事故の内容',
    DATE: '発生日',
    DRIVER: 'ドライバー',
    DRIVER_NAME: 'ドライバー名',
    DRIVER_ID: 'ドライバーID',
    VEHICLE_NUMBER: '車両ナンバー',
    VEHICLE_ID: '車両ID',
    DAMAGE_AMOUNT: '損害金額',
    DAMAGED_OBJECT: '対人/対物',
    RESPONSIBILITY: '加害/被害',
    ACCIDENT_TYPE: '事故種別',
    PRODUCT_ACCIDENT: '商品事故',
    QUICK_REPORT: '事故速報',
    STATUS: '事故対応',
    CAUSE: '事故の原因',
    MEASURES: '今後の対策',
    MEMO: '備考',
    IMAGE: '画像',
    VIDEO: '動画',
  },
  NUMBERS: {
    // nginxの設定でリクエストサイズは100MBで制限している。
    // 添付ファイル以外のデータのサイズ確保分として99MBで設定。
    MAX_SIZE_VIDEO_FILE: 99 * 1024 * 1024, // 99MB in bytes
  },
}

export const DRIVERS = {
  LABELS: {
    FULLNAME: '氏名',
    FULLNAME_KANA: '氏名（カナ）',
    EMAIL: 'メールアドレス',
    LOGIN_ID: 'ログインID',
    PASSWORD: 'パスワード(6文字以上)',
    ROLE: '権限',
    EMPLOYEE_NUMBER: '社員番号',
    EMPLOYMENT_STATUS: '雇用形態',
    CURRENT_CAREER_STATUS: '在籍状況',
    JOINED: '入社',
    LEDGER_CREATED_AT: '台帳作成年月日',
    LAST_NAME: '姓',
    FIRST_NAME: '名',
    LAST_NAME_KANA: '姓（カナ）',
    FIRST_NAME_KANA: '名（カナ）',
    GENDER: '性別',
    BIRTHDAY: '生年月日',
    BLOOD_TYPE: '血液型',
    PHONE_NUMBER: '電話番号(社用)',
    PERSONAL_PHONE_NUMBER1: '電話番号（自宅/個人携帯用1）',
    PERSONAL_PHONE_NUMBER2: '電話番号（自宅/個人携帯用2）',
    ZIP_CODE: '郵便番号',
    FULL_ADDRESS: '住所',
    PREFECTURE: '都道府県',
    ADDRESS: '市区町村以降の住所',
    ENGAGED_WORK_TYPE: '従事する業務の種類',
    RELATIONSHIP: '続柄',
    IS_DEPENDENT: '扶養',
    LAST_UPDATED_USER: '最終更新者',
    LAST_UPDATED_AT: '最終更新日',
    LEDGER_FILES: '添付ファイル',
    COMPANY: '会社',
    OFFICE: '事業所',
    PROFILE: '基本情報',
    PROFILE_IMAGE: 'プロフィール画像',
    PROFILE_IMAGE_DATE: 'プロフィール画像撮影年月日',
    CAREER: '入社情報',
    EMERGENCY_CONTACT: '緊急連絡先',
    FAMILY_PROFILE: '家族構成',
    LICENSE: '運転免許証',
    LICENSE_DATE: '運転免許の取得年月日',
    BASIC_LICENSE: '運転免許',
    BASIC_LICENSE_DATE: '運転免許の所得年月日',
    TOP_LICENSE: '最上位免許',
    TOP_LICENSE_DATE: '最上位免許の取得年月日',
    CHARGED_VEHICLES: '担当車両',
    PERSONAL_HISTORY: '履歴',
    PERSONAL_HISTORY_ADMISSION: '入学/入社年月',
    PERSONAL_HISTORY_RETIREMENT: '卒業/退職年月',
    PERSONAL_HISTORY_INSTITUTION_NAME: '最終学歴/以前の勤務先',
    PERSONAL_HISTORY_MEMO: '備考',
    PERSONNEL_CHANGE_HISTORY: '社内歴',
    APTITUDE_TEST: '適性診断の受診状況',
    DIRECTION_RECORDS: '特別指導の実施状況',
    VIOLATION_HISTORY: '違反歴',
    MEDICAL_RECORDS: '健康診断の受診状況',
    DRIVING_EXPERIENCE: '運転経験',
    INSURANCE: '保険',
    QUALIFICATIONS_TYPE: '資格の種類',
    IS_EDUCATION_TARGET: '安全教育講習',
    FILE: '添付ファイル',
    FILE_UPLOAD: 'ファイルアップロード',
    FILE_UPLOAD_DESCRIPTION_PARENT: '計画・実績等のデータ',
    FILE_UPLOAD_DESCRIPTION_CHILD: '結果等のデータ',
  },
}

export const EMERGENCY_CONTACTS = {
  LABELS: {
    LAST_NAME: '姓',
    FIRST_NAME: '名',
    LAST_NAME_KANA: '姓(カナ)',
    FIRST_NAME_KANA: '名(カナ)',
    PHONE_NUMBER: '電話番号',
    RELATIONSHIP: '続柄',
    ZIP_CODE: '郵便番号',
    PREFECTURE: '都道府県',
    ADDRESS: '市区町村以降の住所',
  },
}

export const LICENSES = {
  LABELS: {
    LICENSE_NUMBER: '運転免許証の番号',
    ISSUANCED_DATE: '交付年月日',
    EXPIRED_DATE: '有効期限',
    LICENSE_TYPE: '免許の種類',
    FILES: '運転免許証のアップロード',
    MEMO: '備考',
  },
}

export const INSURANCES = {
  LABELS: {
    TYPES: {
      HEALTH: '健康保険',
      PENSION: '厚生年金保険',
      EMPLOYMENT: '雇用保険',
      INJURY: '労災保険',
    },
    DATE: '加入年月日',
    NUMBER: '保険番号',
  },
}

export const FAMILY_PROFILES = {
  LABELS: {
    FIRST_NAME: '名',
    LAST_NAME: '姓',
    BIRTHED_DATE: '生年月日',
    RELATIONSHIP: '族柄',
    IS_DEPENDENT: '扶養',
  },
}

export const CAREERS = {
  LABELS: {
    JOINED_DATE: '入社日',
    RETIRED_DATE: '退職日',
    CHARTERED_DRIVER_DATE: '貸切専任日',
    SHARED_DRIVER_DATE: '乗合専任日',
    DIED_DATE: '死亡日',
    STATUS: '在籍区分',
    REASON: '理由',
  },
  // categoryの定義順序
  CATEGORIES: {
    JOINED: 'joined',
    CHARTERED_VEHICLE_DRIVER: 'chartered_vehicle_driver',
    SHARED_VEHICLE_DRIVER: 'shared_vehicle_driver',
    RETIRED: 'retired',
    DIED: 'died',
  },
  // categoryとインデックスの対応付け
  MAPPING: {
    joined: 0, // 入社日
    retired: 1, // 退職日
    died: 3, // 死亡日
    chartered_vehicle_driver: 4, // 貸切専任日
    shared_vehicle_driver: 5, // 乗合専任日
  },
  // 表示順序のペア
  DISPLAY_GROUPS: {
    JOINED: ['joined'],
    ASSIGNED: ['chartered_vehicle_driver', 'shared_vehicle_driver'],
    STATUS: ['retired', 'died'],
  },
}

export const MEDICAL_RECORDS = {
  LABELS: {
    CONSULTATION_DATE: '受診年月日',
    RESULT: '受診結果',
    FILES: '添付ファイル',
    FILES_UPLOAD: '受診結果アップロード',
    MEMO: '備考',
  },
}

export const CHARGED_VEHICLES = {
  LABELS: {
    VEHICLE: '車両',
    FROM_DATE: '乗車開始日',
    TO_DATE: '乗車終了日',
    MEMO: '備考',
  },
}

export const PERSONAL_HISTORIES = {
  LABELS: {
    ADMISSION_DATE: '入学/入社年月',
    RETIREMENT_DATE: '卒業/退職年月',
    INSTITUTION_NAME: '最終学歴/以前の勤務先',
    MEMO: '備考',
  },
}

export const PERSONNEL_CHANGE_HISTORIES = {
  LABELS: {
    POSITION: '部署/役職',
    APPOINTED_DATE: '着任日/就任日',
    MEMO: '備考',
  },
}

export const DRIVING_EXPERIENCES = {
  LABELS: {
    VEHICLE_TYPE: '自動車の種類',
    CAPACITY: '定員',
    LOAD_CAPACITY: '積載量',
    EXPERIENCE_DATE: '経験年数',
    OFFICE_NAME: '経験した事業所の名称',
    VEHICLE_CATEGORY: 'バス車両の区分',
    LARGE_VEHICLE: '大型車',
    MEDIUM_VEHICLE: '中型車',
    SMALL_VEHICLE: '小型車',
  },
}

export const APTITUDE_TEST_RECORDS = {
  LABELS: {
    APTITUDE_TYPE: '受診対象の種類',
    CONSULTATION_DATE: '受診年月日',
    INSTITUTION_NAME: '実施機関名',
    RESULT: '受診結果',
    FILES: '添付ファイル',
    FILES_UPLOAD: '受診結果アップロード',
  },
}

export const DIRECTION_RECORDS = {
  LABELS: {
    DRIVER_TYPE: '種類',
    START_DATE: '開始日',
    END_DATE: '終了日',
    DESCRIPTION: '実施内容',
    FILES: '添付ファイル',
    FILES_UPLOAD: '受診結果アップロード',
  },
}

export const VIOLATION_HISTORIES = {
  LABELS: {
    VIOLATION_DATE: '違反年月日',
    DESCRIPTION: '内容等',
  },
}

export const VEHICLES = {
  LABELS: {
    NUMBER: '車両ナンバー',
    NUMBER_WITH_DESCRIPTION: '車両ナンバー（自動車登録番号又は車両番号）',
    NAME: '車名',
    COMPANY: '会社',
    OFFICE: '事業所',
    DATE_OF_EXPIRY: '有効期限',
    FILE: 'ファイル',
    FILE_UPLOAD: 'ファイルアップロード',
    TEMPERATURE: '温度',
    TEMPERATURE_ZONE: '温度帯',
    WEIGHT: '車両重量',
    SHAPE: 'ボディタイプ（車体の形状）',
    CONNECTED_TRAILER: '連結するトレーラー',
    SIZE: 'サイズ',
    GREEN_MANAGEMENT_VEHICLE_TYPE: '車両種別',
    EQUIPMENT: '装備',
    LAST_UPDATED_USER: '最終更新者',
    LAST_UPDATED_AT: '最終更新日',
    FILES: '画像（車体の外観など）',
    OPERATION_STATUS: '稼働状況',
    LICENSE: '免許区分',
    INSPECTIONS_FILES: '車検証アップロード',
    INSPECTIONS_DATE: '車検満了日',
    INSURANCE_COMPULSORY_COMPANY_NAME_DETAILED: '自賠責の保険会社名',
    INSURANCE_COMPULSORY_CONTRACT_BRANCH_NAME: '自賠責番号',
    BASIC_INFO: '基本情報',
    UNIQUE_NUMBER: '車台番号',
    REGISTERED_DATE: '登録年月日/交付年月日',
    FIRST_REGISTERED_DATE: '初年度登録年月',
    CATEGORY: '自動車の種別',
    USAGE: '自動車の用途',
    NUMBER_PLATE_TYPE: 'ナンバー種別',
    MANUFACTURE: 'メーカー名',
    PERSON_CAPACITY: '乗車定員',
    WEIGHT_CAPACITY: '最大積載量',
    TOTAL_WEIGHT: '車両総重量',
    OUTER: '車両外寸',
    OUTER_LENGTH: '車両外寸(長さ)',
    OUTER_WIDTH: '車両外寸(幅)',
    OUTER_HEIGHT: '車両外寸(高さ)',
    CABIN_TYPE: 'キャブ幅',
    AXILE_WEIGHT_FRONT_FRONT: '前前軸重',
    AXILE_WEIGHT_FRONT_REAR: '前後軸重',
    AXILE_WEIGHT_REAR_FRONT: '後前軸重',
    AXILE_WEIGHT_FRONT_REAR_REAR: '後後軸重',
    DRIVE_SYSTEM: '駆動方式',
    MODEL: '車両型式',
    ENGINE_MODEL: '原動機の型式',
    ENGINE_DISPLACEMENT: '総排気量',
    FUEL_TYPE: '燃料の種類',
    FUEL_TANK_NUMBER: '燃料タンクの数',
    FUEL_TANK_CAPACITY: '燃料タンクの容量',
    HAS_SCR: '尿素SCR',
    TRANSMISSION_TYPE: 'ミッション',
    HAS_BED: 'ベッド（寝台）',
    TIRE_COUNT: 'タイヤ本数',
    TIRE_SIZE_FRONT: 'タイヤサイズ（前）',
    TIRE_SIZE_REAR: 'タイヤサイズ（後）',
    IS_SPECIFIED_COLD: '寒冷地仕様',
    RECYCLE_PRICE: 'リサイクル料金',
    CONTROL_NUMBER: '管理番号',
    BODY: '荷台情報',
    BODY_SERIAL_NUMBER: '製造番号（型式）',
    BODY_MANUFACTURED_DATE: '製造年月',
    BODY_INNER: '荷室内寸',
    BODY_CAPACITY: '容量',
    BODY_WALL_THICKNESS: '壁の厚さ',
    BODY_FLOOR_HEIGHT: '床面地上高',
    BODY_GATE_FRAME: '門構',
    BODY_GATE_HEIGHT: 'あおりの高さ',
    BODY_GATE_MATERIAL: 'あおりの材質',
    SIDE_DOOR: 'サイドドア',
    COOLER: '冷凍機',
    COOLER_MANUFACTURE: 'メーカー',
    COOLER_MODEL: '型式',
    COOLER_SERIAL_NUMBER: '製造番号',
    COOLER_MANUFACTURED_DATE: '製造年月',
    COOLER_FREON_TYPE: 'フロンの種類',
    COOLER_TEMPERATURE: '温度設定',
    COOLER_HAS_STANDBY_DEVICE: 'スタンバイ装置',
    COOLER_FLOOER_COUNT: '冷凍冷蔵車の層数',
    COOLER_EVAPORATOR_COUNT: 'エバポレーターの数',
    TOOLS: '車内設備',
    DIGI_TACHO: 'デジタコ',
    DIGI_TACHO_MANUFACTURE: 'メーカー/型式',
    DIGI_TACHO_SERIAL_NUMBER: '製造番号',
    DRIVER_RECORDER: 'ドラレコ',
    DRIVER_RECORDER_MANUFACTURE: 'メーカー/型式',
    DRIVER_RECORDER_SERIAL_NUMBER: '製造番号',
    ETC: 'ETC車載器',
    ETC_DEVICE: '車載器番号',
    ETC_ASL_ID: 'ASL-ID',
    ETC_CARD: 'ETCカード',
    ETC_CARD_NUMBER: 'カード会社/カード番号',
    ETC_CARD_EXPIRE_DATE: '有効期限',
    FUEL_CARD: '燃料カード',
    FUEL_CARD_NUMBER: 'カード会社/カード番号',
    OTHERS: 'その他',
    PURCHASE: '購入',
    PURCHASE_TYPE: '購入区分',
    PURCHASE_DATE: '購入日',
    PURCHASE_PRICE: '購入価格（円）',
    DEPRECATION_PERIOD: '減価償却期間',
    RETAILER: '購入先',
    RETAILER_CHARGE_PERSON: '担当者',
    RETAILER_ADDRESS: '住所',
    RETAILER_PHONE_NUMBER: '電話番号',
    PURCHASE_MEMO: '備考',
    LEASE: 'リース',
    LEASE_CONTRACT_ID: '契約ID',
    LEASE_CONTRACT_NUMBER: '契約番号',
    LEASE_CONTRACT_OPTION: 'リースの種類',
    LEASE_TOTAL_FEE: 'リース総額（円)',
    LEASE_MONTHLY_FEE: '月間リース料金（円）',
    LEASE_DEPOSITE_FEE: '頭金（円）',
    LEASE_RESIDUAL_VALUE: '残価（円）',
    LEASE_CANCEL_PENALTY: '規定損害金額（円）',
    LEASE_MONTHLY_DEGRESSION: '逓減月額（円）',
    LEASE_START_DATE: 'リース契約日',
    LEASE_END_DATE: 'リース終了日',
    LEASE_PAYMENTS_NUMBER: '支払い回数',
    LEASE_PAYMENTS_DATE: '毎月の支払い日',
    LEASE_COMPANY_NAME: 'リース会社',
    LEASE_CHARGE_PERSON: '担当者',
    LEASE_ADDRESS: 'リース会社住所',
    LEASE_PHONE_NUMBER: 'リース会社電話番号',
    LEASE_MEMO: '備考',
    TAX_BASE: '自動車税',
    TAX_BASE_TOTAL_FEE: '自動車税費用（円）',
    TAX_BASE_START_DATE: '開始日',
    TAX_BASE_END_DATE: '満了日',
    TAX_BASE_MEMO: '備考',
    TAX_WEIGHT: '自動車重量税',
    TAX_WEIGHT_TOTAL_FEE: '自動車重量税費用（円）',
    TAX_WEIGHT_START_DATE: '開始日',
    TAX_WEIGHT_END_DATE: '満了日',
    TAX_WEIGHT_MEMO: '備考',
    INSURANCE_COMPULSORY: '自賠責保険',
    INSURANCE_COMPULSORY_STATUS: '契約状況',
    INSURANCE_COMPULSORY_TYPE: '保険種類',
    INSURANCE_COMPULSORY_COMPANY_NAME: '保険会社',
    INSURANCE_COMPULSORY_START_DATE: '付保日',
    INSURANCE_COMPULSORY_END_DATE: '保険満了日',
    INSURANCE_COMPULSORY_CONTRACT_NUMBER: '証券番号',
    INSURANCE_COMPULSORY_CONTRACT_BRANCH_NUMBER: '枝番',
    INSURANCE_COMPULSORY_COST: '保険費用（円）',
    INSURANCE_COMPULSORY_MEMO: '備考',
    INSURANCE_VOLUNTARY: '任意保険',
    INSURANCE_VOLUNTARY_STATUS: '契約状況',
    INSURANCE_VOLUNTARY_TYPE: '保険種類',
    INSURANCE_VOLUNTARY_COMPANY_NAME: '保険会社',
    INSURANCE_VOLUNTARY_START_DATE: '付保日',
    INSURANCE_VOLUNTARY_END_DATE: '保険満了日',
    INSURANCE_VOLUNTARY_CONTRACT_NUMBER: '証券番号',
    INSURANCE_VOLUNTARY_CONTRACT_BRANCH_NUMBER: '枝番',
    INSURANCE_VOLUNTARY_COST: '保険費用（円）',
    INSURANCE_VOLUNTARY_MEMO: '備考',
    INSPECTIONS_ANNUALLY: '定期点検(車検)',
    INSPECTIONS_INSPECT_DATE: '実施日',
    INSPECTIONS_TOTAL_FEE: '合計金額（円）',
    INSPECTIONS_PARTS_FEE: '部品代（円）',
    INSPECTIONS_TECHNICAL_FEE: '技術代（円）',
    INSPECTIONS_COMPANY_NAME: '修理・整備先',
    INSPECTIONS_MILEAGE: '走行距離(km)',
    INSPECTIONS_MEMO: '備考',
    INSPECTIONS_THREE_MONTHS: '定期点検(3ヵ月)',
    INSPECTIONS_OTHERS: '定期点検(その他)',
    INSPECTIONS_NEXT_DATE: '次回実施予定日',
    INSPECTIONS_IS_ACCEPTABLE: '良否判定',
    MAINTENANCE: '修理・整備記録',
    MAINTENANCE_TITLE: 'タイトル',
    MAINTENANCE_NUMBER: '修理・整備記録No',
    MAINTENANCE_DATE: '実施日',
    MAINTENANCE_TOTAL_FEE: '合計金額（円）',
    MAINTENANCE_PARTS_FEE: '部品代（円）',
    MAINTENANCE_TECHNICAL_FEE: '技術代（円）',
    MAINTENANCE_AREA: '修理・整備箇所',
    MAINTENANCE_COMPANY_NAME: '修理・整備先',
    MAINTENANCE_MILEAGE: '走行距離(km)',
    MAINTENANCE_MEMO: '備考',
    PARTS_REPLACED: '交換部品',
    PARTS_REPLACED_DATE: '実施年月日',
    PARTS_REPLACED_MILEAGE: '走行距離(km)',
    PARTS_REPLACED_PARTS: '主な交換部品',
    PARTS_REPLACED_AMOUNT_ENGINE_OIL: 'エンジンオイルのリッター数',
    PARTS_REPLACED_MEMO: '備考',
    PARTS_PURCHASED: '購入部品',
    PARTS_PURCHASED_NAME: '品名',
    PARTS_PURCHASED_DATE: '購入日',
    PARTS_PURCHASED_TOTAL_FEE: '金額（円）',
    PARTS_PURCHASED_AMOUNT: '購入数',
    PARTS_PURCHASED_MEMO: '備考',
    GREEN_MANAGEMENT: 'グリーン経営認証',
    MEMO: 'メモ',
    TONNAGE: 'トン数（最大積載量）',
    FILE_UPLOAD_DESCRIPTION_PARENT: '計画・実績等のデータ',
    FILE_UPLOAD_DESCRIPTION_CHILD: '伝票・図面等の証憑データ',
  },
}

export const REFUELINGS = {
  LABELS: {
    COMPANY: '会社',
    OFFICE: '事業所',
    VEHICLE_NUMBER: '車両ナンバー',
    VEHICLE_NAME: '車両名',
    DRIVER_NAME: '担当ドライバー名',
    REFUELING_DATE: '給油日',
    REFUELING_AMOUNT: '給油量',
    MILEAGE: '累計走行距離',
    INCREMENTAL_MILEAGE: '走行距離(前回給油以降)',
    SUBTOTAL: '金額',
    LATEST_REFUELING: '前回給油情報',
    FUEL_CONSUMPTION: '燃費',
    CO2_EMISSION_FACTOR: 'CO₂排出係数',
    CO2_EMISSION: 'CO₂排出量',
    MEMO: '備考',
  },
}



export const REFUELING_CARDS = {
  LABELS: {
    COMPANY: '会社',
    OFFICE: '事業所',
    SERVICE_STATION_NAME: 'サービスステーション名',
    TERM: '期間',
    UNIT_PRICE: '単価',
  },
}



export const VEHICLE_MANAGEMENTS = {
  LABELS: {
    SERVICE_STATIONS: 'サービスステーション設定',
    REFUELING_CARDS: '給油カード設定',
  },
}

export const ALCOHOL_CHECKS = {
  LABELS: {
    DRIVER: 'ドライバー',
    COMPANY: '会社',
    OFFICE: '事業所',
    VEHICLE_NUMBER: '車両ナンバー',
    RESULT: '測定結果(mg/L)',
    RESULT_PHOTO: 'アルコール検知器の写真',
    FACE_PHOTO: 'ドライバーの顔写真',
    MEMO: '備考',
  },
}



export const DELIVERY_REQUESTS = {
  SECTIONS: {
    CARGO_OWNER: '荷主',
    DELIVERY_SCHEDULE: '配送スケジュール',
    DELIVERY_TYPE: '配送種別',
    DELIVERY_ROUTE: '配送ルート',
    CARGO_INFO: '荷物情報',
    BASIC_FEE: '基本運賃',
    DELEGATION_FEE: '委託運賃',
    INHOUSE_CHARGE_PERSONINFO: '自社担当者情報',
    MEMO: 'その他・特記事項',
  },
  LABELS: {
    COMPANY: '会社',
    OFFICE: '事業所',
    DELIVERY_TYPE: '配送種別',
    SHIPMENT_REQUEST_RESULT: '依頼書送信結果',
    OWNER_COMPANY_NAME: '荷主',
    REQUEST_TYPE: '案件種別',
    PICK_UP_DATE: '積日',
    DELIVERY_DATE: '卸日',
    VEHICLE: '車両',
    DRIVER_NAME: 'ドライバー',
    PARTNER_COMPANY_NAME: '委託先会社名',
    DELIVERY_DAYS: '曜日',
    TOTAL_AMOUNT: '合計金額',
    CARGO_DETAIL: '荷物',
    CARGO_MEMO: '車両の条件',
    FIRST_DESTINATION: '積地',
    VIA: '経由',
    LAST_DESTINATION: '卸地',
    DEPART_PREFECTURE: '積地(都道府県)',
    DEPART_ADDRESS: '積地(市区町村)',
    ARRIVE_PREFECTURE: '卸地(都道府県)',
    ARRIVE_ADDRESS: '卸地(市区町村)',
    INHOUSE_CHARGE_PERSON: '担当者名',
    INHOUSE_CHARGE_PERSON_CONTACT_ADDRESS: '担当者連絡先',
    MEMO: 'その他・特記事項',
    OWNER: {
      COMPANY_NAME: '社名',
      ZIP_CODE: '郵便番号',
      PREFECTURE: '都道府県',
      ADDRESS: '市区町村以降の住所',
      PHONE_NUMBER: '電話番号',
      CHARGE_PERSON_NAME: '担当者名',
      FAX_NUMBER: 'FAX番号',
    },
    DELIVERY_SCHEDULE: {
      OWNER_NAME: '荷主',
      REQUEST_TYPE: '案件種別',
      DELIVERY_TYPE: '配送種別',
      DELIVERY_TERM: '配送期間',
      PICK_UP_DATE: '積日',
      DELIVERY_DATE: '卸日',
      STATUS: '配送状況',
      METHOD: '配送方法',
      DRIVER: 'ドライバー名',
      VEHICLE: '車両',
      PARTNER_COMPANY: '委託先会社名',
      DELEGATION_DRIVER_NAME: '委託先ドライバー名',
      DELEGATION_VEHICLE_NUMBER: '委託先車両ナンバー',
    },
    DELIVERY_ROUTE: {
      TASK: '作業内容',
      LOADING: '積込み',
      UNLOADING: '取卸し',
      DEADLINE_TYPE: '時間指定',
      DEADLINE_TIME: '時間',
      BUILDING_NAME: '建物名',
      ZIP_CODE: '郵便番号',
      PREFECTURE: '都道府県',
      ADDRESS: '市区町村以降の住所',
      CHARGE_PERSON_NAME: '担当者名',
      CONTACT_INFO: '連絡先',
      TASK_DETAIL: '作業詳細',
      TASK_AFTER_ARRIVAL: '特記事項(日付等)',
      FILES: '添付ファイル',
    },
    CARGO: {
      CARGO_TYPE: '荷物分類',
      NAME: '品名',
      COUNT: '数量',
      PACKING_STYLE: '荷姿',
      WEIGHT: '総重量',
      DEPTH: '寸法(長さ)',
      WIDTH: '寸法(幅)',
      HEIGHT: '寸法(高さ)',
      VEHICLE_REQUIREMENT: '車両の条件(重量、形状、冷房設備の有無、装備品など)',
      MEMO: '備考',
    },
    BASIC_FEE: {
      VEHICLE_COUNT: '台数',
      TOTAL_WEIGHT: '重量',
      SHIPPING_FEE: '運賃',
      FUEL_SURCHARGE: '燃料サーチャージ',
      HIGHWAY_FEE: '有料道路利用料',
      CARGO_HANDLING_FEE: '荷役料',
      OTHERS_FEE: 'その他料金',
      ADJUSTMENT_FEE: '調整金額',
      TAX: '消費税',
      SUBTOTAL_WITH_TAX: '基本運賃合計(税込)',
      HIGHWAY_SECTION: '有料道路区間',
      PAYMENT_DATE: '荷主からの入金予定日',
      DELEGATION_PAYMENT_DATE: '委託先への支払い期日',
      DELEGATE_INSURANCE: '運送保険加入の委託',
      CONTRACT_TYPE: '運送の扱い種別',
      PAYMENT_METHOD: 'お支払い方法',
      MEMO: '備考(その他料金の内訳など)',
    },
    BASIC_FEE_DETAIL: '基本運賃内訳',
    DELEGATION_SUBTOTAL_FEE_WITH_TAX: '委託運賃合計(税込)',
    COST_MODIFY_BUTTON: '金額変更ボタン',
  },
}

export const SUBSCRIPTION_DELIVERY_REQUESTS = {
  LABELS: {
    DELIVERY_TERM: '期間',
    DELIVERY_DAYS: '曜日',
    START_AT: '開始日',
    END_AT: '終了日',
  },
}

export const PROPOSAL_DELIVERY_REQUESTS = {
  SECTIONS: {
    DELEGATION_VEHICLE_REQUIREMENT: '車両情報',
    SEND_TARGET: '送信先選択',
    PROPOSAL_STATUS: '打診状況',
  },
  LABELS: {
    BASIC_FEE: '受注金額',
    DELEGATE_FEE: '委託金額',
    DELEGATION_VEHICLE_REQUIREMENT: {
      DELEGATION_VEHICLE_TYPE: '種類',
      WEIGHT: 't数',
      TEMPERATURE_ZONE: '温度帯',
      MODEL: '車型',
      SIZE_DETAIL: 'サイズ詳細',
      FEATURES: '機能',
      VEHICLE_COUNT: '台数',
      VEHICLE_EQUIPMENT: '必要装備',
    },
    PROPOSAL_STATUS: '打診状況',
    VEHICLE_DETAIL: '車両情報',
  },
}

export const DELIVERY_REQUEST_SETTINGS = {
  LABELS: {
    IS_SHOW_OWNER: '配送依頼書の委託者(荷主名)の表示',
  },
}

export const SEND_TARGET_TABLE_ROWS = 10 // 打診管理 送信先選択の1ページ行数

export const DELIVERY_REQUEST_SCHEDULES = {
  LABELS: {
    COMPANY: '会社',
    OFFICE: '事業所',
    OWNER_NAME: '荷主',
    DELIVERY_TYPE: '配送種別',
    PICK_UP_DATE: '積日',
    DElIVERY_DATE: '卸日',
    SUBTOTAL_WITH_TAX: '合計(税込)',
    SUBTOTAL_WITHOUT_HIGHWAY_FEE: '合計(税抜)',
    SHIPPING_FEE: '運賃',
    FUEL_SURCHARGE_FEE: '燃料サーチャージ料',
    HIGHWAY_FEE: '有料道路利用料',
    CARGO_HANDLING_FEE: '荷役料',
    OTHERS_FEE: 'その他料金',
    ADJUSTMENT_FEE: '調整金額',
    TAX: '消費税',
    MEMO: '備考',
  },
}

/**
 * Constants related to companies.
 */
export const COMPANIES = {
  LABELS: {
    NAME: '会社名(事業所名)',
    PARENT_COMPANY_NAME: '親会社名',
    GROUP_PARENT_COMPANY_NAME: 'グループ親会社名',
    COMPANY_LOGIN_ID: '会社ID',
    EXCLUSIVE_LOGIN_URL_AND_QR: '専用ログインURL・QRコード',
    ADDRESS: '住所',
    ZIP_CODE: '郵便番号',
    PHONE_NUMBER: '電話番号',
    FAX_NUMBER: 'FAX番号',
    EMAIL: 'メールアドレス',
    BANK_ACCOUNT_NUMBER: '振込先口座情報',
    PAYMENT_STATEMENT_REFERENCE_DATE_TYPE: '支払明細書：支払期間の基準日',
    ANNUAL_START_MONTH: '年度開始月',
    INVOICE_REGISTRATION_NUMBER: '適格請求書発行事業者登録番号',
    SUBSCRIPTION: '契約プラン',
    SIGNATURE_IMAGE: '承認印画像',
    NOTIFICATION_SETTINGS: 'アラート管理を表示',
  },
}

export const PARTNER_COMPANIES = {
  LABELS: {
    INVOICE_REGISTRATION_NUMBER: '適格請求書発行事業者登録番号',
    CREATOR_COMPANY: '会社',
    CREATOR_OFFICE: '事業所',
    NAME: '取引先名',
    FORM_NAME: '取引先名(帳票用)',
    NAME_KANA: '取引先名(かな)',
    CORPORATE_NUMBER: '法人番号',
    PHONE_NUMBER: '電話番号',
    FAX_NUMBER: 'FAX番号',
    EMAIL: 'メールアドレス',
    ZIP_CODE: '郵便番号',
    PREFECTURE: '都道府県',
    ADDRESS: '市区町村番地',
    BUILDING_NAME: '建物名',
    RELATION_CATEGORY: '取引関係',
    COMPANY_ID: '会社ID',
    BANK_ACCOUNT: '振込先',
    BILL_EMAIL: 'メールアドレス(請求書の送り先)',
    BILL_POST_ADDRESSEE: '請求書宛名',
    BILL_MAIL_ADDRESSEE: 'メールで請求書を送る際の宛名',
    BILL_DESTINATION: '請求書送付先',
    BILL_CHARGE_PERSON: '請求先担当者',
    BILL_CONFIRMATION_MEMO: '請求書の確認方法',
    PAYMENT_CYCLE_END_DAY: '支払い条件(締日)',
    PAYMENT_DEADLINE: '支払い条件(支払い期限)',
    BILL_REFERENCE_DATE_TYPE: '請求書の基準日',
    RECEIPT_DESTINATION: '受領書送付先',
    PAYMENT_STATEMENT_REFERENCE_DATE_TYPE: '支払明細書：支払期間の基準日',
    IS_INVOICE_SUPPORTED: '請求書のインボイス対応有無',
    VEHICLE: {
      WEIGHT: 't数',
      TEMPERATURE_ZONE: '温度帯',
      MODEL: '車型',
      VEHICLE_TYPE: '種類',
      SIZE_DETAIL: 'サイズ詳細',
      FEATURES: '機能',
      HAS_GATE: 'ゲート',
      HAS_AIR_SUSPENSION: 'エアサス',
      COUNT: '台数',
      OWN_TYPE: '保有主体',
      IS_CARGO_HANDLING: '自主荷役の可否',
      IS_BULK_CARGO: 'バラ作業の可否',
      AREA_TYPES: '運行エリア',
      IS_LOCAL: '地場',
      IS_MIDDLE_LONG_DISTANCE: '中長距離',
      DEPART_AREAS: '希望発地',
      ARRIVE_AREAS: '希望着地',
      DELIVERY_AREA_MEMO: '運行エリア備考',
      CAUTION: 'NG項目・注意点など',
    },
  },
}

export const USER_EDUCATIONS = {
  LABELS: {
    USER_NAME: 'ドライバー',
    COMPANY: '会社',
    OFFICE: '事業所',
    QUESTION_COUNT: '設問数',
    CORRECT_COUNT: '正解数',
    ATTENDANCE_DATETIME: '受講日時',
    ATTENDANCE_DATE: '受講日時（年月日）',
    ATTENDANCE_TIME: '受講日時（時分）',
    IS_WEBTEST: '形式',
  },
}

export const EDUCATION_VIDEO_LOGS = {
  LABELS: {
    USER_NAME: 'ドライバー',
    COMPANY: '会社',
    OFFICE: '事業所',
    WATCH_STATUS: '視聴状況',
    COMPLETED_DATETIME: '視聴完了日時',
  },
}

export const EDUCATIONS = {
  LEGAL_COUNT: 12,
}

export const EDUCATION_ANNUAL_PLANS = {
  LABELS: {
    BASIC_INFO: '基本情報',
    ANNUAL_PLAN_TITLE: '年間計画タイトル',
    FISCAL_YEAR: '年度',
    COMPANY: '会社',
    OFFICE: '事業所',
    ANNUAL_PLAN_DETAIL: '実施計画詳細',
    SCHEDULE: '実施予定日',
    CONTENT: '実施内容',
    ORGANIZER: '実施者',
    LOCATION: '実施場所',
    VIDEO: '動画',
  },
}

export const EDUCATION_COMMENTS = {
  LABELS: {
    FISCAL_YEAR: '年度',
    COMPANY: '会社',
    OFFICE: '事業所',
    DRIVER_NAME: 'ドライバー氏名',
    ADMIN_USER_NAME: '管理者氏名',
    DRIVER_COMMENT: 'ドライバーコメント',
    ADMIN_COMMENT: '管理者コメント',
    STATUS: 'コメント状況',
  },
  DRIVER_COMMENT_LENGTH_MIN: 20,
  DRIVER_COMMENT_LENGTH_MAX: 250,
}

export const DRIVER_COMMENT_VALIDATE_LENGTH_TEXT = `${EDUCATION_COMMENTS.DRIVER_COMMENT_LENGTH_MIN}文字以上、${EDUCATION_COMMENTS.DRIVER_COMMENT_LENGTH_MAX}文字以内`
export const DRIVER_COMMENT_PLACEHOLDER =
  '入力例：今回受講した〇〇の項目の〇〇について非常に勉強になりました。特に〇〇については今後の運転にも取り入れ、より安全運転と業務の質を高められるように心がけます。'

export const BILLS = {
  LABELS: {
    BILL_NUMBER: '請求書番号',
    ISSUANCE_DATE: '発行日',
    OWNER: '請求先',
    APPROVE_STATUS: '承認状況',
    PAYMENT_STATUS: '入金状況',
    OWNER_CHARGE_PERSON: '請求先担当者',
    BILL_TERM: '請求期間',
    START_AT: '請求期間(開始)',
    END_AT: '請求期間(終了)',
    BILL_AMOUNT: '合計金額',
    TAX_BREAKDOWN: '消費税内訳',
    DELIVERY_DETAILS: '明細一覧',
    PAYEE: '振込先',
    PAYMENT_DEADLINE: '振込期限',
    PAYMENT_DATE: '入金日',
    VEHICLE: '車両',
    PRODUCT_NAME: '品名',
    QUANTITY: '個数',
    UNIT_PRICE: '単価',
    UNIT: '単位',
    SECTION: '区間',
    DATE: '月日',
    AMOUNT: '金額',
    REIMBURSABLE_COST: '立替経費',
    SUBTOTAL: '小計',
    HAS_TAX: '税',
    COMPANY: '会社',
    OFFICE: '事業所',
    DELIVERY_DETAIL: {
      ID: '案件No',
      PICK_UP_DATE: '積日',
      FIRST_DESTINATION_ADDRESS: '積地',
      START_AT: '卸日',
      LAST_DESTINATION_ADDRESS: '卸地',
      PRODUCT_NAME: '品名',
      VEHICLE_OPTION: '運賃の基準',
      VEHICLE_COUNT: '台数',
      TOTAL_WEIGHT: '重量(t)',
      SHIPPING_FEE: '運賃',
      FUEL_SURCHARGE: '燃料サーチャージ料',
      HIGHWAY_FEE: '有料道路利用料(税込)',
      CARGO_HANDLING_FEE: '荷役料',
      OTHERS_FEE: 'その他料金',
      ADJUSTMENT_FEE: '調整金額',
      BASIC_FEE_MEMO: '備考',
      TAX_RATE: '税区分',
      TAX: '消費税',
      SUBTOTAL_WITH_TAX: '合計(税込)',
    },
  },
}

export const BILL_SETTINGS = {
  LABELS: {
    SORT_TARGET: '項目',
    IS_SORT_DESC: '順序',
  },
}

export const PAYMENT_STATEMENTS = {
  LABELS: {
    BASIC_INFO: '基本情報',
    COMPANY: '会社',
    OFFICE: '事業所',
    PAYMENT_NUMBER: '支払書番号',
    ISSUANCE_DATE: '発行日',
    PAYMENT_TERM: '支払期間',
    START_AT: '支払期間(開始)',
    END_AT: '支払期間(終了)',
    PAYEE: '支払先',
    PAYMENT_DEADLINE: '支払期限',
    PAYMENT_AMOUNT: '支払金額',
    AMOUNT: '合計金額',
    DETAIL: '明細一覧',
    PAYER_INFO: '支払元情報',
    PAYER_NAME: '会社名',
    ZIP_CODE: '郵便番号',
    ADDRESS: '住所',
    PHONE_NUMBER: '電話番号',
    FAX_NUMBER: 'FAX番号',
    INVOICE_REGISTRATION_NUMBER: '適格請求書発行事業者登録番号',
    STATUSES: '支払明細書ステータス',
    APPROVE_STATUS: '承認状況',
    PAYMENT_STATUS: '支払状況',
    DELIVERY_DETAIL: {
      ID: 'No',
      PICK_UP_DATE: '積日',
      START_AT: '卸日',
      LAST_DESTINATION_BUILDING: '納入先建物名',
      FIRST_DESTINATION_ADDRESS: '発地',
      LAST_DESTINATION_ADDRESS: '着地',
      VEHICLE_NUMBER: '車番',
      PRODUCT_NAME: '品名',
      TAX_RATE: '税区分',
      SHIPPING_FEE: '運賃',
      FUEL_SURCHARGE: '燃料サーチャージ料',
      HIGHWAY_FEE: '有料道路利用料(税込)',
      CARGO_HANDLING_FEE: '荷役料',
      OTHERS_FEE: 'その他料金',
      ADJUSTMENT_FEE: '調整金額',
      TAX: '消費税',
      SUBTOTAL_WITH_TAX: '合計(税込)',
    },
  },
}



export const NOTIFICATIONS = {
  LABELS: {
    DATE: '期限',
    NOTIFICATION_TYPE: '種別',
    COMPANY: '会社',
    OFFICE: '事業所',
    NAME: '氏名（車両名）',
    CONTENT: 'アラート内容',
  },
}



export const ARTICLES = {
  LABELS: {
    DESTINATION: '送信先',
    CONTENT: 'お知らせ内容',
    COMPANY: '会社',
    OFFICE: '事業所',
    USER_NAME: '投稿者',
    USER_ORGANIZATION: '投稿者所属',
    PUBLISH_STATUS: '公開状態',
    TITLE: 'タイトル',
    DESCRIPTION: '内容',
    FILES: '添付ファイル',
    UPDATED_AT: '更新日時',
    ARTICLE_MAIL: 'お知らせメール',
  },
  NUMBERS: {
    // nginxの設定でリクエストサイズは100MBで制限している。
    // 添付ファイル以外のデータのサイズ確保分として99MBで設定。
    MAX_SIZE_FILE: 99 * 1024 * 1024, // 99MB in bytes
  },
}

export const USER_ARTICLES = {
  LABELS: {
    COMPANY: '会社',
    OFFICE: '事業所',
    USER_NAME: 'ドライバー',
    CREATED_AT: '閲覧日時',
    STATUS: '確認状況',
  },
}

export const FUND_CONTACTS = {
  LABELS: {
    COMPANY_NAME: '会社名',
    OFFICE_NAME: '事業所名',
    USER_NAME: '名前',
    CONTACT_ADDRESS: '連絡先メールアドレス',
    PHONE_NUMBER: '電話番号',
    CONTACT_TYPE: 'ご相談内容',
    MEMO: '備考',
  },
}

export const CSV_HEADER_ASSOCIATIONS = {
  LABELS: {
    DRIVER_IMPORT: {
      LOGIN_ID: 'ログインID',
      PASSWORD: 'パスワード',
      OFFICE_NAME: '事業所',
      ROLE: '権限',
      EMPLOYEE_NUMBER: '社員番号',
      LEDGER_CREATED_DATE: '台帳作成年月日',
      NAME: '姓名',
      NAME_KANA: '姓名(カナ)',
      GENDER: '性別',
      BIRTHDAY: '生年月日',
      BLOOD_TYPE: '血液型',
      EMAIL: 'メールアドレス',
      PHONE_NUMBER: '電話番号(社用)',
      PERSONAL_PHONE_NUMBER1: '電話番号(自宅/個人携帯用1)',
      PERSONAL_PHONE_NUMBER2: '電話番号(自宅/個人携帯用2)',
      ZIP_CODE: '郵便番号',
      PREFECTURE: '都道府県',
      ADDRESS: '市区町村以降の住所',
      QUALIFICATION_OPERATION_ASSISTANT: '[資格の種類] 運行補助者',
      QUALIFICATION_OPERATION_MANAGER: '[資格の種類] 運行管理者',
      QUALIFICATION_MAINTENANCE_ASSISTANT: '[資格の種類] 整備管理補助者',
      QUALIFICATION_MAINTENANCE_MANAGER: '[資格の種類] 整備管理者',
      QUALIFICATION_FORKLIFT: '[資格の種類] フォークリフト',
      QUALIFICATION_SLINGING: '[資格の種類] 玉掛け',
      QUALIFICATION_CRANE: '[資格の種類] クレーン',
      ENGAGED_WORK_TYPE: '従事する業務の種類',
      LICENSE_NUMBER: '運転免許証の番号',
      LICENSE_ISSUANCED_DATE: '運転免許証の交付年月日',
      LICENSE_EXPIRED_DATE: '運転免許証の有効期限',
      LICENSE_STANDARD: '[免許証の種類] 普通',
      LICENSE_LIMITED_SEMI_MIDSIZE: '[免許証の種類] 準中型限定',
      LICENSE_SEMI_MIDSIZE: '[免許証の種類] 準中型',
      LICENSE_LIMITED_MIDSIZE: '[免許証の種類] 中型限定',
      LICENSE_MIDSIZE: '[免許証の種類] 中型',
      LICENSE_LARGE: '[免許証の種類] 大型',
      LICENSE_LARGE2: '[免許証の種類] 大型2種',
      LICENSE_LARGE_SPECIAL: '[免許証の種類] 大型特殊',
      LICENSE_SMALL_SPECIAL: '[免許証の種類] 小型特殊',
      LICENSE_TOWING: '[免許証の種類] けん引',
      LICENSE_MEMO: '運転免許証の備考',
      HEALTH_INSURANCE_SUBSCRIBED_DATE: '健康保険の加入年月日',
      HEALTH_INSURANCE_NUMBER: '健康保険番号',
      PENISION_INSURANCE_SUBSCRIBED_DATE: '厚生年金保険の加入年月日',
      PENISION_INSURANCE_NUMBER: '厚生年金保険番号',
      EMPLOYMENT_INSURANCE_SUBSCRIBED_DATE: '雇用保険の加入年月日',
      EMPLOYMENT_INSURANCE_NUMBER: '雇用保険番号',
      INJURY_INSURANCE_SUBSCRIBED_DATE: '労災保険の加入年月日',
      INJURY_INSURANCE_NUMBER: '労災保険番号',
      CAREER_JOINED_DATE: '入社日',
      CAREER_RETIRED_DATE: '退社日',
      CAREER_RETIRED_REASON: '退社理由',
      CAREER_SELECTED_DRIVER_DATE: '運転者選任日',
      CAREER_DIED_DATE: '死亡日',
      CAREER_DIED_REASON: '死亡理由',
      PROFILE_MEMO: '備考',
    },
    VEHICLE_IMPORT: {
      NUMBER: '車両ナンバー',
      UNIQUE_NUMBER: '車台番号',
      OFFICE_NAME: '事業所',
      NAME: '車名',
      REGISTERED_DATE: '登録年月日',
      FIRST_REGISTERED_DATE: '初年度登録年月',
      MANUFACTURE: 'メーカー名',
      PERSON_CAPACITY: '乗車定員',
      WEIGHT_CAPACITY1: '最大積載量',
      WEIGHT_CAPACITY2: '最大積載量(2)',
      WEIGHT_CAPACITY3: '最大積載量(3)',
      TOTAL_WEIGHT1: '車両総重量',
      TOTAL_WEIGHT2: '車両総重量(2)',
      TOTAL_WEIGHT3: '車両総重量(3)',
      TONNAGE: 'トン数(最大積載量)',
      OUTER_LENGTH: '車両外寸(長さ)',
      OUTER_WIDTH: '車両外寸(幅)',
      OUTER_HEIGHT: '車両外寸(高さ)',
      ENGINE_DISPLACEMENT: '総排気量',
      FUEL_TYPE: '燃料の種類',
      FUEL_TANK_CAPACITY1: '燃料タンクの容量1',
      FUEL_TANK_CAPACITY2: '燃料タンクの容量2',
      FUEL_TANK_CAPACITY3: '燃料タンクの容量3',
      FUEL_TANK_CAPACITY4: '燃料タンクの容量4',
      ETC_DEVICE: '車載器番号',
      ETC_CARD1: 'ETCカード会社/カード番号1',
      ETC_CARD2: 'ETCカード会社/カード番号2',
      ETC_CARD3: 'ETCカード会社/カード番号3',
      ETC_CARD4: 'ETCカード会社/カード番号4',
      ETC_CARD5: 'ETCカード会社/カード番号5',
      PURCHASED_PRICE: '購入価格(円)',
      MEMO: 'メモ',
    },
  },
}

export const HEAD_TEXTS = {
  DOWNLOAD: 'ダウンロード',
  DOCUMENT_VIEW: '資料を見る',
  VIDEO_VIEW: '動画を見る',
  WEB_TEST: 'Webテスト',
  COMMENT: 'コメント',
  DOWNLOAD_EDUCATION_DOCUMENT: '安全教育の資料',
  ACCIDENT_VIDEO: '事故動画',
  NEAR_MISS_VIDEO: 'ヒヤリハット動画',
  KYT_VIDEO: '危険予知訓練(KYT)',
  BUSINESS_VIDEO: '業務教育',
}

export const ERRORS_TEXTS = {
  FORBIDDEN: 'このページの閲覧権限がありません',
  INTERNAL_SERVER:
    'サーバが混み合っています。しばらく時間を置いてからもう一度お試しください。\n\nシステム管理者が対応中ですので、今しばらくお待ちください。',
  NOT_FOUND: 'データが見つかりませんでした。',
  TOO_MANY_REQUESTS:
    'サーバが混み合っています。\n\nお忙しいところご不便・ご迷惑をおかけしてしまい、大変申し訳ございません\n\nしばらく時間を置いてからもう一度お試しください。',
  UNAUTHORIZED: 'システムにログインしてください',
  UNKNOWN:
    '予期せぬエラーが発生しました。\nお忙しいところご不便・ご迷惑をおかけしてしまい、大変申し訳ございません。\nシステム管理者が対応中ですので、今しばらくお待ちください。',
  EXCEEDED_MAX_FILE_SIZE: '※ 合計ファイル容量は、最大100MBまでとなります。',
  EXCEEDED_MAX_VIDEO_FILE_SIZE: '※ 動画の合計容量は、最大100MBまでとなります。',
}

export const ALERT_TEXT = {
  LONG_TIME_EXPORT:
    '出力件数次第ではダウンロード完了までに数十秒以上かかる場合があります。',
  ALL_DATA_EXPORT: '全てのデータが出力されますがよろしいですか？',
  ARTICLE_SEND_MAIL:
    'チェックをして保存した場合、送信先の所属従業員にお知らせが作成、更新されたことをメールで通知します。（従業員管理でメールアドレスが登録されている従業員にのみ通知されます）',
  RESTRICTION_BY_EDUCATION_ANNUAL_PLAN:
    '年間計画によって受講が制限されています',
  AVI_VIDEO_ALERT_CREATE_OR_UPDATE:
    'モビポケでは添付形式の動画は再生できません。保存後に再生可能な形式(mp4)に変換した動画が自動で添付されます。',
  AVI_VIDEO_ALERT_SHOW_PENDING:
    'モビポケでは添付形式の動画は再生できないため、再生可能な形式(mp4)に変換中です。一定時間経過後に画面を再表示ください。',
  AVI_VIDEO_ALERT_SHOW_SUCCESS:
    'モビポケでは添付形式の動画は再生できません。再生可能な形式(mp4)に変換した動画を添付しているため、そちらを再生ください。表示されない場合は画面を更新してください。',
  AVI_VIDEO_ALERT_SHOW_FAILED:
    'モビポケでは添付の形式の動画は再生できません。また再生可能な形式への変換にも対応していません。',
  UNSUBSCRIBED_ALERT_MESSAGE:
    'ご契約のプランでは一部項目の入力が制限されています。',
}

export const TOOLTIP_TEXTS = {
  NOTIFICATION_AUTO_CREATE: '登録、更新時にアラート情報を自動作成します。',
  DRIVER_NOTIFICATION_TARGET_USER:
    'アラート表示対象には、ドライバー本人、システム管理者、ドライバーの所属事業所の事業所責任者が設定されます。',
  VEHICLE_NOTIFICATION_TARGET_USER:
    'アラート表示対象には、システム管理者、車両の所属事業所の事業所責任者が設定されます。',
  INSPECTION_THREE_MONTHS_DEADLINE:
    'なおアラート情報の対応期限には定期点検(3ヶ月)実施日の3ヶ月後が設定されます。',
  NOTIFICATION_IS_EDUCATION_TARGET:
    '未チェックの場合は、受講状況(受講済・未受講)に関わらず、教育管理の各メニュー(受講履歴一覧、未受講者一覧等)で表示されません。',
  REFUELINGS_INCREMENTAL_MILEAGE:
    '前回給油時の累計走行距離を元に自動計算されます。(前回の累計走行距離が未入力の場合は計算されません。)',
}

export const LOCAL_STORAGE_ORDER_KEY = 'order'

export const LOCAL_STORAGE_ORDER_BY_KEY = 'orderBy'

export const LOCAL_STORAGE_DISPLAY_LIMIT_KEY = 'displayLimit'

export const LOCAL_STORAGE_PAGE_NUMBER_KEY = 'pageNumber'

export const LOCAL_STORAGE_SEARCH_PARAMS_KEY = 'searchParams'

export const LOCAL_STORAGE_VISIBLE_COLUMNS_KEY = 'visibleColumns'

export const LOCAL_STORAGE_USER_ID = 'userId'

export const LOCAL_STORAGE_USER_NAME = 'userName'

export const LOCAL_STORAGE_PAGE_KEY = {
  DRIVERS: 'drivers',
  VEHICLES: 'vehicles',
  VEHICLE_INSPECTION_PLANS: 'vehicle_inspection_plans',
  REFUELINGS: 'refuelings',
  REFUELING_CARDS: 'refueling_cards',

  COMPANIES: 'companies',
  PARTNER_COMPANIES: 'partner_companies',
  BILLS: 'bills',
  PAYMENT_STATEMENTS: 'payment_statements',

  ACCIDENTS: 'accidents',
  ALCOHOL_CHECKS: 'alcohol_checks',
  TIMECARD_RECORDS: 'timecard/records',
  TIMECARD_CATEGORY: 'timecard/settings/category',
  TIMECARD_ALERTS: 'timecard/management/alerts',
  TIMECARD_DASHBOARD: 'timecard/dashboard',
  TIMECARD_APPROVALS_PAGE_NUMBER: 'timecard/management/approvals',
  TIMECARD_AGGREGATES_PAGE_NUMBER: 'timecard/management/aggregates',
  TIMECARD_LEAVE_REQUESTS_PAGE_NUMBER: 'timecard/management/leave_requests',
  LEAVE_REQUESTS: 'timecard/managmenet/leave_requests',
  NOTIFICATIONS: 'notifications',
  EDUCATION_ATTENDANCES: 'educations/attendances',
  EDUCATION_VIDEO_LOGS: 'educations/video_logs',
  EDUCATION_ANNUAL_PLAN: 'educations/annual_plan',
  EDUCATION_COMMENTS: 'educations/comments',
  DELIVERY_REQUESTS: 'delivery_requests',
  SUBSCRIPTION_DELIVERY_REQUESTS: 'subscription_delivery_requests',
  DELIVERY_DETAILS: 'delivery_details',
  DELIVERY_REQUEST_STATUSES: 'delivery_requests/delivery_statuses',
  PROPOSAL_DELIVERIES: 'delivery_requests/proposal_deliveries',
  ARTICLES: 'articles',
  ARTICLES_MAINTENANCES: 'articles/maintenances',
  USER_ARTICLES: 'user_articles',

}

export const APPLICATION_NAME = 'モビポケ'

export const PAGE_TITLES = {
  INDEX: `${APPLICATION_NAME} | アラート一覧`,
  NOTIFICATIONS: {
    INDEX: `${APPLICATION_NAME} | アラート管理`,
    NEW: `${APPLICATION_NAME} | アラートの新規作成`,
    SHOW: `${APPLICATION_NAME} | アラートの詳細`,
    EDIT: `${APPLICATION_NAME} | アラートの編集`,
  },
  EDUCATIONS: {
    INDEX: `${APPLICATION_NAME} | 資料一覧`,
    VIDEOS: `${APPLICATION_NAME} | 動画教材`,
    VIDEO_LOGS: `${APPLICATION_NAME} | 動画視聴履歴`,
    SALES: {
      INDEX: `${APPLICATION_NAME} | 経営管理ダッシュボード`,
    },
    ATTENDANCES: {
      INDEX: `${APPLICATION_NAME} | 受講履歴`,
      EDIT: `${APPLICATION_NAME} | 受講履歴の編集`,
      NEW: `${APPLICATION_NAME} | 受講履歴の作成`,
      SHOW: {
        1: `${APPLICATION_NAME} | 受講履歴1. 事業用自動車を運転する場合の心構え`,
        2: `${APPLICATION_NAME} | 受講履歴2. 事業用自動車の安全運行を確保するための遵守事項`,
        3: `${APPLICATION_NAME} | 受講履歴3. 事業用自動車の構造上の特性`,
        4: `${APPLICATION_NAME} | 受講履歴4. 貨物の正しい積載方法`,
        5: `${APPLICATION_NAME} | 受講履歴5. 過積載の危険性`,
        6: `${APPLICATION_NAME} | 受講履歴6. 危険物を運搬する場合に留意すべき事項`,
        7: `${APPLICATION_NAME} | 受講履歴7. 適切な運行の経路および当該経路における道路および交通の状況`,
        8: `${APPLICATION_NAME} | 受講履歴8. 危険の予測および回避ならびに緊急時における対応方法`,
        9: `${APPLICATION_NAME} | 受講履歴9. 運転者の運転適性に応じた安全運転`,
        10: `${APPLICATION_NAME} | 受講履歴10. 交通事故に関わる運転者の生理的および心理的要因ならびにこれらへの対処方法`,
        11: `${APPLICATION_NAME} | 受講履歴11. 健康管理の重要性`,
      },
    },
    TESTS: {
      INDEX: `${APPLICATION_NAME} | WEBテスト`,
      1: `${APPLICATION_NAME} | WEBテスト1. 事業用自動車を運転する場合の心構え`,
      2: `${APPLICATION_NAME} | WEBテスト2. 事業用自動車の安全運行を確保するための遵守事項`,
      3: `${APPLICATION_NAME} | WEBテスト3. 事業用自動車の構造上の特性`,
      4: `${APPLICATION_NAME} | WEBテスト4. 貨物の正しい積載方法`,
      5: `${APPLICATION_NAME} | WEBテスト5. 過積載の危険性`,
      6: `${APPLICATION_NAME} | WEBテスト6. 危険物を運搬する場合に留意すべき事項`,
      7: `${APPLICATION_NAME} | WEBテスト7. 適切な運行の経路および当該経路における道路および交通の状況`,
      8: `${APPLICATION_NAME} | WEBテスト8. 危険の予測および回避ならびに緊急時における対応方法`,
      9: `${APPLICATION_NAME} | WEBテスト9. 運転者の運転適性に応じた安全運転`,
      10: `${APPLICATION_NAME} | WEBテスト10. 交通事故に関わる運転者の生理的および心理的要因ならびにこれらへの対処方法`,
      11: `${APPLICATION_NAME} | WEBテスト11. 健康管理の重要性`,
      12: `${APPLICATION_NAME} | WEBテスト12. 安全性の向上を図るための装置を備える事業用自動車の適切な運転方法`,
    },
    COMMENTS: {
      INDEX: `${APPLICATION_NAME} | コメント履歴`,
      NEW: `${APPLICATION_NAME} | コメントの作成`,
    },
    EXPORT_CSV: `${APPLICATION_NAME} | CSV出力`,
    ANNUAL_PLANS: {
      INDEX: `${APPLICATION_NAME} | 年間計画`,
      NEW: `${APPLICATION_NAME} | 年間計画の新規作成`,
      SHOW: `${APPLICATION_NAME} | 年間計画の詳細`,
      EDIT: `${APPLICATION_NAME} | 年間計画の編集`,
    },
    SETTINGS: `${APPLICATION_NAME} | 各種設定`,
  },
  ALCOHOL_CHECKS: {
    INDEX: `${APPLICATION_NAME} | アルコールチェック`,
    NEW: `${APPLICATION_NAME} | アルコールチェックの新規作成`,
    SHOW: `${APPLICATION_NAME} | アルコールチェックの詳細`,
  },
  TIMECARDS: {
    INDEX: `${APPLICATION_NAME} | 勤怠打刻`,
    EDIT: `${APPLICATION_NAME} | 勤怠打刻の編集`,
    REPORTS: {
      AGGREGATES: `${APPLICATION_NAME} | 勤怠実績`,
      ALERTS: `${APPLICATION_NAME} | 労務時間管理`,
      APPROVAL: `${APPLICATION_NAME} | 申請承認`,
    },
    LEAVE_REQUESTS: {
      EDIT: `${APPLICATION_NAME} | 休暇申請の編集`,
      INDEX: `${APPLICATION_NAME} | 休暇申請の一覧`,
      NEW: `${APPLICATION_NAME} | 休暇申請の新規作成`,
    },
    DASHBORD: `${APPLICATION_NAME} | 労務ダッシュボード`,
    VEHICLE_ALLOCATION_TABLE: `${APPLICATION_NAME} | 配車表`,
    SETTING: {
      CATEGORY: {
        EDIT: `${APPLICATION_NAME} | 勤務区分の編集`,
        NEW: `${APPLICATION_NAME} | 勤務区分の新規作成`,
        INDEX: `${APPLICATION_NAME} | 勤務区分一覧`,
        SHOW: `${APPLICATION_NAME} | 勤務区分の詳細`,
      },
    },
    SETTINGS: `${APPLICATION_NAME} | 各種設定`,
    CATEGORIES: {
      EDIT: `${APPLICATION_NAME} | 勤務区分の編集`,
      NEW: `${APPLICATION_NAME} | 勤務区分の新規作成`,
      INDEX: `${APPLICATION_NAME} | 勤務区分一覧`,
      SHOW: `${APPLICATION_NAME} | 勤務区分の詳細`,
    },
  },
  ACCIDENTS: {
    INDEX: `${APPLICATION_NAME} | 事故報告管理`,
    NEW: `${APPLICATION_NAME} | 事故報告の新規作成`,
    SHOW: `${APPLICATION_NAME} | 事故報告の詳細`,
    EDIT: `${APPLICATION_NAME} | 事故報告の編集`,
  },
  BILLS: {
    INDEX: `${APPLICATION_NAME} | 請求書管理`,
    NEW: `${APPLICATION_NAME} | 請求書の新規作成`,
    SHOW: `${APPLICATION_NAME} | 請求書の詳細`,
    EDIT: `${APPLICATION_NAME} | 請求書の編集`,
    SETTINGS: `${APPLICATION_NAME} | 各種設定`,
  },
  PAYMENT_STATEMENTS: {
    INDEX: `${APPLICATION_NAME} | 支払明細管理`,
    NEW: `${APPLICATION_NAME} | 支払明細書の新規作成`,
    SHOW: `${APPLICATION_NAME} | 支払明細書の詳細`,
    EDIT: `${APPLICATION_NAME} | 支払明細書の編集`,
    SETTINGS: `${APPLICATION_NAME} | 各種設定`,
  },

  DELIVERY_DETAILS: {
    INDEX: `${APPLICATION_NAME} | 明細管理`,
    NEW: `${APPLICATION_NAME} | 明細の新規作成`,
    SHOW: `${APPLICATION_NAME} | 明細の詳細`,
    EDIT: `${APPLICATION_NAME} | 明細の編集`,
  },
  DELIVERY_REQUESTS: {
    INDEX: `${APPLICATION_NAME} | 案件管理`,
    NEW: `${APPLICATION_NAME} | 案件の新規作成`,
    SHOW: `${APPLICATION_NAME} | 案件の詳細`,
    EDIT: `${APPLICATION_NAME} | 案件の編集`,
    SETTINGS: `${APPLICATION_NAME} | 各種設定`,
  },
  SUBSCRIPTION_DELIVERY_REQUESTS: {
    INDEX: `${APPLICATION_NAME} | 定期案件管理`,
    NEW: `${APPLICATION_NAME} | 定期案件の新規作成`,
    SHOW: `${APPLICATION_NAME} | 定期案件の詳細`,
  },
  PROPOSAL_DELIVERIES: {
    INDEX: `${APPLICATION_NAME} | オンライン打診`,
    NEW: `${APPLICATION_NAME} | オンライン打診の新規作成`,
    SHOW: `${APPLICATION_NAME} | オンライン打診の詳細`,
    EDIT: `${APPLICATION_NAME} | オンライン打診の編集`,
  },
  VEHICLES: {
    INDEX: `${APPLICATION_NAME} | 車両管理`,
    NEW: `${APPLICATION_NAME} | 車両の新規作成`,
    SHOW: `${APPLICATION_NAME} | 車両の詳細`,
    EDIT: `${APPLICATION_NAME} | 車両の編集`,
  },
  VEHICLE_INSPECTION_PLANS: {
    INDEX: `${APPLICATION_NAME} | 車両定期点検計画`,
  },
  REFUELINGS: {
    INDEX: `${APPLICATION_NAME} | 給油履歴の管理`,
    NEW: `${APPLICATION_NAME} | 給油履歴の新規作成`,
    SHOW: `${APPLICATION_NAME} | 給油履歴の詳細`,
    EDIT: `${APPLICATION_NAME} | 給油履歴の編集`,
  },

  REFUELING_CARDS: {
    INDEX: `${APPLICATION_NAME} | 給油カード設定の管理`,
    NEW: `${APPLICATION_NAME} | 給油カード設定の新規作成`,
    SHOW: `${APPLICATION_NAME} | 給油カード設定の詳細`,
    EDIT: `${APPLICATION_NAME} | 給油カード設定の編集`,
  },


  VEHICLE_MANAGEMENTS: {
    SETTINGS: `${APPLICATION_NAME} | 各種設定`,
  },
  DRIVERS: {
    INDEX: `${APPLICATION_NAME} | ドライバー管理`,
    NEW: `${APPLICATION_NAME} | ドライバーの新規作成`,
    SHOW: `${APPLICATION_NAME} | ドライバーの詳細`,
    EDIT: `${APPLICATION_NAME} | ドライバーの編集`,
  },
  COMPANIES: {
    INDEX: `${APPLICATION_NAME} | 会社管理`,
    NEW: `${APPLICATION_NAME} | 会社の新規作成`,
    SHOW: `${APPLICATION_NAME} | 会社の詳細`,
    EDIT: `${APPLICATION_NAME} | 会社の編集`,
  },
  PARTNER_COMPANIES: {
    INDEX: `${APPLICATION_NAME} | 取引先管理`,
    NEW: `${APPLICATION_NAME} | 取引先の新規作成`,
    SHOW: `${APPLICATION_NAME} | 取引先の詳細`,
    EDIT: `${APPLICATION_NAME} | 取引先の編集`,
  },
  CONTACT_ADDRESSES: {
    INDEX: `${APPLICATION_NAME} | 通知設定`,
    EDIT: `${APPLICATION_NAME} | 通知設定の編集`,
  },
  CSV_SETTINGS: {
    INDEX: `${APPLICATION_NAME} | CSV設定`,
    DRIVER_IMPORT: `${APPLICATION_NAME} | 従業員CSVインポート設定`,
    VEHICLE_IMPORT: `${APPLICATION_NAME} | 車両CSVインポート設定`,
  },
  FUND_CONTACTS: {
    INDEX: `${APPLICATION_NAME} | 問い合わせ履歴`,
    NEW: `${APPLICATION_NAME} | 経営・資金相談問い合わせ`,
    COMPLETE: `${APPLICATION_NAME} | 送信完了`,
  },
  HELPS: {
    INDEX: `${APPLICATION_NAME} | ヘルプ`,
    MANUALS: {
      NOTIFICATIONS: `${APPLICATION_NAME} | アラート管理機能について`,
    },
  },
  EXECUTIVES: {
    INDEX: `${APPLICATION_NAME} | 経営者メニュー`,
  },
}

export const PARTNER_COMPANY_RELATION_CATEGORY = {
  OWNER: 'owner',
  CARRIER: 'carrier',
}

export const PRD_DOMAIN = 'yourtruck.jp'
export const STG_DOMAIN = 'stg.yourtruck.jp'

export const EXECUTIVE_USER_ROUTE_PATH = '/executives'
export const TAX_RATE = 0.1

export const DELIVERY_REQUEST_TIME_INTERVAL = 5
