import * as React from 'react'

type Props = {
  children?: React.ReactNode
  user: User
  isXmileAdmin: boolean
  isSystemAdmin: boolean
  isAssistantStaff: boolean
  isGroupParentCompanyAdmin: boolean
  isDriverCommonAccount: boolean
  isExecutive: boolean
  fullName: string
  company: CompanyDetail
  office: OfficeDetail
  subscribedCsvSettings: boolean
}

export const UserContext = React.createContext<User | undefined>(undefined)

export const UserProvider = (props: Props) => {
  const [user] = React.useState(props.user)

  return (
    <UserContext.Provider
      value={{
        ...user,
        // userが持つ値はsnake_caseのため置き換え
        officeId: user.office_id,
        belongToCompanyId: user.office_id ? user.office_id : user.company_id,
        isXmileAdmin: props.isXmileAdmin,
        isSystemAdmin: props.isSystemAdmin,
        isDriverCommonAccount: props.isDriverCommonAccount,
        isAssistantStaff: props.isAssistantStaff,
        isGroupParentCompanyAdmin: props.isGroupParentCompanyAdmin,
        isExecutive: props.isExecutive,
        fullName: props.fullName,
        company: props.company,
        office: props.office,
        subscribedCsvSettings: props.subscribedCsvSettings,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}
