import useOfficeUsers from 'components/apis/users/useOfficeUsers'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'

export interface Option {
  value: number
  label: string
}

interface Props {
  selectedUsers: number[]
  officeId?: number
  onSelectedUsersChange: (selectedOptions: Option[]) => void
}

const MultipleSelectOfficeUsers: React.FC<Props> = ({
  selectedUsers,
  officeId,
  onSelectedUsersChange,
}) => {
  const { users, loading, error } = useOfficeUsers(officeId)  // officeId を渡す
  const [options, setOptions] = useState<Option[]>([])

  useEffect(() => {
    if (!loading && !error && users.length > 0) {
      const options = users.map((user) => ({
        value: user.id,
        label: user.name,
      }))
      setOptions(options)
    }
  }, [users, loading, error, selectedUsers, onSelectedUsersChange])

  const selectedOptions = options.filter((option) =>
    selectedUsers.includes(option.value)
  )

  return (
    <div>
      <Select
        isMulti
        options={options}
        value={selectedOptions}
        onChange={onSelectedUsersChange}
        closeMenuOnSelect={false}
        menuPortalTarget={document.body}
        placeholder="ドライバーを選択"
      />
    </div>
  )
}

export default MultipleSelectOfficeUsers
