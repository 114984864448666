import * as React from 'react'
import Router from 'router/Router'
import { UserProvider } from '../providers/UserProvider'
import Modal from 'react-modal'
import * as Sentry from '@sentry/react'
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary'
import ErrorFallback from './pages/errors/ErrorFallback'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { ThemeProvider } from '@mui/material'
import { theme } from './theme'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.ENV,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// Railsが吐き出すHTMLテンプレートのbody部分をルートエレメントに設定
// これがないと稀にエラーが発生し、ページの挙動が変わる場合がある。
Modal.setAppElement('.main')

const App = (props: {
  user: User
  subscribed: boolean
  isXmileAdmin: boolean
  isSystemAdmin: boolean
  isAssistantStaff: boolean
  isGroupParentCompanyAdmin: boolean
  isDriverCommonAccount: boolean
  isExecutive: boolean
  fullName: string
  company: CompanyDetail
  office: OfficeDetail
  subscribedCsvSettings: boolean
}) => {
  const handleError = useErrorHandler()

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
        retryDelay: 0,
        cacheTime: 0,
        onError: (e: AxiosError) => handleError(e.response.status),
      },
      mutations: {
        onError: (e: AxiosError) => handleError(e.response.status),
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider
        user={props.user}
        isXmileAdmin={props.isXmileAdmin}
        isSystemAdmin={props.isSystemAdmin}
        isAssistantStaff={props.isAssistantStaff}
        isGroupParentCompanyAdmin={props.isGroupParentCompanyAdmin}
        isDriverCommonAccount={props.isDriverCommonAccount}
        isExecutive={props.isExecutive}
        fullName={props.fullName}
        company={props.company}
        office={props.office}
        subscribedCsvSettings={props.subscribedCsvSettings}
      >
        <ThemeProvider theme={theme}>
          {props.subscribed && <Router />}
        </ThemeProvider>
      </UserProvider>
    </QueryClientProvider>
  )
}

function AppWithErrorBoundary(props: {
  user: User
  subscribed: boolean
  isXmileAdmin: boolean
  isSystemAdmin: boolean
  isAssistantStaff: boolean
  isGroupParentCompanyAdmin: boolean
  isDriverCommonAccount: boolean
  isExecutive: boolean
  fullName: string
  company: CompanyDetail
  office: OfficeDetail
  subscribedCsvSettings: boolean
}) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {/* App内でuseErrorHandler()を使う為に、ErrorBoundaryとコンポーネントを分けています */}
      <App {...props}></App>
    </ErrorBoundary>
  )
}

export default AppWithErrorBoundary
