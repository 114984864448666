import * as React from 'react'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { palette } from 'components/theme'
import {
  CustomLabel,
  CustomForm,
  CustomInput,
  CustomTextArea,
  CustomDropZone,
  ErrorMessage,
  WithRequiredBadge,
  CustomSelect,
  CustomRadioGroup,
  CustomRadioButton,
  WithDescriptionTooltipIcon,
  CustomCheckBoxForm,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { ARTICLES, ERRORS_TEXTS, VALIDATION_MESSAGES } from 'commons/constants'
import { Controller, useForm } from 'react-hook-form'
import { useErrorHandler } from 'react-error-boundary'
import { toastOnError } from 'commons/toaster'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { createArticle, updateArticle } from 'components/apis/articles'
import FormSubmitSelectPageModal from 'components/atoms/FormSubmitSelectPageModal'
import ProcessingModal from 'components/atoms/ProcessingModal'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { fetchS3Images } from 'commons/fetchS3Images'
import { styled } from '@mui/styles'
import SearchCompanyAndOffice from 'components/atoms/ReactHookFormPartials/SearchForm/SearchCompanyAndOffice'
import { UserContext } from 'providers/UserProvider'
import { ENUMS } from 'commons/enums'
import { ALERT_TEXT } from 'commons/constants'
import { slackNotification } from 'commons/slackNotification'
import { isVideoFile, isUnPlayableFile } from 'commons/video'
import VideoPlayerModal from 'components/atoms/videos/VideoPlayerModal'
import { VideoViewButton } from 'components/atoms/videos/VideoViewButton'
import {
  articleVideoUrlStyle,
  articleVideoFlexBoxStyle,
} from 'components/theme'
import { useNavigate } from 'react-router-dom'

interface Props {
  type: 'create' | 'update'
  data?: Article
}

export default function ArticleMaintenancesForm(props: Props) {
  const handleError = useErrorHandler()
  const [isModalShow, setModalShow] = React.useState(false)
  const [isProcessingModalShow, setIsProcessingModalShow] =
    React.useState(false)
  const [files, setFiles] = React.useState<File[]>([])
  // 添付動画のModalの状態管理
  const [isVideoModalShow, setIsVideoModalShow] = React.useState(false)
  // 動画ファイルのURLの状態管理
  const [videoUrl, setVideoUrl] = React.useState('')
  const navigate = useNavigate()

  const doMethod = props.type === 'create' ? createArticle : updateArticle
  const user = React.useContext(UserContext)

  const mutation = useMutation({
    mutationFn: doMethod,
    onError: (e: AxiosError<{ errors: string[] }>) => {
      setIsProcessingModalShow(false)
      switch (e.response.status) {
        case 422:
          toastOnError(e.response.data.errors.join(','))
          // 422エラー時にslack通知する
          slackNotification(e, user)
          break
        default:
          handleError(e.response.status)
      }
    },
  })

  // 更新時の初期値
  const defaultValues = {
    ...props.data,
    isDraft: props.type == 'update' ? props.data.isDraft : '1',
    sendMail: false,
  }

  // フォームオブジェクト
  const {
    control,
    register,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { errors },
  } = useForm<Article>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: defaultValues,
  })

  // S3から画像URLを取得してFileオブジェクトに変換
  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3Images(defaultValues.fileUrls)

      setFiles(files)
    })()
  }, [defaultValues?.fileUrls])

  // 添付するファイルの合計サイズを制限している。
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      let totalSize = files.reduce((total, file) => total + file.size, 0)
      totalSize += acceptedFiles.reduce((total, file) => total + file.size, 0)

      if (totalSize >= ARTICLES.NUMBERS.MAX_SIZE_FILE) {
        toastOnError(ERRORS_TEXTS.EXCEEDED_MAX_FILE_SIZE)
      } else {
        setFiles([...files, ...acceptedFiles])
      }
    },
    [files]
  )

  const formatParamsData = (data) => {
    const formData = new FormData()
    files.map((file) => {
      formData.append('files[]', file)
    })
    return { data, formData }
  }

  const submit = async (callback: () => void) => {
    setModalShow(false)
    setIsProcessingModalShow(true)
    const hasNotError = await trigger()
    if (hasNotError) {
      callback()
    } else {
      toastOnError('入力内容に不備があります')
      setIsProcessingModalShow(false)
    }
  }

  const submitCallback = async (to: 'show' | 'index') => {
    setIsProcessingModalShow(true)
    // getValues()の戻り値をそのまま渡すと送信後にフォームの値が元に戻ってしまう不具合が発生した為、deepCopyした値を渡して対処している
    // https://github.com/X-Mile/track-manager/pull/795#discussion_r1034714181
    const copy = structuredClone(getValues())
    const { data, formData } = formatParamsData(copy)
    mutation.mutate(
      { data, formData },
      {
        onSuccess: (res) => {
          scrollTo({ top: 0 })
          if (to === 'show') {
            navigate(`/admin/articles/maintenances/${res.data.id}/`)
          } else if (to === 'index') {
            navigate(`/admin/articles/maintenances?focus_id=${res.data.id}`)
          }
        },
        onSettled: () => {
          setIsProcessingModalShow(false)
        },
      }
    )
  }

  const handleModalCancelSelected = () => {
    setModalShow(false)
  }

  const ColumnGroup = styled('div')({
    display: 'inline-flex',
    flexDirection: 'column',
    width: '100%',
  })

  const Section = styled('div')({
    margin: '0 1rem 1rem 1rem',
  })

  const HeadingText = styled('p')({
    fontWeight: 'bold',
    marginTop: 0,
  })

  const LabelText = styled('span')({
    display: 'flex',
    alignItems: 'center',
    color: palette.text.gray.pale,
  })

  const CustomCheckBoxFormContain = styled('div')({
    marginTop: '10px',
  })

  const selectLists = [
    { label: '本社のみ(事業所以外)', value: 'all_head_offices' },
  ]
  const [companySelectType, setCompanySelectType] = React.useState(
    props.data?.destinationType != null
      ? 'destinationTypeSelect'
      : 'companyOfficeSelect'
  )

  const ArticleFormCompanyOfficeSelect = () => {
    return (
      <SearchCompanyAndOffice
        companyKeyName={'destinationCompanyId'}
        officeKeyName={'destinationOfficeId'}
        control={control}
        getValues={getValues}
        setValue={setValue}
        watch={watch}
        companyListPlaceholder={'全会社'}
        officeListPlaceholder={'全社'}
        disabled={companySelectType != 'companyOfficeSelect'}
      />
    )
  }

  const showVideoModal = (video) => {
    setIsVideoModalShow(true)
    setVideoUrl(URL.createObjectURL(video))
  }

  const CompanyOfficeSelectWithDestinationType = () => {
    return (
      <>
        <CustomLabel>
          <label style={{ marginRight: '20px' }}>
            <input
              type="radio"
              value="companyOfficeSelect"
              onChange={(e) => {
                setCompanySelectType(e.target.value)
                setValue('destinationType', undefined)
              }}
              checked={companySelectType === 'companyOfficeSelect'}
            />
            会社、事業所から選択
          </label>
          <ArticleFormCompanyOfficeSelect />
        </CustomLabel>

        <CustomLabel>
          <label style={{ marginRight: '20px' }}>
            <input
              type="radio"
              value="destinationTypeSelect"
              onChange={(e) => {
                setCompanySelectType(e.target.value)
                setValue('destinationCompanyId', null)
                setValue('destinationOfficeId', null)
              }}
              checked={companySelectType === 'destinationTypeSelect'}
            />
            会社の種別から指定
          </label>
          <Controller
            name="destinationType"
            control={control}
            rules={{ required: companySelectType == 'destinationTypeSelect' }}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                options={selectLists}
                value={selectLists?.find((c) => c.value === value)}
                onChange={(option: ReactSelectOptionProps) => {
                  onChange(option.value)
                }}
                isDisabled={companySelectType != 'destinationTypeSelect'}
                placeholder="未選択"
              />
            )}
          />
          {errors.destinationType && (
            <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
          )}
        </CustomLabel>
      </>
    )
  }

  return (
    <>
      <ColumnGroup>
        <CustomForm>
          <Section>
            <BackGroundWhite>
              <HeadingText>{ARTICLES.LABELS.DESTINATION}</HeadingText>
              {user.isXmileAdmin || user.isGroupParentCompanyAdmin ? (
                <CompanyOfficeSelectWithDestinationType />
              ) : (
                <ArticleFormCompanyOfficeSelect />
              )}
            </BackGroundWhite>
          </Section>

          <Section>
            <BackGroundWhite>
              <HeadingText>{ARTICLES.LABELS.CONTENT}</HeadingText>
              <CustomLabel>
                <WithRequiredBadge>{ARTICLES.LABELS.TITLE}</WithRequiredBadge>
                <CustomInput {...register('title', { required: true })} />
                {errors.title && (
                  <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
                )}
              </CustomLabel>
              <CustomLabel>
                <WithRequiredBadge>
                  {ARTICLES.LABELS.DESCRIPTION}
                </WithRequiredBadge>
                <CustomTextArea
                  rows={10}
                  {...register('description', { required: true })}
                />
                {errors.description && (
                  <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
                )}
              </CustomLabel>
              <CustomLabel>
                {ARTICLES.LABELS.FILES}
                <Controller
                  render={() => (
                    <CustomDropZone
                      multiple={true}
                      onDrop={onDrop}
                      maxSize={ARTICLES.NUMBERS.MAX_SIZE_FILE}
                      onDropRejected={() => {
                        toastOnError(ERRORS_TEXTS.EXCEEDED_MAX_FILE_SIZE)
                      }}
                    />
                  )}
                  name="files"
                  control={control}
                />
                {Object.values(files).map((file, index) => (
                  <FlexBox
                    flexDirection={'row'}
                    key={index}
                    style={articleVideoFlexBoxStyle}
                  >
                    {isVideoFile(file) && (
                      <>
                        <VideoPlayerModal
                          isModalOpen={isVideoModalShow}
                          setIsModalOpen={setIsVideoModalShow}
                          videoUrl={videoUrl}
                        />
                        <VideoViewButton
                          onClick={() => showVideoModal(file)}
                          disabled={isUnPlayableFile(file)}
                        />
                      </>
                    )}
                    <p style={articleVideoUrlStyle}>
                      <a
                        href={URL.createObjectURL(file)}
                        download={decodeURI(file.name)}
                      >
                        {decodeURI(file.name)}
                      </a>
                    </p>
                    <IconButton
                      component="span"
                      onClick={() =>
                        setFiles(files.filter((_, i) => i !== index))
                      }
                    >
                      <CancelIcon />
                    </IconButton>
                    {isUnPlayableFile(file) && (
                      <div style={{ marginTop: '19px' }}>
                        <WithDescriptionTooltipIcon
                          text={`${ALERT_TEXT.AVI_VIDEO_ALERT_CREATE_OR_UPDATE}`}
                        ></WithDescriptionTooltipIcon>
                      </div>
                    )}
                  </FlexBox>
                ))}
              </CustomLabel>
              <CustomLabel>
                <WithRequiredBadge>
                  {ARTICLES.LABELS.PUBLISH_STATUS}
                </WithRequiredBadge>
                <CustomRadioGroup>
                  {Object.entries(ENUMS.ARTICLES.IS_DRAFT).map(
                    ([key, obj], index) => (
                      <React.Fragment key={index}>
                        <CustomRadioButton
                          {...register('isDraft', { required: true })}
                          id={key}
                          value={obj.value}
                          key={index}
                          type="radio"
                          required
                        />
                        <label htmlFor={key}>{obj.label}</label>
                      </React.Fragment>
                    )
                  )}
                </CustomRadioGroup>
                {errors.isDraft && (
                  <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
                )}
              </CustomLabel>
              {/* 公開状態でないと、メール送信はしない */}
              {watch('isDraft') == '0' && (
                <>
                  <CustomCheckBoxFormContain>
                    <FlexBox alignItems="center" justifyContent="flex-start">
                      <Controller
                        name={'sendMail'}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <CustomCheckBoxForm>
                            <label>
                              <input
                                type="checkbox"
                                checked={value as boolean}
                                onChange={() => onChange(!value)}
                              />
                              <LabelText>{'通知メールを送る'}</LabelText>
                            </label>
                          </CustomCheckBoxForm>
                        )}
                      />
                      <WithDescriptionTooltipIcon
                        text={`${ALERT_TEXT.ARTICLE_SEND_MAIL}`}
                      ></WithDescriptionTooltipIcon>
                    </FlexBox>
                  </CustomCheckBoxFormContain>
                </>
              )}
            </BackGroundWhite>
          </Section>
        </CustomForm>
      </ColumnGroup>
      <FlexBox
        justifyContent={'flex-end'}
        sx={{ marginTop: '8px', marginRight: '1rem' }}
      >
        <ApplyButton
          pattern="block"
          onClick={(e) => {
            e.preventDefault()
            setModalShow(true)
          }}
        >
          保存する
        </ApplyButton>
      </FlexBox>
      <FormSubmitSelectPageModal
        isOpen={isModalShow}
        resourceName="お知らせ"
        text={{
          main:
            getValues('isDraft') == true
              ? 'お知らせを非公開状態で保存します。よろしいですか？'
              : getValues('sendMail') == true
              ? `お知らせを公開状態で保存し、メールを送信します。\nよろしいですか？`
              : 'お知らせを公開状態で保存します。よろしいですか？',
          toShow:
            getValues('sendMail') == true
              ? '送信してデータを確認'
              : '保存してデータを確認',
          toIndex:
            getValues('sendMail') == true ? '送信して一覧へ' : '保存して一覧へ',
        }}
        handlers={{
          cancel: handleModalCancelSelected,
          toShow: () => submit(() => submitCallback('show')),
          toIndex: () => submit(() => submitCallback('index')),
        }}
      />
      <ProcessingModal isOpen={isProcessingModalShow} />
    </>
  )
}
