import { AxiosError, AxiosResponse } from 'axios'
import { useContext, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useMutation, useQuery } from '@tanstack/react-query'

import { fetchS3ImageV2 } from 'commons/fetchS3Images'
import { ShouldSubmitOnKeyPress } from 'commons/formUtils'
import { slackNotification } from 'commons/slackNotification'
import { toastOnError, toastOnSuccess } from 'commons/toaster'
import { createCompany, updateCompany } from 'components/apis/companies'
import { UserContext } from 'providers/UserProvider'

export const CompanyFormType = {
  Create: 'create',
  Update: 'update',
} as const

export type CompanyFormType =
  typeof CompanyFormType[keyof typeof CompanyFormType]

export const useCompanyForm = (
  companyFormType: CompanyFormType,
  companyData?: Company
) => {
  const user = useContext(UserContext)
  const handleError = useErrorHandler()
  const navigate = useNavigate()

  const [signatureImage, setSignatureImage] = useState<File>()
  const [isModalShow, setModalShow] = useState(false)
  const [hasMutationBeenCalled, setHasMutationBeenCalled] = useState(false)
  const [isProcessingModalShow, setProcessingModalVisible] = useState(false)

  // 画像設定時のコールバック
  const onInputChange = (e) => {
    const file = e.target.files[0]
    setSignatureImage(file)
  }

  // 更新時の初期値
  const defaultValues = {
    ...companyData,
    hasGMark: companyData?.hasGMark ? companyData.hasGMark : '0',
  }

  useQuery(
    [
      `companies/Form`,
      `${defaultValues?.attachedSignatureImage?.url}`,
      `attachedSignatureImage`,
    ],
    () => fetchS3ImageV2(defaultValues?.attachedSignatureImage),
    {
      enabled: companyFormType === 'update' && signatureImage === undefined,
      onSuccess: (data) => setSignatureImage(data),
      onError: (e: AxiosError) => handleError(e.response.status),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )

  // フォームオブジェクト
  const methods = useForm<Company>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: defaultValues,
  })
  const { getValues, setValue, trigger } = methods

  const mutationMethod =
    companyFormType === 'create' && !hasMutationBeenCalled
      ? createCompany
      : updateCompany

  const handleMutationError = (e: AxiosError<{ errors: string[] }>) => {
    switch (e.response.status) {
      case 422:
        toastOnError(e.response.data.errors.join(','))
        slackNotification(e, user)
        break
      default:
        handleError(e.response.status)
    }
  }

  const handleMutationSuccess = (res: AxiosResponse) => {
    setHasMutationBeenCalled(true)
    setValue('id', res.data.id)
    toastOnSuccess('保存しました')
  }

  const mutation = useMutation({
    mutationFn: mutationMethod,
    onError: handleMutationError,
  })

  const mutationWithoutNavigation = useMutation({
    mutationFn: mutationMethod,
    onSuccess: handleMutationSuccess,
    onError: handleMutationError,
    onSettled: () => setProcessingModalVisible(false),
  })

  const formatParamsData = (data): { data: Company; formData: FormData } => {
    const formData = new FormData()
    if (signatureImage) formData.append('signature_image', signatureImage)

    // subscriptionsを適切な形式に整形
    const formattedData = {
      ...data,
      subscriptions: Object.entries(data.subscriptions || {}).reduce(
        (acc, [key, value]) => {
          if (value) {
            acc[key] = { enabled: value }
          }
          return acc
        },
        {}
      ),
    }

    return { data: formattedData, formData }
  }

  const handleSubmit = async () => {
    const isValid = await trigger(undefined, { shouldFocus: true })
    if (isValid) {
      setProcessingModalVisible(true)
      const clonedValues = structuredClone(getValues())
      const formattedParams = formatParamsData(clonedValues)
      mutationWithoutNavigation.mutate(formattedParams)
      ;(document.activeElement as HTMLElement)?.blur()
    } else {
      toastOnError('入力内容に不備があります')
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (ShouldSubmitOnKeyPress(e)) {
      handleSubmit()
      e.preventDefault()
    }
  }

  const submit = async (callback: () => void) => {
    setModalShow(false)
    const isValid = await trigger(undefined, { shouldFocus: true })
    if (isValid) {
      callback()
    } else {
      toastOnError('入力内容に不備があります')
    }
  }

  const submitCallback = (navigationTarget: 'show' | 'index') => {
    setProcessingModalVisible(true)
    const clonedValues = structuredClone(getValues())
    const formattedParams = formatParamsData(clonedValues)
    mutation.mutate(formattedParams, {
      onSuccess: (res) => {
        scrollTo({ top: 0 })
        if (navigationTarget === 'show') {
          navigate(`/admin/companies/${res.data.id}/`)
        } else if (navigationTarget === 'index') {
          navigate(`/admin/companies?focus_id=${res.data.id}`)
        }
        window.location.reload()
      },
      onSettled: () => setProcessingModalVisible(false),
    })
  }

  const submitHandlers = {
    cancel: () => setModalShow(false),
    toShow: () => submit(() => submitCallback('show')),
    toIndex: () => submit(() => submitCallback('index')),
  }

  return {
    signatureImage,
    isModalShow,
    setModalShow,
    isProcessingModalShow,
    methods,
    handleKeyDown,
    onInputChange,
    submitHandlers,
  }
}
