import * as React from 'react'
import { FlexBox } from 'components/atoms/BoxComponents'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import { deleteVehicle } from 'components/apis/vehicles'
import { useNavigate } from 'react-router-dom'
import { toastOnError } from 'commons/toaster'
import { fetchS3ImagesV2 } from 'commons/fetchS3Images'
import VehicleImage from './sections/VehicleImage'
import OperationStatus from './sections/OperationStatus'
import License from './sections/License'
import BasicInfo from './sections/BasicInfo'
import BodyInfo from './sections/BodyInfo'
import Tools from './sections/Tools'
import Purchase from './sections/Purchase'
import Lease from './sections/Lease'
import TaxBase from './sections/TaxBase'
import TaxWeight from './sections/TaxWeight'
import InsuranceCompulsory from './sections/InsuranceCompulsory'
import InsuranceVoluntary from './sections/InsuranceVoluntary'
import InspectionsAnnually from './sections/InspectionsAnnually'
import InspectionsThreeMonths from './sections/InspectionsThreeMonths'
import InspectionsOthers from './sections/InspectionsOthers'
import Maintenances from './sections/Maintenances'
import PartsReplaced from './sections/PartsReplaced'
import PartsPurchased from './sections/PartsPurchased'
import GreenManagement from './sections/GreenManagement'
import Memo from './sections/Memo'
import Equipment from './sections/Equipment'
import { styled } from '@mui/styles'
import { VEHICLE_LINK_LIST_ITEMS } from 'commons/constants'

type VehicleDetailProps = {
  data: Vehicle
}

const VehicleDetail: React.FC<VehicleDetailProps> = (props) => {
  const navigate = useNavigate()
  const onClickDelete = () => {
    deleteVehicle(props.data.id)
      .then(() => {
        navigate(`/admin/vehicles/`)
      })
      .catch((e) => {
        toastOnError(e.response.errors)
      })
  }

  const [vehicleImages, setVehicleImages] = React.useState<File[]>([])
  const [inspectionAnnuallyImages, setInspectionAnnuallyImages] =
    React.useState<File[]>([])
  const [inspectionThreeMonthImages, setInspectionThreeMonthImages] =
    React.useState<File[]>([])
  const [inspectionOtherImages, setInspectionOtherImages] = React.useState<
    File[]
  >([])
  const [maintenancesImages, setMaintenancesImages] = React.useState<File[]>([])
  const [equipmentImages, setEquipmentImages] = React.useState<File[]>([])
  const [leaseImages, setLeaseImages] = React.useState<File[]>([])
  const [taxBaseImages, setTaxBaseImages] = React.useState<File[]>([])
  const [taxWeightImages, setTaxWeightImages] = React.useState<File[]>([])
  const [insuranceCompulsoryImages, setInsuranceCompulsoryImages] =
    React.useState<File[]>([])
  const [insuranceVoluntaryImages, setInsuranceVoluntaryImages] =
    React.useState<File[]>([])
  const [partsReplacedImages, setPartsReplacedImages] = React.useState<File[]>(
    []
  )
  const [partsPurchasedImages, setPartsPurchasedImages] = React.useState<
    File[]
  >([])
  const [taxBaseChildrenFiles, setTaxBaseChildrenFiles] = React.useState<
    File[][]
  >([])
  const [taxWeightChildrenFiles, setTaxWeightChildrenFiles] = React.useState<
    File[][]
  >([])
  const [leaseChildrenFiles, setLeaseChildrenFiles] = React.useState<File[][]>(
    []
  )
  const [
    insuranceCompulsoryChildrenFiles,
    setInsuranceCompulsoryChildrenFiles,
  ] = React.useState<File[][]>([])
  const [insuranceVoluntaryChildrenFiles, setInsuranceVoluntaryChildrenFiles] =
    React.useState<File[][]>([])
  const [inspectionAnnuallyChildrenFiles, setInspectionAnnuallyChildrenFiles] =
    React.useState<File[][]>([])
  const [
    inspectionThreeMonthsChildrenFiles,
    setInspectionThreeMonthsChildrenFiles,
  ] = React.useState<File[][]>([])
  const [inspectionOthersChildrenFiles, setInspectionOthersChildrenFiles] =
    React.useState<File[][]>([])
  const [maintenanceChildrenFiles, setMaintenanceChildrenFiles] =
    React.useState<File[][]>([])
  const [partsReplacedChildrenFiles, setPartsReplacedChildrenFiles] =
    React.useState<File[][]>([])
  const [partsPurchasedChildrenFiles, setPartsPurchasedChildrenFiles] =
    React.useState<File[][]>([])
  // S3から画像URLを取得してFileオブジェクトに変換
  React.useEffect(() => {
    ;(async () => {
      const images = await fetchS3ImagesV2(props.data?.attachedFiles)
      setVehicleImages(images)
    })()
  }, [props.data?.attachedFiles])
  React.useEffect(() => {
    ;(async () => {
      const images = await fetchS3ImagesV2(
        props.data?.attachedInspectionAnnuallyFiles
      )
      setInspectionAnnuallyImages(images)
    })()
  }, [props.data?.attachedInspectionAnnuallyFiles])
  React.useEffect(() => {
    ;(async () => {
      const images = await fetchS3ImagesV2(
        props.data?.attachedInspectionThreeMonthsFiles
      )
      setInspectionThreeMonthImages(images)
    })()
  }, [props.data?.attachedInspectionThreeMonthsFiles])
  React.useEffect(() => {
    ;(async () => {
      const images = await fetchS3ImagesV2(
        props.data?.attachedInspectionOthersFiles
      )
      setInspectionOtherImages(images)
    })()
  }, [props.data?.attachedInspectionOthersFiles])
  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3ImagesV2(props.data.attachedEquipmentFiles)
      setEquipmentImages(files)
    })()
  }, [props.data.attachedEquipmentFiles])
  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3ImagesV2(props.data.attachedLeaseFiles)
      setLeaseImages(files)
    })()
  }, [props.data.attachedLeaseFiles])
  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3ImagesV2(props.data.attachedTaxBasisFiles)
      setTaxBaseImages(files)
    })()
  }, [props.data.attachedTaxBasisFiles])
  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3ImagesV2(props.data.attachedTaxWeightFiles)
      setTaxWeightImages(files)
    })()
  }, [props.data.attachedTaxWeightFiles])
  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3ImagesV2(
        props.data.attachedInsuranceCompulsoryFiles
      )
      setInsuranceCompulsoryImages(files)
    })()
  }, [props.data.attachedInsuranceCompulsoryFiles])
  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3ImagesV2(
        props.data.attachedInsuranceVoluntaryFiles
      )
      setInsuranceVoluntaryImages(files)
    })()
  }, [props.data.attachedInsuranceVoluntaryFiles])
  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3ImagesV2(props.data.attachedMaintenanceFiles)
      setMaintenancesImages(files)
    })()
  }, [props.data.attachedMaintenanceFiles])
  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3ImagesV2(props.data.attachedPartsReplacedFiles)
      setPartsReplacedImages(files)
    })()
  }, [props.data.attachedPartsReplacedFiles])
  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3ImagesV2(
        props.data.attachedPartsPurchasedFiles
      )
      setPartsPurchasedImages(files)
    })()
  }, [props.data.attachedPartsPurchasedFiles])

  React.useEffect(() => {
    ;(async () => {
      const taxBaseChildrenFiles = await Promise.all(
        props.data.taxBase.map(async (taxBase) => {
          return await fetchS3ImagesV2(taxBase.attachedFiles)
        })
      )
      setTaxBaseChildrenFiles(taxBaseChildrenFiles)
    })()
  }, [props.data.taxBase])

  React.useEffect(() => {
    ;(async () => {
      const taxWeightChildrenFiles = await Promise.all(
        props.data.taxWeight.map(async (taxWeight) => {
          return await fetchS3ImagesV2(taxWeight.attachedFiles)
        })
      )
      setTaxWeightChildrenFiles(taxWeightChildrenFiles)
    })()
  }, [props.data.taxWeight])

  React.useEffect(() => {
    ;(async () => {
      const insuranceCompulsoryChildrenFiles = await Promise.all(
        props.data.insurancesCompulsory.map(async (insuranceCompulsory) => {
          return await fetchS3ImagesV2(insuranceCompulsory.attachedFiles)
        })
      )
      setInsuranceCompulsoryChildrenFiles(insuranceCompulsoryChildrenFiles)
    })()
  }, [props.data.insurancesCompulsory])

  React.useEffect(() => {
    ;(async () => {
      const leaseChildrenFiles = await Promise.all(
        props.data.leases.map(async (lease) => {
          return await fetchS3ImagesV2(lease.attachedFiles)
        })
      )
      setLeaseChildrenFiles(leaseChildrenFiles)
    })()
  }, [props.data.insurancesCompulsory])

  React.useEffect(() => {
    ;(async () => {
      const insuranceVoluntaryChildrenFiles = await Promise.all(
        props.data.insurancesVoluntary.map(async (insuranceVoluntary) => {
          return await fetchS3ImagesV2(insuranceVoluntary.attachedFiles)
        })
      )
      setInsuranceVoluntaryChildrenFiles(insuranceVoluntaryChildrenFiles)
    })()
  }, [props.data.insurancesVoluntary])

  React.useEffect(() => {
    ;(async () => {
      const inspectionAnnuallyChildrenFiles = await Promise.all(
        props.data.inspectionsAnnually.map(async (inspectionAnnually) => {
          return await fetchS3ImagesV2(inspectionAnnually.attachedFiles)
        })
      )
      setInspectionAnnuallyChildrenFiles(inspectionAnnuallyChildrenFiles)
    })()
  }, [props.data.inspectionsAnnually])

  React.useEffect(() => {
    ;(async () => {
      const inspectionThreeMonthsChildrenFiles = await Promise.all(
        props.data.inspectionsThreeMonths.map(
          async (inspectionsThreeMonths) => {
            return await fetchS3ImagesV2(inspectionsThreeMonths.attachedFiles)
          }
        )
      )
      setInspectionThreeMonthsChildrenFiles(inspectionThreeMonthsChildrenFiles)
    })()
  }, [props.data.inspectionsThreeMonths])

  React.useEffect(() => {
    ;(async () => {
      const inspectionOthersChildrenFiles = await Promise.all(
        props.data.inspectionsOthers.map(async (inspectionOthers) => {
          return await fetchS3ImagesV2(inspectionOthers.attachedFiles)
        })
      )
      setInspectionOthersChildrenFiles(inspectionOthersChildrenFiles)
    })()
  }, [props.data.inspectionsOthers])

  React.useEffect(() => {
    ;(async () => {
      const maintenanceChildrenFiles = await Promise.all(
        props.data.maintenances.map(async (maintenance) => {
          return await fetchS3ImagesV2(maintenance.attachedFiles)
        })
      )
      setMaintenanceChildrenFiles(maintenanceChildrenFiles)
    })()
  }, [props.data.maintenances])

  React.useEffect(() => {
    ;(async () => {
      const partsReplacedChildrenFiles = await Promise.all(
        props.data.partsReplaced.map(async (partsReplaced) => {
          return await fetchS3ImagesV2(partsReplaced.attachedFiles)
        })
      )
      setPartsReplacedChildrenFiles(partsReplacedChildrenFiles)
    })()
  }, [props.data.partsReplaced])

  React.useEffect(() => {
    ;(async () => {
      const partsPurchasedChildrenFiles = await Promise.all(
        props.data.partsPurchased.map(async (partsPurchased) => {
          return await fetchS3ImagesV2(partsPurchased.attachedFiles)
        })
      )
      setPartsPurchasedChildrenFiles(partsPurchasedChildrenFiles)
    })()
  }, [props.data.partsPurchased])

  const Section = styled('div')({
    margin: '1rem',
  })

  const inspectionExpireDate =
    props.data.inspectionExpireYear &&
    props.data.inspectionExpireMonth &&
    props.data.inspectionExpireDay
      ? `${props.data.inspectionExpireYear}年${props.data.inspectionExpireMonth}月${props.data.inspectionExpireDay}日`
      : ''

  return (
    <>
      <Section id={VEHICLE_LINK_LIST_ITEMS.VEHICLE_IMAGE.ID}>
        <VehicleImage
          data={{
            name: props.data.name,
            number: props.data.number,
            manufacture: props.data.manufacture,
            model1: props.data.model1,
            model2: props.data.model2,
            modelModified: props.data.modelModified,
            uniqueNumber: props.data.uniqueNumber,
            firstRegisteredDate: props.data.firstRegisteredDate
              ? String(props.data.firstRegisteredDate).slice(0, -3)
              : '',
            inspectionExpireDate: inspectionExpireDate,
            insurancesCompulsory: props.data.insurancesCompulsory,
          }}
          imageFiles={vehicleImages}
        />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.OPERATION_STATUS.ID}>
        <OperationStatus
          data={{
            operationStatus: props.data.operationStatus,
          }}
        />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.EQUIPMENT.ID}>
        <Equipment data={props.data.equipmentsText} />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.LICENSES.ID}>
        <License
          data={{
            standard: props.data.licenseStandard,
            large: props.data.licenseLarge,
            large2: props.data.licenseLarge2,
            largeSpecial: props.data.licenseLargeSpecial,
            midSize: props.data.licenseMidsize,
            limitedMidsize: props.data.licenseLimitedMidsize,
            limitedSemiMidsize: props.data.licenseLimitedSemiMidsize,
            semiMidsize: props.data.licenseSemiMidsize,
            smallSpecial: props.data.licenseSmallSpecial,
            towing: props.data.licenseTowing,
          }}
        />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.BASIC_INFO.ID}>
        <BasicInfo data={props.data} />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.BODY_INFO.ID}>
        <BodyInfo data={props.data} />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.TOOLS.ID}>
        <Tools data={props.data} equipmentImages={equipmentImages} />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.PURCHASE.ID}>
        <Purchase data={props.data} />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.LEASE.ID}>
        <Lease
          data={props.data}
          leaseImages={leaseImages}
          leaseChildrenFiles={leaseChildrenFiles}
        />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.TAX_BASE.ID}>
        <TaxBase
          data={props.data}
          taxBaseImages={taxBaseImages}
          taxBaseChildrenFiles={taxBaseChildrenFiles}
        />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.TAX_WEIGHT.ID}>
        <TaxWeight
          data={props.data}
          taxWeightImages={taxWeightImages}
          taxWeightChildrenFiles={taxWeightChildrenFiles}
        />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.INSURANCE_COMPULSORY.ID}>
        <InsuranceCompulsory
          data={props.data}
          insurancesCompulsoryImages={insuranceCompulsoryImages}
          insuranceCompulsoryChildrenFiles={insuranceCompulsoryChildrenFiles}
        />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.INSURANCE_VOLUNTARY.ID}>
        <InsuranceVoluntary
          data={props.data}
          insurancesVoluntaryImages={insuranceVoluntaryImages}
          insuranceVoluntaryChildrenFiles={insuranceVoluntaryChildrenFiles}
        />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.INSPECTIONS_ANNUALLY.ID}>
        <InspectionsAnnually
          data={props.data}
          inspectionsAnnuallyImages={inspectionAnnuallyImages}
          inspectionAnnuallyChildrenFiles={inspectionAnnuallyChildrenFiles}
        />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.INSPECTIONS_THREE_MONTHS.ID}>
        <InspectionsThreeMonths
          data={props.data}
          inspectionsThreeMonthsImages={inspectionThreeMonthImages}
          inspectionThreeMonthsChildrenFiles={
            inspectionThreeMonthsChildrenFiles
          }
        />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.INSPECTIONS_OTHERS.ID}>
        <InspectionsOthers
          data={props.data}
          inspectionsOthersImages={inspectionOtherImages}
          inspectionOthersChildrenFiles={inspectionOthersChildrenFiles}
        />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.MAINTENANCES.ID}>
        <Maintenances
          data={props.data}
          maintenancesImages={maintenancesImages}
          maintenanceChildrenFiles={maintenanceChildrenFiles}
        />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.PARTS_REPLACED.ID}>
        <PartsReplaced
          data={props.data}
          partsReplacedImages={partsReplacedImages}
          partsReplacedChildrenFiles={partsReplacedChildrenFiles}
        />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.PARTS_PURCHASED.ID}>
        <PartsPurchased
          data={props.data}
          partsPurchasedImages={partsPurchasedImages}
          partsPurchasedChildrenFiles={partsPurchasedChildrenFiles}
        />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.GREEN_MANAGEMENT.ID}>
        <GreenManagement data={props.data} />
      </Section>
      <Section id={VEHICLE_LINK_LIST_ITEMS.MEMO.ID}>
        <Memo data={props.data} />
      </Section>
      <FlexBox
        flexDirection={{ xs: 'column-reverse', sm: 'row' }}
        justifyContent={{ xs: 'space-between' }}
        alignItems={{ xs: 'start', sm: 'center' }}
      >
        <DeleteButton sx={{ margin: 'none' }} onClick={onClickDelete}>
          このデータを削除する
        </DeleteButton>
      </FlexBox>
    </>
  )
}

export default VehicleDetail
