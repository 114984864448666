import * as React from 'react'
import EnhancedTable, { HeadCell } from 'components/atoms/TableComponents'
import {
  ADMIN_ROUTES,
  LOCAL_STORAGE_PAGE_KEY,
  ROUTES,
  USER_EDUCATIONS,
} from 'commons/constants'
import SearchForm from '../education_attendances/SearchForm'
import { UserContext } from 'providers/UserProvider'
import {
  getLocalStorageDisplayLimit,
  getLocalStoragePageNumber,
  getLocalStorageOrder,
  isOrder,
} from 'commons/table'
import { Box } from '@mui/material'
import { FlexBox } from 'components/atoms/BoxComponents'
import { Order } from 'commons/array'

const headCells: readonly HeadCell<UserEducation>[] = [
  {
    id: 'officeName',
    label: USER_EDUCATIONS.LABELS.OFFICE,
    type: 'string',
  },
  {
    id: 'userName',
    label: USER_EDUCATIONS.LABELS.USER_NAME,
    type: 'string',
  },
  {
    id: 'isWebtestText',
    label: USER_EDUCATIONS.LABELS.IS_WEBTEST,
    type: 'string',
  },
  {
    id: 'attendanceDatetime',
    label: USER_EDUCATIONS.LABELS.ATTENDANCE_DATETIME,
    type: 'string',
  },
  {
    id: 'questionCount',
    label: USER_EDUCATIONS.LABELS.QUESTION_COUNT,
    type: 'string',
  },
  {
    id: 'correctCount',
    label: USER_EDUCATIONS.LABELS.CORRECT_COUNT,
    type: 'string',
  },
  {
    id: 'resultText',
    label: '合否',
    type: 'string',
  },
]

const headCellsWithCompany: readonly HeadCell<UserEducation>[] = [
  {
    id: 'companyName',
    label: USER_EDUCATIONS.LABELS.COMPANY,
    type: 'string',
  },
  {
    id: 'officeName',
    label: USER_EDUCATIONS.LABELS.OFFICE,
    type: 'string',
  },
  {
    id: 'userName',
    label: USER_EDUCATIONS.LABELS.USER_NAME,
    type: 'string',
  },
  {
    id: 'isWebtestText',
    label: USER_EDUCATIONS.LABELS.IS_WEBTEST,
    type: 'string',
  },
  {
    id: 'attendanceDatetime',
    label: USER_EDUCATIONS.LABELS.ATTENDANCE_DATETIME,
    type: 'string',
  },
  {
    id: 'questionCount',
    label: USER_EDUCATIONS.LABELS.QUESTION_COUNT,
    type: 'string',
  },
  {
    id: 'correctCount',
    label: USER_EDUCATIONS.LABELS.CORRECT_COUNT,
    type: 'string',
  },
  {
    id: 'resultText',
    label: '合否',
    type: 'string',
  },
]

function AdditionalContent(props: {
  hasAttendenceDate: boolean
  searchParams: SearchUserEducation
}) {
  return (
    <Box mt={2} ml={3}>
      {props.hasAttendenceDate && (
        <></>
      )}
    </Box>
  )
}

export default function EducationAttendancesTable(props: {
  educationId: string
  data: UserEducation[]
  totalCount: number
  paginateParams: PaginateParams
  setPaginateParams: React.Dispatch<React.SetStateAction<PaginateParams>>
  setSearchParams: React.Dispatch<React.SetStateAction<SearchUserEducation>>
  searchParams: SearchUserEducation
  defaultSearchParams: SearchUserEducation
  hasAttendenceDate: boolean
  focusId?: number
  isLoading?: boolean
}) {
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof UserEducation>('id')
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(
    props.paginateParams.displayLimit
  )
  const rows = props.data
  const totalCount = props.totalCount
  const user = React.useContext(UserContext)
  const isOrderBy = (arg): arg is keyof UserEducation => {
    const headCellItems =
      user.isXmileAdmin || user.isGroupParentCompanyAdmin
        ? headCellsWithCompany
        : headCells
    const arr = headCellItems.map((h) => {
      return h.id
    })
    return arr.includes(arg)
  }
  // ソート条件をlocalStorageから取得してset
  React.useEffect(() => {
    const { order, orderBy } = getLocalStorageOrder(
      LOCAL_STORAGE_PAGE_KEY.EDUCATION_ATTENDANCES
    )
    if (isOrder(order) && isOrderBy(orderBy)) {
      setOrder(order)
      setOrderBy(orderBy)
    }
    //「ページあたりの行数」をloalStorageから取得してセット
    const displayLimit = getLocalStorageDisplayLimit()
    const pageNumber = getLocalStoragePageNumber(
      LOCAL_STORAGE_PAGE_KEY.EDUCATION_ATTENDANCES,
      true
    )
    const newPaginateParams = {
      pageNumber: pageNumber,
      displayLimit: displayLimit,
    }
    props.setPaginateParams(newPaginateParams)
    setRowsPerPage(displayLimit)
    setPage(pageNumber)
  }, [])
  return (
    <EnhancedTable<UserEducation>
      {...props}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      page={page}
      setPage={setPage}
      rows={rows}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      headCells={
        user.isXmileAdmin || user.isGroupParentCompanyAdmin
          ? headCellsWithCompany
          : headCells
      }
      totalCount={totalCount}
      localStoragePageKey={LOCAL_STORAGE_PAGE_KEY.EDUCATION_ATTENDANCES}
      basePath={
        user.admin
          ? [ADMIN_ROUTES.EDUCATION_ATTENDANCES_INDEX, props.educationId].join(
              ''
            )
          : ROUTES.EDUCATION_ATTENDANCES_INDEX
      }
      isNewVersion
      searchForm={
        <SearchForm
          setSearchParams={props.setSearchParams}
          setPaginateParams={props.setPaginateParams}
          setPage={setPage}
          defaultSearchParams={props.defaultSearchParams}
          isLoading={props.isLoading}
        />
      }
      additionalContent={
        props.isLoading ? null : (
          <AdditionalContent
            hasAttendenceDate={props.hasAttendenceDate}
            searchParams={props.searchParams}
          />
        )
      }
    />
  )
}
