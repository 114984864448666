import * as React from 'react'
import {
  CustomInput,
  CustomLabel,
  CustomSelect,
  CustomCheckBoxForm,
  WithRequiredBadge,
  ErrorMessage,
} from 'components/atoms/FormComponents'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from 'react-hook-form'
import {
  DRIVERS,
  DRIVING_EXPERIENCES,
  VALIDATION_MESSAGES,
} from 'commons/constants'
import { BackGroundPalePink } from 'components/atoms/BackGroundComponents'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import { styled } from '@mui/styles'
import { FlexBox } from 'components/atoms/BoxComponents'
import { Box } from '@mui/material'
import NumberFormat from 'components/organisms/NumberFormat'

type DrivingExperienceCardProps = {
  register: UseFormRegister<Driver>
  control: Control<Driver>
  errors: FieldErrors<Driver>
  appendEmptyItem: () => void
  deleteItem: (index: number) => void
  index: number
  onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement>
}

const DrivingExperienceCard: React.FC<DrivingExperienceCardProps> = (props) => {
  const vehicleTypeOptions = [
    { value: 'cargo', label: '貨物' },
    { value: 'passenger', label: '乗用車' },
    { value: 'bus', label: 'バス' },
  ]
  const CardTitle = styled('p')({
    fontWeight: 'bold',
  })

  return (
    <BackGroundPalePink sx={{ marginBottom: '30px' }}>
      <CardTitle>
        {DRIVERS.LABELS.DRIVING_EXPERIENCE}
        {props.index + 1}
      </CardTitle>
      <CustomLabel
        sx={{
          marginBottom: '30px',
        }}
      >
        <WithRequiredBadge>
          {DRIVING_EXPERIENCES.LABELS.VEHICLE_TYPE}
        </WithRequiredBadge>
        <Controller
          name={`drivingExperiences.${props.index}.vehicleType`}
          control={props.control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <CustomSelect
              options={vehicleTypeOptions}
              value={vehicleTypeOptions?.find(
                (option) => option.value === value
              )}
              onChange={(option: ReactSelectOptionProps) =>
                onChange(option.value)
              }
              placeholder="未選択"
            />
          )}
        />
        {props.errors?.drivingExperiences?.[props.index]?.vehicleType && (
          <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
        )}
      </CustomLabel>
      <FlexBox flexWrap="wrap">
        <CustomLabel sx={{ marginRight: '32px', marginBottom: '30px' }}>
          {DRIVING_EXPERIENCES.LABELS.CAPACITY}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat
                  {...field}
                  customInput={CustomInput}
                  onKeyDown={props.onInputKeyDown}
                  sx={{ marginRight: '4px', height: '38px', maxWidth: '200px' }}
                />
              )}
              name={`drivingExperiences.${props.index}.capacity`}
              control={props.control}
            />
            人
          </FlexBox>
        </CustomLabel>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {DRIVING_EXPERIENCES.LABELS.LOAD_CAPACITY}
          <FlexBox alignItems="center">
            <Controller
              render={({ field }) => (
                <NumberFormat
                  {...field}
                  customInput={CustomInput}
                  onKeyDown={props.onInputKeyDown}
                  sx={{ height: '38px', maxWidth: '200px', marginRight: '4px' }}
                />
              )}
              name={`drivingExperiences.${props.index}.loadCapacity`}
              control={props.control}
            />
            t
          </FlexBox>
        </CustomLabel>
      </FlexBox>
      <CustomLabel sx={{ marginBottom: '30px', maxWidth: '200px' }}>
        <WithRequiredBadge>
          {DRIVING_EXPERIENCES.LABELS.EXPERIENCE_DATE}
        </WithRequiredBadge>
        <FlexBox flexWrap="wrap">
          <Box sx={{ marginRight: '32px' }}>
            <FlexBox alignItems="center">
              <Controller
                render={({ field }) => (
                  <NumberFormat
                    {...field}
                    customInput={CustomInput}
                    onKeyDown={props.onInputKeyDown}
                    sx={{
                      height: '38px',
                      maxWidth: '200px',
                      marginRight: '4px',
                    }}
                  />
                )}
                name={`drivingExperiences.${props.index}.experienceYear`}
                control={props.control}
                rules={{ required: true }}
              />
              年
            </FlexBox>
            {props.errors?.drivingExperiences?.[props.index]
              ?.experienceYear && (
              <ErrorMessage>{VALIDATION_MESSAGES.required}</ErrorMessage>
            )}
          </Box>
          <Box>
            <FlexBox alignItems="center">
              <Controller
                render={({ field }) => (
                  <NumberFormat
                    {...field}
                    customInput={CustomInput}
                    onKeyDown={props.onInputKeyDown}
                    sx={{
                      height: '38px',
                      maxWidth: '200px',
                      marginRight: '4px',
                    }}
                  />
                )}
                name={`drivingExperiences.${props.index}.experienceMonth`}
                control={props.control}
              />
              月
            </FlexBox>
          </Box>
        </FlexBox>
      </CustomLabel>
      <FlexBox flexDirection="column" sx={{ marginBottom: '30px' }}>
        <CustomLabel sx={{ marginBottom: '30px' }}>
          {DRIVING_EXPERIENCES.LABELS.OFFICE_NAME}
          <CustomInput
            sx={{ height: '38px' }}
            onKeyDown={props.onInputKeyDown}
            {...props.register(`drivingExperiences.${props.index}.officeName`)}
          />
        </CustomLabel>
        <Box sx={{ marginTop: '16px' }}>
          <Box sx={{ mt: 2, mb: 2 }}>
            <CustomLabel>
              {DRIVING_EXPERIENCES.LABELS.VEHICLE_CATEGORY}
            </CustomLabel>
            <FlexBox flexDirection="column" sx={{ gap: '12px' }}>
              <Controller
                name={`drivingExperiences.${props.index}.largeVehicle`}
                control={props.control}
                render={({ field: { onChange, value } }) => (
                  <CustomCheckBoxForm>
                    <label>
                      <input
                        type="checkbox"
                        checked={value as boolean}
                        onChange={() => onChange(!value)}
                      />
                      {DRIVING_EXPERIENCES.LABELS.LARGE_VEHICLE}
                    </label>
                  </CustomCheckBoxForm>
                )}
              />
              <Controller
                name={`drivingExperiences.${props.index}.mediumVehicle`}
                control={props.control}
                render={({ field: { onChange, value } }) => (
                  <CustomCheckBoxForm>
                    <label>
                      <input
                        type="checkbox"
                        checked={value as boolean}
                        onChange={() => onChange(!value)}
                      />
                      {DRIVING_EXPERIENCES.LABELS.MEDIUM_VEHICLE}
                    </label>
                  </CustomCheckBoxForm>
                )}
              />
              <Controller
                name={`drivingExperiences.${props.index}.smallVehicle`}
                control={props.control}
                render={({ field: { onChange, value } }) => (
                  <CustomCheckBoxForm>
                    <label>
                      <input
                        type="checkbox"
                        checked={value as boolean}
                        onChange={() => onChange(!value)}
                      />
                      {DRIVING_EXPERIENCES.LABELS.SMALL_VEHICLE}
                    </label>
                  </CustomCheckBoxForm>
                )}
              />
            </FlexBox>
          </Box>
        </Box>
      </FlexBox>
      <DeleteButton onClick={() => props.deleteItem(props.index)}>
        削除
      </DeleteButton>
    </BackGroundPalePink>
  )
}

export default DrivingExperienceCard
