// 署名あり有効期限ありの画像を取得する
export const fetchS3ImagesV2 = async (urls: AttachedFileType[] | undefined) => {
  return await Promise.all(
    Object.values(urls || []).map((url) => {
      return fetchS3ImageV2(url)
    })
  )
}

// 署名あり有効期限ありの画像を取得する
export const fetchS3ImageV2 = async (image: AttachedFileType) => {
  if (!image) return null
  const { filename, url, type } = image

  const metadata = {
    type: type,
  }
  const data = await (await fetch(url)).blob()
  const file = new File([data], filename, metadata)
  return file
}

// 署名なし有効期限なしの画像を取得する
export const fetchS3Images = async (urls: string[] | undefined) => {
  return await Promise.all(
    Object.values(urls || [])
      .filter((url): url is string => typeof url === 'string' && url !== '')
      .map((url) => {
        return fetchS3Image(url)
      })
  )
}

// 署名なし有効期限なしの画像を取得する
export const fetchS3Image = async (url: string) => {
  if (!url || typeof url !== 'string') return null
  try {
    const path = url.split('/')
    const filename = path[path.length - 1]

    // Use AbortController to handle timeouts
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), 30000) // 30 second timeout

    const response = await fetch(url, {
      signal: controller.signal,
      // Avoid caching issues
      cache: 'no-store',
      // Explicitly set mode to cors for cross-origin requests
      mode: 'cors',
      // Set larger buffer size for large files
      headers: {
        'Accept-Encoding': 'gzip, deflate, br',
      },
    })

    clearTimeout(timeoutId)

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const data = await response.blob()

    // Validate blob data
    if (!data || data.size === 0) {
      throw new Error('Empty blob received')
    }

    const metadata = {
      type: data.type || 'application/octet-stream',
    }

    // For video files, ensure proper MIME type
    if (filename.match(/\.(mp4|mov|avi|wmv|flv|mkv|webm)$/i)) {
      metadata.type = 'video/' + filename.split('.').pop().toLowerCase()
    }

    const file = new File([data], filename, metadata)
    return file
  } catch (error) {
    console.error('Error fetching S3 image:', error, url)
    return null
  }
}
