import * as React from 'react'
import { EducationIcon } from 'components/icons/EducationIcon'
import { AccidentIcon } from 'components/icons/AccidentIcon'
import { BillIcon } from 'components/icons/BillIcon'
import { DeliveryDetailIcon } from 'components/icons/DeliveryDetailIcon'
import { SalesIcon } from 'components/icons/SalesIcon'
import { WorkIcon } from 'components/icons/WorkIcon'
import { InspectionIcon } from 'components/icons/InspectionIcon'
import { MasterIcon } from 'components/icons/MasterIcon'
import { VehicleIcon } from 'components/icons/VehicleIcon'
import { DriverIcon } from 'components/icons/DriverIcon'
import { AlcoholIcon } from 'components/icons/AlcoholIcon'
import { TimecardIcon } from 'components/icons/TimecardIcon'
import { UserVehicleIcon } from 'components/icons/UserVehiclezIcon'
import { NotificationListIcon } from 'components/icons/NotificationListIcon'
import { HelpIcon } from 'components/icons/HelpIcon'
import MailIcon from '@mui/icons-material/Mail'
import { CsvSettingIcon } from 'components/icons/CsvSettingIcon'
import CloudCircleIcon from '@mui/icons-material/CloudCircle'
import ArticleIcon from '@mui/icons-material/Article'
import ApartmentIcon from '@mui/icons-material/Apartment'
import BusinessIcon from '@mui/icons-material/Business'

type MenuName = string

const educationMenus: MenuName[] = [
  'educations',
  'education_documents',
  'education_papertests',
  'education_attendances',
  'education_export_csv',
  'education_videos',
  'education_annual_plans',
  'education_video_logs',
  'education_comments',
  'driving_course_newcomer',
  'education_settings',
]

const billMenus: MenuName[] = [
  'bills',
  'bill_settings',
  'payment_statements',
  'payment_statement_settings',
]

const deliveryMenus: MenuName[] = [
  'delivery_requests',
  'subscription_delivery_requests',
  'delivery_statuses',
  'proposal_deliveries',
  'delivery_request_settings',
]

const vehicleMenus: MenuName[] = [
  'vehicles',
  'inspection_and_accident',
  'refuelings',
  'vehicle_managements_settings',
  'fuel_consumptions',
  'vehicle_managements',
  'vehicle_cost_managements',
]

const timecardMenus: MenuName[] = [
  'timecard',
  'timecard_check_in-out',
  'timecard_record',
  'timecard_management',
  'timecard_setting',
]

const notificationMenus: MenuName[] = [
  'notifications',
  'notification_lists',
  'private_notifications',
]

export const getMenuIcon = (menuName: MenuName): JSX.Element | undefined => {
  if (educationMenus.includes(menuName)) return <EducationIcon />
  if (billMenus.includes(menuName)) return <BillIcon />
  if (deliveryMenus.includes(menuName)) return <WorkIcon />
  if (vehicleMenus.includes(menuName)) return <VehicleIcon />
  if (timecardMenus.includes(menuName)) return <TimecardIcon />
  if (notificationMenus.includes(menuName)) return <NotificationListIcon />

  switch (menuName) {
    case 'accidents':
      return <AccidentIcon />
    case 'delivery_details':
      return <DeliveryDetailIcon />
    case 'sales':
    case 'timecard_dashboard':
      return <SalesIcon />
    case 'vehicle_inspection_plans':
      return <InspectionIcon />
    case 'masters':
      return <MasterIcon />
    case 'drivers':
      return <DriverIcon />
    case 'companies':
      return <ApartmentIcon />
    case 'partner_companies':
      return <BusinessIcon />
    case 'alcohol_checks':
      return <AlcoholIcon />
    case 'vehicle_allocation_table':
      return <UserVehicleIcon />
    case 'helps':
      return <HelpIcon />
    case 'contact_addresses':
      return <MailIcon />
    case 'csv_settings':
      return <CsvSettingIcon />
    case 'resources':
      return <CloudCircleIcon />
    case 'articles':
      return <ArticleIcon />
    default:
      return undefined
  }
}
