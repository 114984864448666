import { styled } from '@mui/styles'
import { Box, Button } from '@mui/material'
import { palette, theme, spacing } from 'components/theme'

export const SidebarContainer = styled(Box)({
  height: '100%',
  minWidth: spacing.sidebarWidth,
  padding: '12px 8px 8px',
  borderRight: 'solid',
  borderRightColor: palette.border.pale,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'block',
  },
})

export const SidebarMenu = styled('div')({
  width: '100%',
  verticalAlign: 'middle',
  whiteSpace: 'pre',
  height: '50px',
  marginTop: '5px',
  lineHeight: '60px',
  display: 'flex',
})

export const CustomLink = styled('a')({
  height: '100%',
  minHeight: '30px',
  padding: 0,
  fontWeight: 'bold',
  fontSize: '20px',
  verticalAlign: 'middle',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  '& span': {
    color: palette.text.gray.deep,
  },
  borderRadius: '4px',
})

export const CustomButton = styled(Button)({
  height: '100%',
  width: '100%',
  minHeight: '30px',
  padding: 0,
  fontWeight: 'bold',
  fontSize: '20px',
  verticalAlign: 'middle',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '& span': {
    color: palette.text.gray.deep,
  },
})

export const ActiveLink = styled(CustomLink)({
  backgroundColor: palette.background.activeMenu,
  '& span': {
    color: palette.text.black.main,
  },
  width: '100%',
})

export const CustomIcon = styled('svg')({
  color: palette.text.black.main,
  width: '30px',
  height: '30px',
  margin: '0 10px',
})

export const BlankSpace = styled('div')({
  height: '70px',
  flexShrink: 0,
})

export const SectionLine = styled('div')({
  border: `1.8px solid ${palette.border.pale}`,
})

export const badgeStyle = {
  '& .MuiBadge-badge': {
    color: 'white',
    backgroundColor: 'red',
    fontSize: '12px',
    fontWeight: 'bold',
    marginLeft: '12px',
  },
}
