import * as React from 'react'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { ARTICLES } from 'commons/constants'
import { getVideoConversionStatuses } from 'components/apis/articles'
import { useQuery } from '@tanstack/react-query'
import {
  FlexBox,
  MarginBox,
  BoxBorderBottom,
} from 'components/atoms/BoxComponents'
import { Typography } from '@mui/material'
import { TextBold } from 'components/atoms/TextComponents'
import { fetchS3Images } from 'commons/fetchS3Images'
import { styled } from '@mui/material'
import VideoPlayerModal from 'components/atoms/videos/VideoPlayerModal'
import {
  VideoViewButton,
  ConversionStatusButton,
  ConversionStatusTooltip,
} from 'components/atoms/videos/VideoViewButton'
import {
  articleVideoUrlStyle,
  articleVideoFlexBoxStyle,
} from 'components/theme'
import {
  isVideoFile,
  isUnPlayableFile,
  checkVideoConversionsStatuses,
} from 'commons/video'

export default function ArticleDetail(props: { data: Article }) {
  const { data } = props
  const [files, setFiles] = React.useState<File[]>([])
  // 添付動画のModalの状態管理
  const [isVideoModalShow, setIsVideoModalShow] = React.useState(false)
  // 動画ファイルのURLの状態管理
  const [videoUrl, setVideoUrl] = React.useState('')

  React.useEffect(() => {
    ;(async () => {
      // Check if fileUrls exists and is not empty
      if (
        data?.fileUrls &&
        Array.isArray(data.fileUrls) &&
        data.fileUrls.length > 0
      ) {
        try {
          const files = await fetchS3Images(data.fileUrls)
          // Filter out null or undefined files
          setFiles(files.filter((file) => file != null))
        } catch (error) {
          console.error('Error fetching S3 images:', error)
          setFiles([])
        }
      } else {
        setFiles([])
      }
    })()
  }, [data?.fileUrls])

  const Section = styled('div')({
    margin: '0 1rem 1rem 1rem',
  })

  const showVideoModal = (video) => {
    try {
      if (!video) {
        console.error('無効な動画ファイル: undefined')
        return
      }

      if (!video.name) {
        console.error('動画ファイル名が不明:', video)
        return
      }

      // ファイルの有効性を確認
      if (!(video instanceof File) || video.size === 0) {
        console.error('無効なファイルオブジェクト:', video)

        // 元のURLを直接使用する代替手段
        const originalUrl = getFileUrl(video.name)
        if (originalUrl) {
          setIsVideoModalShow(true)
          setVideoUrl(originalUrl)
          return
        }
        return
      }

      setIsVideoModalShow(true)

      // HLS形式（m3u8）の場合は動的マニフェストURLまたはS3のURLを使用
      if (video.name.endsWith('.m3u8')) {
        // fileUrlsから対応するファイル情報を探す
        let fileInfo = null

        if (Array.isArray(data.fileUrls) && data.fileUrls.length > 0) {
          if (
            typeof data.fileUrls[0] === 'object' &&
            data.fileUrls[0] !== null
          ) {
            fileInfo = data.fileUrls.find(
              (info) =>
                info && typeof info === 'object' && info.filename === video.name
            )
          }
        }

        if (fileInfo && fileInfo.video_id) {
          // 動的マニフェストエンドポイントのURLを設定
          const manifestUrl = `/api/v1/videos/${fileInfo.video_id}/hls_manifest`
          setVideoUrl(manifestUrl)
        } else {
          // S3のURLを取得（例：data.fileUrlsから対応するURLを探す）
          const hlsUrl = data.fileUrls.find(
            (url) => url && typeof url === 'string' && url.includes(video.name)
          )
          if (hlsUrl) {
            setVideoUrl(hlsUrl)
          } else {
            console.error('HLSファイルのURLが見つかりませんでした:', video.name)
            setVideoUrl('')
          }
        }
      } else {
        // 非HLSファイルの場合、BlobURLを使用
        try {
          // ファイルが有効かどうか再確認
          if (video.size > 0 && video instanceof File) {
            const blobUrl = URL.createObjectURL(video)
            setVideoUrl(blobUrl)

            // BlobURLを記録して後でクリーンアップできるようにする
            window.setTimeout(() => {
              try {
                URL.revokeObjectURL(blobUrl)
              } catch (revokeError) {
                console.error(
                  'BlobURL解放中にエラーが発生しました:',
                  revokeError
                )
              }
            }, 30000) // 30秒後に解放（動画再生に十分な時間）
          } else {
            // ファイルが無効な場合は元のURLを使用
            const originalUrl = getFileUrl(video.name)
            if (originalUrl) {
              setVideoUrl(originalUrl)
            } else {
              throw new Error('有効なファイルでもURLでもありません')
            }
          }
        } catch (blobError) {
          console.error('BlobURL作成中にエラーが発生しました:', blobError)

          // 元のURLを直接使用する代替手段
          const originalUrl = getFileUrl(video.name)
          if (originalUrl) {
            setVideoUrl(originalUrl)
          } else {
            setVideoUrl('')
          }
        }
      }
    } catch (error) {
      console.error('動画URL設定中にエラーが発生しました:', error)
      setVideoUrl('')
    }
  }

  // ファイル名からURLを取得する関数
  const getFileUrl = (filename) => {
    try {
      if (!filename) {
        console.error('ファイル名が指定されていません')
        return null
      }

      if (
        !data.fileUrls ||
        !Array.isArray(data.fileUrls) ||
        data.fileUrls.length === 0
      ) {
        console.error('fileUrlsが無効です:', data.fileUrls)
        return null
      }

      // オブジェクト配列の場合
      if (typeof data.fileUrls[0] === 'object' && data.fileUrls[0] !== null) {
        const fileInfo = data.fileUrls.find(
          (info) =>
            info && typeof info === 'object' && info.filename === filename
        )
        return fileInfo?.url
      }
      // 文字列配列の場合
      else if (typeof data.fileUrls[0] === 'string') {
        return data.fileUrls.find(
          (url) => typeof url === 'string' && url.includes(filename)
        )
      }

      return null
    } catch (error) {
      console.error('ファイルURL取得中にエラーが発生しました:', error)
      return null
    }
  }

  const { data: VideoConversionStatuses } = useQuery(
    [`articles`, `${data?.id}`, `video_conversion_statuses`],
    () => {
      if (!data?.id) return Promise.resolve(null)
      return getVideoConversionStatuses(data.id).then((res) => {
        return res.data
      })
    },
    {
      enabled: !!data?.id,
    }
  )

  return (
    <>
      <Section>
        <BackGroundWhite>
          <FlexBox flexDirection={'column'}>
            <MarginBox sx={{ margin: '1rem' }}>
              <h2>{data?.title}</h2>
              <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                {data?.description}
              </Typography>
            </MarginBox>

            <MarginBox sx={{ margin: '1rem' }}>
              <TextBold>{ARTICLES.LABELS.USER_NAME}</TextBold>
              <BoxBorderBottom>
                <Typography>{data?.creatorDetail}</Typography>
              </BoxBorderBottom>
            </MarginBox>

            <MarginBox sx={{ margin: '1rem' }}>
              <TextBold>{ARTICLES.LABELS.UPDATED_AT}</TextBold>
              <BoxBorderBottom>
                <Typography>{data?.updatedAt}</Typography>
              </BoxBorderBottom>
            </MarginBox>
          </FlexBox>
          {Array.isArray(files) &&
            files.length > 0 &&
            files.map((file, index) => {
              // Skip rendering if file is null or undefined
              if (!file || !file.name) {
                return null
              }

              return (
                <FlexBox
                  flexDirection={'row'}
                  key={index}
                  style={articleVideoFlexBoxStyle}
                >
                  <VideoPlayerModal
                    isModalOpen={isVideoModalShow}
                    setIsModalOpen={setIsVideoModalShow}
                    videoUrl={videoUrl}
                  />
                  {isVideoFile(file) &&
                    (isUnPlayableFile(file) ? (
                      <ConversionStatusButton
                        status={checkVideoConversionsStatuses(
                          file.name,
                          VideoConversionStatuses
                        )}
                      />
                    ) : (
                      <VideoViewButton
                        onClick={() => showVideoModal(file)}
                        disabled={isUnPlayableFile(file)}
                      />
                    ))}
                  <p style={articleVideoUrlStyle}>
                    {getFileUrl(file.name) ? (
                      <a href={getFileUrl(file.name)} download={file.name}>
                        {decodeURI(file.name)}
                      </a>
                    ) : (
                      <span>{decodeURI(file.name)}</span>
                    )}
                  </p>
                  {isUnPlayableFile(file) && (
                    <ConversionStatusTooltip
                      status={checkVideoConversionsStatuses(
                        file.name,
                        VideoConversionStatuses
                      )}
                    />
                  )}
                </FlexBox>
              )
            })}
        </BackGroundWhite>
      </Section>
    </>
  )
}
