import * as React from 'react'
import { styled } from '@mui/styles'
import { Box, Grid, Stack } from '@mui/material'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { APPLICATION_NAME, HEAD_TEXTS, PAGE_TITLES } from 'commons/constants'
import PageWrapper from 'components/organisms/layouts/PageWrapper'
import { fetchEducationVideos } from 'components/apis/educations'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'
import VideoContent from 'components/organisms/educations/VideoContent'

const HeadingText = styled('p')({
  fontWeight: 'bold',
  marginTop: 0,
})

function EducationVideosIndex() {
  const { data: educationVideos, isLoading } = useQuery(
    [`educations/videos`, 'education_videos'],
    () => fetchEducationVideos().then((res) => res.data.educations)
  )

  function VideoWrapper(props: {
    videoType: 'accident' | 'near_miss' | 'kyt' | 'business'
  }) {
    const { videoType } = props
    React.useState<boolean>(false)

    return (
      <BackGroundWhite>
        <HeadingText>
          {HEAD_TEXTS[`${videoType.toUpperCase()}_VIDEO`]}
        </HeadingText>
        <Grid container spacing={2} justifyContent="flex-start">
          {educationVideos
            .filter((education: Education) => education.videoType == videoType)
            .map((education: Education, index: number) => {
              return (
                <React.Fragment key={index}>
                  <VideoContent education={education} />
                </React.Fragment>
              )
            })}
        </Grid>
      </BackGroundWhite>
    )
  }

  return (
    <PageWrapper>
      <Helmet>
        <title>{PAGE_TITLES.EDUCATIONS.VIDEOS ?? APPLICATION_NAME}</title>
      </Helmet>
      <h1>動画教材</h1>
      <Box sx={{ marginTop: '30px' }}>
        {isLoading ? (
          <CircularProgressBox />
        ) : (
          <Stack direction="column" gap={1}>
            <VideoWrapper videoType="accident" />
            <VideoWrapper videoType="near_miss" />
            <VideoWrapper videoType="kyt" />
          </Stack>
        )}
      </Box>
    </PageWrapper>
  )
}

export default EducationVideosIndex
