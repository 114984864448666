// ファイルが動画ファイルか判定する関数
export const isVideoFile = (file: File): boolean => {
  if (file && file.type) {
    // HLS形式（m3u8）も動画ファイルとして扱う
    if (file.name.endsWith('.m3u8')) {
      return true
    }
    return file.type.startsWith('video/')
  }
  return false
}

// ファイルが,再生できないファイル形式か判定する関数
export const isUnPlayableFile = (file: File): boolean => {
  if (file && file.type) {
    // HLS形式（m3u8）は再生可能とする
    if (file.name.endsWith('.m3u8')) {
      return false
    }

    if (file.type === 'video/x-msvideo' || file.type === 'video/mp2t') {
      return true
    }
    // tsファイル形式を読み込めないブラウザがあるため、その応急迂処置
    if (file.type === 'application/octet-stream' && file.name.endsWith('.ts')) {
      return true
    }
  }
  return false
}

// アプリ上で再生できない動画の変換状態をチェックする
export const checkVideoConversionsStatuses = (
  fileName: string,
  statuses
): string | undefined => {
  const videoStatus = statuses?.find((status) => status.file === fileName)
  return videoStatus ? videoStatus.status : undefined
}
