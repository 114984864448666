import * as React from 'react'
import { ACCIDENTS } from 'commons/constants'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  BoxBorderBottom,
  FlexBox,
  MarginBox,
} from 'components/atoms/BoxComponents'
import { Typography } from '@mui/material'
import { TextBold } from 'components/atoms/TextComponents'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import {
  deleteAccident,
  getVideoConversionStatuses,
} from 'components/apis/accidents'
import { convertToStringYen } from 'commons/functions'
import { isUnPlayableFile, checkVideoConversionsStatuses } from 'commons/video'
import CarouselComponent from 'components/atoms/ResponsiveCarousel'
import { UserContext } from 'providers/UserProvider'
import { useNavigate } from 'react-router-dom'
import { toastOnError } from 'commons/toaster'
import { fetchS3ImagesV2 } from 'commons/fetchS3Images'
import { slackNotification } from 'commons/slackNotification'
import { useQuery, useMutation } from '@tanstack/react-query'
import VideoPlayerModal from 'components/atoms/videos/VideoPlayerModal'
import {
  VideoViewButton,
  ConversionStatusButton,
  ConversionStatusTooltip,
} from 'components/atoms/videos/VideoViewButton'
import { videoUrlStyle, videoFlexBoxStyle } from 'components/theme'

export default function AccidentDetail(props: { data: Accident }) {
  const user = React.useContext(UserContext)
  const { data } = props
  const navigate = useNavigate()
  const [accidentFiles, setAccidentFiles] = React.useState<File[]>([])
  const [accidentVideoFiles, setAccidentVideoFiles] = React.useState<File[]>([])
  const [isVideoModalShow, setIsVideoModalShow] = React.useState(false)
  const [videoUlr, setVideoUrl] = React.useState('')
  const deleteMutation = useMutation({
    mutationFn: deleteAccident,
    onSuccess: () => navigate('/accidents/'),
    onError: (e: { response: { errors: string } }) => {
      toastOnError(e.response.errors)
      // 422エラー時にslack通知する
      slackNotification(e, user)
    },
  })
  const onClickDelete = () => {
    deleteMutation.mutate(data.id)
  }
  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3ImagesV2(data.attachedFiles)
      setAccidentFiles(files)
    })()
  }, [data.attachedFiles])
  React.useEffect(() => {
    ;(async () => {
      const files = await fetchS3ImagesV2(data.attachedVideoFiles)
      setAccidentVideoFiles(files)
    })()
  }, [data.attachedVideoFiles])

  const { data: VideoConversionStatuses } = useQuery(
    [`accidents`, `${data.id}`, `video_conversion_statuses`],
    () =>
      getVideoConversionStatuses(data.id).then((res) => {
        return res.data
      })
  )

  const showVideoModal = (video: File) => {
    setIsVideoModalShow(true)
    setVideoUrl(URL.createObjectURL(video))
  }

  return (
    <BackGroundWhite>
      <FlexBox flexDirection={'column'}>
        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ACCIDENTS.LABELS.CONTENT}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.content}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ACCIDENTS.LABELS.DATE}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.date}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ACCIDENTS.LABELS.DRIVER_NAME}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.driverName}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ACCIDENTS.LABELS.VEHICLE_NUMBER}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.vehicleNumber}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ACCIDENTS.LABELS.DAMAGE_AMOUNT}</TextBold>
          <BoxBorderBottom>
            <Typography>{convertToStringYen(data.damageAmount)}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ACCIDENTS.LABELS.ACCIDENT_TYPE}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.accidentTypeText}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ACCIDENTS.LABELS.DAMAGED_OBJECT}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.damagedObjectText}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ACCIDENTS.LABELS.RESPONSIBILITY}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.responsibilityText}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ACCIDENTS.LABELS.PRODUCT_ACCIDENT}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.isProductAccidentText}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ACCIDENTS.LABELS.STATUS}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.statusText}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ACCIDENTS.LABELS.QUICK_REPORT}</TextBold>
          <BoxBorderBottom>
            <Typography>{data.isQuickReportText}</Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ACCIDENTS.LABELS.CAUSE}</TextBold>
          <BoxBorderBottom>
            <Typography sx={{ whiteSpace: 'pre-wrap' }}>
              {data.cause}
            </Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ACCIDENTS.LABELS.MEASURES}</TextBold>
          <BoxBorderBottom>
            <Typography sx={{ whiteSpace: 'pre-wrap' }}>
              {data.measures}
            </Typography>
          </BoxBorderBottom>
        </MarginBox>

        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ACCIDENTS.LABELS.MEMO}</TextBold>
          <BoxBorderBottom>
            <Typography sx={{ whiteSpace: 'pre-wrap' }}>{data.memo}</Typography>
          </BoxBorderBottom>
        </MarginBox>
      </FlexBox>
      <CarouselComponent images={accidentFiles} />
      <FlexBox flexDirection={'column'}>
        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ACCIDENTS.LABELS.IMAGE}</TextBold>
          {Object.values(accidentFiles)?.map((file, index) => (
            <FlexBox flexDirection={'row'} key={index}>
              <p>
                <a
                  href={URL.createObjectURL(file)}
                  download={decodeURI(file.name)}
                >
                  {decodeURI(file.name)}
                </a>
              </p>
            </FlexBox>
          ))}
        </MarginBox>
        <MarginBox sx={{ margin: '1rem' }}>
          <TextBold>{ACCIDENTS.LABELS.VIDEO}</TextBold>
          {/* 変換後のMP4ファイル名を持つファイルのリストを作成 */}
          {(() => {
            // 変換前のファイル名から変換後のファイル名を生成する関数
            const getConvertedFileName = (originalFileName: string) => {
              const baseName = originalFileName.substring(
                0,
                originalFileName.lastIndexOf('.')
              )
              return `${baseName}.mp4`
            }

            // 変換後のMP4ファイルが存在するかどうかを確認する関数
            const hasConvertedFile = (originalFile: File) => {
              const convertedFileName = getConvertedFileName(originalFile.name)
              return Object.values(accidentVideoFiles).some(
                (file) => file.name === convertedFileName
              )
            }

            // 表示するファイルのリストを作成
            // 1. 再生可能なファイル（MP4など）はそのまま表示
            // 2. 再生不可能なファイル（AVIなど）は、変換後のファイルが存在しない場合のみ表示
            const filesToDisplay = Object.values(accidentVideoFiles).filter(
              (videoFile) =>
                !isUnPlayableFile(videoFile) || !hasConvertedFile(videoFile)
            )

            return filesToDisplay.map((videoFile, index) => (
              <FlexBox
                flexDirection={'row'}
                key={index}
                style={videoFlexBoxStyle}
              >
                <VideoPlayerModal
                  isModalOpen={isVideoModalShow}
                  setIsModalOpen={setIsVideoModalShow}
                  videoUrl={videoUlr}
                />
                {isUnPlayableFile(videoFile) ? (
                  <>
                    <ConversionStatusButton
                      status={checkVideoConversionsStatuses(
                        videoFile.name,
                        VideoConversionStatuses
                      )}
                    />
                    <p style={videoUrlStyle}>
                      <a
                        href={URL.createObjectURL(videoFile)}
                        download={videoFile.name}
                      >
                        {decodeURI(videoFile.name)}
                      </a>
                    </p>
                    <ConversionStatusTooltip
                      status={checkVideoConversionsStatuses(
                        videoFile.name,
                        VideoConversionStatuses
                      )}
                    />
                  </>
                ) : (
                  <>
                    <VideoViewButton
                      onClick={() => showVideoModal(videoFile)}
                      disabled={false}
                    />
                    <p style={videoUrlStyle}>
                      <a
                        href={URL.createObjectURL(videoFile)}
                        download={videoFile.name}
                      >
                        {decodeURI(videoFile.name)}
                      </a>
                    </p>
                  </>
                )}
              </FlexBox>
            ))
          })()}
        </MarginBox>
      </FlexBox>

      <DeleteButton sx={{ margin: 'none' }} onClick={onClickDelete}>
        このデータを削除する
      </DeleteButton>
    </BackGroundWhite>
  )
}
