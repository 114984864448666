import * as React from 'react'
import { EDUCATION_ANNUAL_PLANS } from 'commons/constants'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { styled } from '@mui/material'
import { DeleteButton } from 'components/atoms/ButtonComponenets'
import { deleteEducationAnnualPlan } from 'components/apis/educationAnnualPlans'
import { useNavigate } from 'react-router-dom'
import { toastOnError } from 'commons/toaster'
import { useMutation } from '@tanstack/react-query'
import { UserContext } from 'providers/UserProvider'
import { slackNotification } from 'commons/slackNotification'
import { theme } from 'components/theme'
import { CustomLabel } from 'components/atoms/FormComponents'
import { ValueText } from 'components/atoms/TextComponents'
import VideoContent from 'components/organisms/educations/VideoContent'
import { Grid } from '@mui/material'

export default function EducationAnnualPlanDetail(props: {
  data: EducationAnnualPlan
}) {
  const { data } = props
  const navigate = useNavigate()
  const mutation = useMutation({
    mutationFn: deleteEducationAnnualPlan,
    onSuccess: () => navigate(`/admin/educations/annual_plans/`),
    onError: (e: { response: { errors: string } }) => {
      toastOnError(e.response.errors)
      // 422エラー時にslack通知する
      slackNotification(e, user)
    },
  })
  const user = React.useContext(UserContext)

  const Section = styled('div')({
    marginBottom: '1rem',
  })

  const HeadingText = styled('p')({
    fontWeight: 'bold',
    marginTop: 0,
  })

  const DetailCustomLabel: React.FC<{ children?: React.ReactNode | string }> = (
    props
  ) => {
    return (
      <CustomLabel
        sx={{
          marginRight: '32px',
          [theme.breakpoints.down('md')]: {
            marginRight: '0',
          },
        }}
      >
        {props.children}
      </CustomLabel>
    )
  }

  const onClickDelete = () => {
    mutation.mutate(data.id)
  }

  // 日付範囲を表示するための関数
  const formatDateRange = (
    from: string | Date | null | undefined,
    to: string | Date | null | undefined
  ): string => {
    if (!from && !to) return ''

    // 日付をフォーマットする関数
    const formatDate = (date: string | Date | null | undefined): string => {
      if (!date) return ''

      // 日付オブジェクトに変換
      const dateObj =
        typeof date === 'string'
          ? new Date(date)
          : date instanceof Date
          ? date
          : null
      if (!dateObj || isNaN(dateObj.getTime()))
        return typeof date === 'string' ? date : ''

      // 日本語フォーマット（YYYY年MM月DD日）
      const year = dateObj.getFullYear()
      const month = dateObj.getMonth() + 1
      const day = dateObj.getDate()
      return `${year}年${month}月${day}日`
    }

    const fromStr = formatDate(from)
    const toStr = formatDate(to)

    // fromとtoが同じ場合は単一の日付を表示
    if (!fromStr || !toStr || fromStr === toStr) return fromStr || toStr

    // 異なる場合は範囲を表示
    return `${fromStr} 〜 ${toStr}`
  }

  return (
    <>
      <Section>
        <BackGroundWhite>
          <FlexBox flexDirection={'column'}>
            <HeadingText>
              {EDUCATION_ANNUAL_PLANS.LABELS.BASIC_INFO}
            </HeadingText>
            <DetailCustomLabel>
              {EDUCATION_ANNUAL_PLANS.LABELS.ANNUAL_PLAN_TITLE}
              <ValueText value={data.annualPlanTitle} />
            </DetailCustomLabel>
            <DetailCustomLabel>
              {EDUCATION_ANNUAL_PLANS.LABELS.FISCAL_YEAR}
              <ValueText value={`${data.year}年度`} />
            </DetailCustomLabel>
            {user.isXmileAdmin ||
              (user.isGroupParentCompanyAdmin && (
                <DetailCustomLabel>
                  {EDUCATION_ANNUAL_PLANS.LABELS.COMPANY}
                  <ValueText value={data.companyName} />
                </DetailCustomLabel>
              ))}
            <DetailCustomLabel>
              {EDUCATION_ANNUAL_PLANS.LABELS.OFFICE}
              <ValueText value={data.officeName} />
            </DetailCustomLabel>
          </FlexBox>
        </BackGroundWhite>
      </Section>
      {data.subjects.map((subject, index) => (
        <Section key={index}>
          <BackGroundWhite>
            <HeadingText>
              {index + 1}. {subject.education.title}
            </HeadingText>
            <DetailCustomLabel>
              {EDUCATION_ANNUAL_PLANS.LABELS.SCHEDULE}
              <ValueText
                value={formatDateRange(
                  subject.scheduleDateFrom,
                  subject.scheduleDateTo
                )}
              />
            </DetailCustomLabel>
            <DetailCustomLabel>
              {EDUCATION_ANNUAL_PLANS.LABELS.ORGANIZER}
              <ValueText value={subject.organizer} />
            </DetailCustomLabel>
            <DetailCustomLabel>
              {EDUCATION_ANNUAL_PLANS.LABELS.LOCATION}
              <ValueText value={subject.location} />
            </DetailCustomLabel>
            <DetailCustomLabel>
              {EDUCATION_ANNUAL_PLANS.LABELS.CONTENT}
              <ValueText value={subject.content} />
            </DetailCustomLabel>
            <DetailCustomLabel>
              {EDUCATION_ANNUAL_PLANS.LABELS.VIDEO}
              <Grid container spacing={1}>
                {subject.videos?.map((education: Education, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <VideoContent
                        education={education}
                        isShowComment={false}
                      />
                    </React.Fragment>
                  )
                })}
              </Grid>
            </DetailCustomLabel>
          </BackGroundWhite>
        </Section>
      ))}
      <FlexBox
        flexDirection={{ xs: 'column-reverse', sm: 'row' }}
        justifyContent={{ xs: 'space-between' }}
        alignItems={{ xs: 'start', sm: 'center' }}
      >
        <DeleteButton sx={{ margin: 'none' }} onClick={onClickDelete}>
          このデータを削除する
        </DeleteButton>
      </FlexBox>
    </>
  )
}
