import React from 'react'
import { Grid } from '@mui/material'
import {
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form'
import {
  CustomDateSelect,
  CustomLabel,
  CustomTextArea,
  ErrorMessage,
} from 'components/atoms/FormComponents'
import { BackGroundPalePink } from 'components/atoms/BackGroundComponents'
import { styled } from '@mui/styles'
import { formatCustomDateErrorMessage } from 'commons/errorMessage'
import { DeleteButton } from 'components/atoms/ButtonComponenets'

type Props = {
  index: number
  register: UseFormRegister<Driver>
  control: Control<Driver>
  errors: FieldErrors<Driver>
  setValue: UseFormSetValue<Driver>
  onInputKeyDown?: (e: React.KeyboardEvent) => void
  onRemove: () => void
}

const AwardsAndPunishmentCard: React.FC<Props> = ({
  index,
  register,
  control,
  errors,
  onInputKeyDown,
  onRemove,
}) => {
  const CardTitle = styled('p')({
    fontWeight: 'bold',
  })

  const customDateErrorMessage = React.useCallback(() => {
    return formatCustomDateErrorMessage({
      year: errors?.awardsAndPunishments?.[index]?.year,
      month: errors?.awardsAndPunishments?.[index]?.month,
      day: errors?.awardsAndPunishments?.[index]?.day,
    })
  }, [errors?.awardsAndPunishments?.[index]])

  return (
    <BackGroundPalePink>
      <CardTitle>賞罰{index + 1}</CardTitle>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomLabel>
            賞罰年月日
            <CustomDateSelect
              formKeys={{
                year: `awardsAndPunishments.${index}.year`,
                month: `awardsAndPunishments.${index}.month`,
                day: `awardsAndPunishments.${index}.day`,
              }}
              control={control}
              required
            />
            <ErrorMessage>{customDateErrorMessage()}</ErrorMessage>
          </CustomLabel>
        </Grid>
        <Grid item xs={12}>
          <CustomLabel>
            内容
            <CustomTextArea
              {...register(`awardsAndPunishments.${index}.content`, {
                required: true,
              })}
              placeholder="内容を入力してください"
              onKeyDown={onInputKeyDown}
            />
            {errors?.awardsAndPunishments?.[index]?.content && (
              <ErrorMessage>必須項目です</ErrorMessage>
            )}
          </CustomLabel>
        </Grid>
      </Grid>
      <DeleteButton onClick={onRemove}>削除</DeleteButton>
    </BackGroundPalePink>
  )
}

export default AwardsAndPunishmentCard
