import * as React from 'react'
import { CustomVideoPlayer } from 'components/atoms/CustomVideoPlayer'
import CustomCenteringModal from 'components/atoms/CustomCenteringModal'
import ConfirmCloseVideo from './ConfirmCloseVideo'
import ReactPlayer from 'react-player'

type Props = {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  videoUrl: string
}
function VideoPlayerModal({ isModalOpen, setIsModalOpen, videoUrl }: Props) {
  const [playing, setPlaying] = React.useState<boolean>(false)
  const [ended, setEnded] = React.useState<boolean>(false)
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false)

  const videoRef = React.useRef<ReactPlayer>(null)

  const customOpen = () => {
    setPlaying(true)
    setEnded(false)
  }

  const customClose = (): boolean => {
    if (ended) {
      return true
    } else {
      setShowConfirm(true)
      return false
    }
  }

  const onClose = () => {
    setIsModalOpen(false)
  }

  const onCancel = () => {
    setShowConfirm(false)
  }

  return (
    <CustomCenteringModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      customOpen={customOpen}
      customClose={customClose}
      style={VideoPlayerModalStyle}
    >
      {showConfirm && (
        <ConfirmCloseVideo onClose={onClose} onCancel={onCancel} />
      )}
      {/**
       * マウントの度に動画を再取得することを防ぐため、
       * showConfirmがtrueの場合は高さを0pxにすることで非表示にしている。
       * */}
      <CustomVideoPlayer
        videoRef={videoRef}
        videoUrl={videoUrl}
        playing={!showConfirm && playing}
      />
    </CustomCenteringModal>
  )
}

const VideoPlayerModalStyle = {
  aspectRatio: '16 / 9',
  margin: 'auto',
}

export default VideoPlayerModal
