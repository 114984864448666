/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { CircularProgressBox } from 'components/atoms/ProgressComponents'
import { CustomLabel, CustomSelect } from 'components/atoms/FormComponents'
import useEducationUserAttendances from './hooks/useEducatinUserAttendances'
import { AttendanceTable } from './components/AttendanceTable'
import { AttendanceTablePagination } from './components/AttendanceTablePagination'
import MultipleSelectOfficeUsers, {
  Option,
} from './components/MultipleSelectOfficeUsers'
import SearchCompanyAndOffice from 'components/atoms/ReactHookFormPartials/SearchForm/SearchCompanyAndOffice'

interface ReactSelectOptionProps {
  value: string
  label: string
}

function AdminEducationList() {
  const { control, getValues, setValue, watch } = useForm()
  const officeId = watch('officeId')
  const [page, setPage] = React.useState(1)
  const [userIds, setUserIds] = React.useState<number[]>([])
  const [selectedYear, setSelectedYear] = React.useState<string>('')

  // 今年から過去5年分の年度を生成
  const currentYear = new Date().getFullYear()
  const fiscalYears = Array.from({ length: 5 }, (_, i) => currentYear - i).map(
    (year) => ({
      value: String(year),
      label: `${year}年度`,
    })
  )

  // 指定なしオプションを追加
  const yearOptions = [
    { value: '', label: '指定なし（今年度）' },
    ...fiscalYears,
  ]

  const [data, isLoading] = useEducationUserAttendances({
    page,
    officeId,
    userIds,
    fiscalYear: selectedYear ? Number(selectedYear) : null,
  })

  const handleFiscalYearChange = (option: ReactSelectOptionProps) => {
    setSelectedYear(option.value)
    setPage(1)
  }

  const handleSelectedUsersChange = (newSelectedUsers: Option[]) => {
    const selectedUserIds: number[] = newSelectedUsers.map((user) => user.value)
    setUserIds(selectedUserIds)
  }

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= (data?.totalPages || 1)) {
      setPage(newPage)
    }
  }

  React.useEffect(() => {
    setPage(1)
  }, [userIds])

  React.useEffect(() => {
    setPage(1)
  }, [officeId])

  return (
    <>
      <div style={{ marginBottom: '20px' }}>
        <SearchCompanyAndOffice
          companyKeyName={'companyId'}
          officeKeyName={'officeId'}
          control={control}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
        />
        {/* <div>
          <CustomLabel>
            年度
            <Controller
              name="fiscalYear"
              control={control}
              defaultValue=""
              render={({ field: { onChange } }) => (
                <CustomSelect
                  options={yearOptions}
                  value={yearOptions.find((y) => y.value === selectedYear)}
                  onChange={(option: ReactSelectOptionProps) => {
                    onChange(option.value)
                    handleFiscalYearChange(option)
                  }}
                  placeholder="年度を選択"
                />
              )}
            />
          </CustomLabel>
        </div> */}
        <div style={{ marginTop: '16px' }}>
          <MultipleSelectOfficeUsers
            selectedUsers={userIds}
            onSelectedUsersChange={handleSelectedUsersChange}
            officeId={officeId}
          />
        </div>
      </div>

      <AttendanceTablePagination
        page={page}
        totalPages={data?.totalPages || 1}
        onPageChange={handlePageChange}
        isLoading={isLoading}
      />
      {isLoading ? (
        <CircularProgressBox />
      ) : (
        <AttendanceTable data={data?.userAttendances || []} />
      )}
      <AttendanceTablePagination
        page={page}
        totalPages={data?.totalPages || 1}
        onPageChange={handlePageChange}
        isLoading={isLoading}
      />
    </>
  )
}

export default AdminEducationList
