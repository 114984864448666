import * as React from 'react'
import ReactPlayer from 'react-player'

type Props = {
  onUpdate?: (params: VideoPlayerEventProps) => void
  playing?: boolean
  videoRef?: React.MutableRefObject<ReactPlayer>
  videoUrl: string
  isLoop?: boolean
  style?: React.CSSProperties
  onProgress?: () => void
  onDuration?: (params: number) => void
  onSeek?: () => void
  disablePlaybackRate?: boolean
}

export const CustomVideoPlayer = ({
  onUpdate,
  playing = true,
  videoRef,
  videoUrl,
  isLoop,
  style,
  onProgress,
  onDuration,
  onSeek,
  disablePlaybackRate,
}: Props) => {
  function onEnded() {
    onUpdate({
      status: 'done',
    })
  }

  function onReady() {
    onUpdate({
      status: 'doing',
    })
  }

  const config = disablePlaybackRate
    ? {
        forceVideo: true,
        file: {
          attributes: {
            controlsList: 'noplaybackrate',
          },
        },
      }
    : {
        forceVideo: true,
      }

  return (
    <ReactPlayer
      onEnded={onEnded}
      onReady={onReady}
      stopOnUnmount={true}
      config={config}
      controls={true}
      height={playing ? '90%' : '0px'}
      playing={playing}
      ref={videoRef}
      url={videoUrl}
      width="auto"
      loop={isLoop}
      style={style}
      onProgress={onProgress}
      onDuration={onDuration}
      onSeek={onSeek}
    />
  )
}
