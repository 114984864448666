import * as React from 'react'
import { Link } from 'react-router-dom'
import { ExplanationChip } from 'components/atoms/chips/ExplanationChip'

type EducationLinkProps = {
  education: Education
  isThisMonth?: boolean
}

export function EducationListLink(props: EducationLinkProps) {
  const { education, isThisMonth } = props
  return (
    <Link to={`${education.id}/`}>
      {education.month && (
        <span style={{ marginLeft: '8px' }}>({education.month}月)</span>
      )}
      {education.title}
      {isThisMonth && (
        <ExplanationChip label="今月の項目" style={{ marginLeft: '8px' }} />
      )}
    </Link>
  )
}
