import * as React from 'react'
import Badge from '@mui/material/Badge'
import Collapse from '@mui/material/Collapse'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { FlexBox } from 'components/atoms/BoxComponents'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { fetchLockedDriversList } from 'components/apis/drivers'
import {
  PRD_DOMAIN,
  EXECUTIVE_USER_ROUTE_PATH,
  STG_DOMAIN,
} from 'commons/constants'
import { palette } from 'components/theme'
import useMenu from './Menu.hooks'
import { getMenuIcon } from './IconMapping'
import {
  SidebarContainer,
  SidebarMenu,
  CustomLink,
  CustomButton,
  ActiveLink,
  CustomIcon,
  BlankSpace,
  SectionLine,
  badgeStyle,
} from './Sidebar.styles'
import type {
  Menu,
  SidebarProps,
  MenuLinkProps,
  ParentLinkProps,
  RenderIconProps,
} from './types'

const isDeveloping = (menu?: Menu): boolean => {
  return (
    menu?.isDeveloping &&
    (document.domain === PRD_DOMAIN || document.domain === STG_DOMAIN)
  )
}

const RenderIcon: React.FC<RenderIconProps> = ({ menu }) => {
  const icon = getMenuIcon(menu.name)

  return menu.showBadge ? (
    <Badge
      badgeContent={'!'}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      sx={badgeStyle}
    >
      <CustomIcon>{icon}</CustomIcon>
    </Badge>
  ) : (
    <CustomIcon>{icon}</CustomIcon>
  )
}

const MenuLink: React.FC<MenuLinkProps> = ({
  menu,
  currentUserId,
  children,
}) => {
  const isMenuActive = (menuUrl: string, expectedUrl: string) =>
    menuUrl === expectedUrl
  const isActive = isMenuActive(menu.url, window.location.pathname)
  const LinkComponent = isActive ? ActiveLink : CustomLink

  return (
    <LinkComponent
      href={menu.url}
      target={menu.openNewTab ? '_blank' : ''}
      rel={menu.openNewTab ? 'noopener noreferrer' : ''}
    >
      {children}
    </LinkComponent>
  )
}

const ParentLink: React.FC<ParentLinkProps> = ({
  menu,
  isAdmin,
  isCommentable,
}) => {
  const isActive = menu.children
    ? Object.values(menu.children).some(
        (child) => child.url === window.location.pathname
      )
    : false

  const [checked, setChecked] = React.useState(isActive)
  const handleClick = () => setChecked((prev) => !prev)

  return (
    <FlexBox
      flexDirection="column"
      sx={{ alignItems: 'center', width: '100%' }}
    >
      <SidebarMenu>
        <CustomButton onClick={handleClick}>
          <RenderIcon menu={menu} />
          <span>{menu.title}</span>
          {checked ? (
            <ArrowDropUpIcon style={{ color: palette.text.black.main }} />
          ) : (
            <ArrowDropDownIcon style={{ color: palette.text.black.main }} />
          )}
        </CustomButton>
      </SidebarMenu>
      {menu.children &&
        Object.values(menu.children).map((child: Menu, index: number) => {
          if (isDeveloping(child)) return null

          return (
            <Collapse
              in={checked}
              key={index}
              sx={{
                alignSelf: 'self-start',
                width: '100%',
                paddingLeft: '1rem',
              }}
            >
              <SidebarMenu>
                <MenuLink menu={child}>
                  <span>└ </span>
                  <RenderIcon menu={child} />
                  <span>{child.title}</span>
                </MenuLink>
              </SidebarMenu>
            </Collapse>
          )
        })}
    </FlexBox>
  )
}

const Sidebar: React.FC<SidebarProps> = ({
  isAdmin,
  isXmileAdmin,
  isAssistantStaff,
  isExecutive,
  currentUserId,
  isCommonDriverOnTimecardPage,
  company,
}) => {
  const { menus, isCommentable } = useMenu(
    isAdmin,
    isXmileAdmin,
    isAssistantStaff
  )
  const [lockedDrivers, setLockedDrivers] = React.useState([])

  React.useEffect(() => {
    fetchLockedDriversList().then((response) => {
      setLockedDrivers(response.data.drivers)
    })
  }, [])

  // アカウントロックされているユーザーがいる場合バッジを表示する
  if (isAdmin && lockedDrivers.length > 0) {
    menus.masters.children.drivers.showBadge = true
    menus.masters.showBadge = true
  }

  return (
    <SidebarContainer>
      <FlexBox flexDirection="column" style={{ height: '100%' }}>
        {!isCommonDriverOnTimecardPage && <BlankSpace />}
        <FlexBox
          flexDirection="column"
          style={{ height: '100%', overflowY: 'auto' }}
        >
          {(isExecutive || isAdmin) && (
            <ApplyButton pattern="sidebar" href={EXECUTIVE_USER_ROUTE_PATH}>
              経営者メニューを利用する
            </ApplyButton>
          )}

          {Object.values(menus)
            .filter(Boolean)
            .map((menu: Menu, index) => {
              if (isDeveloping(menu)) return null

              return (
                <React.Fragment key={index}>
                  {menu.children ? (
                    <>
                      <ParentLink
                        menu={menu}
                        isAdmin={isAdmin}
                        key={index}
                        isCommentable={isCommentable}
                      />
                      {menu.name === 'articles' && <SectionLine />}
                    </>
                  ) : menu.name === 'notifications' &&
                    !company.show_notifications ? null : (
                    <SidebarMenu>
                      <MenuLink menu={menu} currentUserId={currentUserId}>
                        <RenderIcon menu={menu} />
                        <span>{menu.title}</span>
                      </MenuLink>
                      {menu.name === 'sales' && <SectionLine />}
                    </SidebarMenu>
                  )}
                </React.Fragment>
              )
            })}
        </FlexBox>
      </FlexBox>
    </SidebarContainer>
  )
}

export default Sidebar
