import * as React from 'react'
import { FlexBox } from 'components/atoms/BoxComponents'
import { useForm, Controller } from 'react-hook-form'
import { Box } from '@mui/material'
import { CancelButtonOutlined } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import Modal from 'react-modal'
import { palette } from 'components/theme'
import {
  CustomLabel,
  CustomSelectShort,
  CustomForm,
  CustomDropZone,
} from 'components/atoms/FormComponents'
import { yearRangeFrom2000 } from 'commons/date'
import CompanyAndOfficeSelect from 'components/atoms/ReactHookFormPartials/InputForm/CompanyAndOfficeSelect'
import { UserContext } from 'providers/UserProvider'
import { useImagesUpload } from 'commons/ImagesUploader'
import { fetchS3ImagesV2 } from 'commons/fetchS3Images'

export default function AttachedFilesModal(props: {
  educationId: string
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { educationId } = props
  const user = React.useContext(UserContext)
  const { getValues, setValue, control, watch, handleSubmit } =
    useForm<ExportNonattendanceList>({
      mode: 'onSubmit',
      defaultValues: {
        year: user.company.thisYear || new Date().getFullYear(),
      },
    })
  const [showUploader, setShowUploader] = React.useState(false)
  const { images, removeImage, setImages } = useImagesUpload()
  const [educationAnnualPlanSubject, setEducationAnnualPlanSubject] =
    React.useState<EducationAnnualPlan>()
  const [errorMessage, setErrorMessage] = React.useState('')

  const onSubmit = async () => {
    const data = getValues()
    const endpoint = `/api/v1/education_annual_plan_subjects/${educationId}`
    const params = new URLSearchParams({
      year: data.year?.toString(),
      company_id: data.companyId?.toString(),
      office_id: data.officeId?.toString(),
    })
    const response = await fetch(`${endpoint}?${params?.toString()}`)
    if (response.status == 404) {
      setErrorMessage(
        '当該事業所には年間計画が存在しないため、ファイルを添付することができません。'
      )
      setShowUploader(false)
      return
    }
    const educationAnnualPlanSubject = await response.json()
    setEducationAnnualPlanSubject(educationAnnualPlanSubject)

    if (educationAnnualPlanSubject) {
      // S3から画像URLを取得してFileオブジェクトに変換
      const images = await fetchS3ImagesV2(
        educationAnnualPlanSubject?.files ?? []
      )
      setImages(images)
      setErrorMessage('')
      setShowUploader(true)
    }
  }

  const handleExport = () => {
    const data = getValues()
    const endpoint = `/education_annual_plan_files/${educationId}`
    const params = new URLSearchParams({
      year: data.year?.toString(),
      company_id: data.companyId?.toString(),
      office_id: data.officeId?.toString(),
    })
    const url = `${endpoint}?${params.toString()}`
    window.open(url, '_blank')
  }

  const onDrop = (acceptedFiles: File[]) => {
    if (educationAnnualPlanSubject) {
      const formData = new FormData()
      acceptedFiles.forEach((file) => formData.append('files[]', file))
      fetch(
        `/api/v1/education_annual_plan_subjects/${educationAnnualPlanSubject?.id}`,
        {
          method: 'PATCH',
          body: formData,
        }
      ).then(() => {
        setImages([...images, ...acceptedFiles])
      })
    }
  }

  function closeModal(): void {
    props.setShowModal(false)
    setErrorMessage('')
  }

  return (
    <Modal
      isOpen={props.showModal}
      style={{
        overlay: {
          minHeight: '100%',
          zIndex: 1000,
        },
        content: {
          backgroundColor: palette.background.main,
          marginLeft: 0,
          margin: 'auto',
          display: 'flex',
          flexFlow: 'wrap',
          overFlow: 'hidden',
          flexDirection: 'column',
          justifyContents: 'center',
          alignItems: 'center',
        },
      }}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        props.setShowModal(false)
      }}
    >
      <h2>添付ファイル一覧</h2>
      <Box sx={{ justifyContents: 'left' }}>
        <CustomLabel sx={{ maxWidth: '420px' }}>
          選択した年度、事業所の添付ファイルを検索します。
        </CustomLabel>
        <FlexBox>
          <CustomLabel>
            年度
            <Controller
              name="year"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomSelectShort
                  options={yearRangeFrom2000}
                  value={yearRangeFrom2000?.find((c) => c.value === value)}
                  onChange={(option: ReactSelectOptionProps) =>
                    onChange(option.value)
                  }
                />
              )}
            />
          </CustomLabel>
        </FlexBox>
        <CompanyAndOfficeSelect
          companyKeyName={'companyId'}
          officeKeyName={'officeId'}
          control={control}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
        />
      </Box>
      <FlexBox>
        <CustomForm onSubmit={handleSubmit(onSubmit)}>
          <ApplyButton pattern="block" type="submit">
            検索する
          </ApplyButton>
        </CustomForm>
        {images && images.length > 0 && (
          <ApplyButton pattern="block" onClick={handleExport}>
            ファイルを印刷する
          </ApplyButton>
        )}
      </FlexBox>
      {errorMessage && <p>{errorMessage}</p>}
      {showUploader && (
        <CustomDropZone
          multiple={true}
          onDrop={onDrop}
          accept={'image/*,application/pdf'}
        />
      )}
      <Box>
        {images
          ?.filter((image): image is File => image instanceof File)
          .map((image, index) => (
            <p key={index}>
              <a
                href={URL.createObjectURL(image)}
                download={decodeURI(image.name)}
              >
                {decodeURI(image.name)}
              </a>
            </p>
          ))}
      </Box>
      <div style={{ position: 'absolute', bottom: 20 }}>
        <CancelButtonOutlined onClick={() => closeModal()}>
          閉じる
        </CancelButtonOutlined>
      </div>
    </Modal>
  )
}
