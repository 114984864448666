import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import { UserAttendance } from '../hooks/useEducatinUserAttendances'

type AttendanceTableProps = {
  data: UserAttendance[]
}

export const AttendanceTable: React.FC<AttendanceTableProps> = ({ data }) => {
  const educations = Array.from(
    new Set(
      data[0]?.attendances.map((a) => ({
        id: a.educationId,
        title: a.educationMonth
          ? `${a.educationTitle} (${a.educationMonth}月) `
          : a.educationTitle,
      })) || []
    )
  )
  if (!data.length) {
    return (
      <Card>
        <CardContent>
          <Typography variant="body1" color="text.secondary" align="center">
            データが存在しません
          </Typography>
        </CardContent>
      </Card>
    )
  }

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 200px)' }}>
      <Table stickyHeader aria-label="attendance table">
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                width: 200,
                maxWidth: 200,
                position: 'sticky',
                left: 0,
                top: 0,
                backgroundColor: '#fff',
                zIndex: 0,
              }}
            />
            {data.map((driver, index) => (
              <TableCell
                key={index}
                align="center"
                style={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: '#fff',
                  zIndex: 0,
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant="body2"
                    style={{
                      writingMode: 'vertical-rl',
                    }}
                  >
                    {driver.userName}
                  </Typography>
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {educations.map((education, tIndex) => (
            <TableRow
              key={tIndex}
              sx={{ '&:nth-of-type(odd)': { backgroundColor: 'action.hover' } }}
            >
              <TableCell>
                <Typography
                  variant="body2"
                  style={{
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {}
                  <Link
                    to={`${education.id}/`}
                    style={{ textDecoration: 'none' }}
                  >
                    {tIndex + 1}. {education.title}
                  </Link>
                </Typography>
              </TableCell>
              {data.map((driver, dIndex) => {
                const attendance = driver.attendances.find(
                  (a) => a.educationId === education.id
                )
                return (
                  <TableCell key={dIndex} align="center">
                    {attendance?.attended ? (
                      <CheckIcon color="success" />
                    ) : (
                      <CloseIcon color="error" />
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
