import * as React from 'react'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  HeadingText,
  CustomTextArea,
  CustomDateSelect,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { CAREERS, DRIVERS } from 'commons/constants'
import { Box, styled, useTheme } from '@mui/material'
import { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import CancelIcon from '@mui/icons-material/Cancel'
import Button from '@mui/material/Button'

type CareerDateFormProps = {
  control: Control<Driver>
  setValue: UseFormSetValue<Driver>
  careerIndex: number
  clearable: boolean
  defaultToday?: boolean
}

const ClearButton = styled('button')({
  background: 'transparent',
  padding: 0,
  border: 0,
  cursor: 'pointer',
  marginLeft: '8px',
  display: 'flex',
  alignItems: 'center',
})

const CareerDateForm: React.FC<CareerDateFormProps> = (props) => {
  const handleClearButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    careerIndex: number
  ) => {
    e.preventDefault()
    props.setValue(`careers.${careerIndex}.recordedYear`, null, {
      shouldValidate: true,
    })
    props.setValue(`careers.${careerIndex}.recordedMonth`, null, {
      shouldValidate: true,
    })
    props.setValue(`careers.${careerIndex}.recordedDay`, null, {
      shouldValidate: true,
    })
  }

  const theme = useTheme()

  return (
    <FlexBox flexWrap="wrap" alignItems="center">
      <CustomDateSelect
        formKeys={{
          year: `careers.${props.careerIndex}.recordedYear`,
          month: `careers.${props.careerIndex}.recordedMonth`,
          day: `careers.${props.careerIndex}.recordedDay`,
        }}
        control={props.control}
        defaultToday={props.defaultToday}
      ></CustomDateSelect>

      {props.clearable && (
        <>
          <ClearButton
            onClick={(e) => {
              handleClearButtonClick(e, props.careerIndex)
            }}
            sx={{
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
            }}
          >
            <CancelIcon />
          </ClearButton>
          <Button
            onClick={(e) => {
              handleClearButtonClick(e, props.careerIndex)
            }}
          >
            クリア
          </Button>
        </>
      )}
    </FlexBox>
  )
}

type DriversCareerProps = {
  register: UseFormRegister<Driver>
  control: Control<Driver>
  setValue: UseFormSetValue<Driver>
}

const DriversCareer: React.FC<DriversCareerProps> = (props) => {
  // カテゴリーの表示順序を定義（MAPPINGの順序と一致させる）
  const displayOrder = [
    CAREERS.CATEGORIES.JOINED,                    // 入社日 (0)
    CAREERS.CATEGORIES.CHARTERED_VEHICLE_DRIVER,  // 貸切専任日 (4)
    CAREERS.CATEGORIES.SHARED_VEHICLE_DRIVER,     // 乗合専任日 (5)
    CAREERS.CATEGORIES.RETIRED,                   // 退職日 (1)
    CAREERS.CATEGORIES.DIED,                      // 死亡日 (3)
  ]

  // カテゴリーに対応するラベルを取得
  const getLabelForCategory = (category: string) => {
    switch (category) {
      case CAREERS.CATEGORIES.JOINED:
        return CAREERS.LABELS.JOINED_DATE
      case CAREERS.CATEGORIES.CHARTERED_VEHICLE_DRIVER:
        return CAREERS.LABELS.CHARTERED_DRIVER_DATE
      case CAREERS.CATEGORIES.SHARED_VEHICLE_DRIVER:
        return CAREERS.LABELS.SHARED_DRIVER_DATE
      case CAREERS.CATEGORIES.RETIRED:
        return CAREERS.LABELS.RETIRED_DATE
      case CAREERS.CATEGORIES.DIED:
        return CAREERS.LABELS.DIED_DATE
      default:
        return ''
    }
  }

  return (
    <BackGroundWhite>
      <HeadingText>{DRIVERS.LABELS.CAREER}</HeadingText>
      {displayOrder.map((category) => (
        <Box mb={2} key={category}>
          <CustomLabel>
            {getLabelForCategory(category)}
            <CareerDateForm
              control={props.control}
              careerIndex={CAREERS.MAPPING[category]}
              setValue={props.setValue}
              clearable={category === CAREERS.CATEGORIES.RETIRED || category === CAREERS.CATEGORIES.DIED}
              defaultToday={category === CAREERS.CATEGORIES.JOINED}
            />
          </CustomLabel>
          {(category === CAREERS.CATEGORIES.RETIRED || category === CAREERS.CATEGORIES.DIED) && (
            <CustomLabel>
              {CAREERS.LABELS.REASON}
              <CustomTextArea
                rows={3}
                {...props.register(`careers.${CAREERS.MAPPING[category]}.reason`)}
              />
            </CustomLabel>
          )}
        </Box>
      ))}
    </BackGroundWhite>
  )
}

export default DriversCareer
