import * as React from 'react'
import {
  Control,
  UseFormRegister,
  FieldErrors,
  UseFormGetValues,
} from 'react-hook-form'
import { BackGroundWhite } from 'components/atoms/BackGroundComponents'
import {
  CustomLabel,
  HeadingText,
  ConvertibleCustomDateSelect,
  ConvertibleCustomTextArea,
} from 'components/atoms/FormComponents'
import { FlexBox } from 'components/atoms/BoxComponents'
import { DRIVERS, CAREERS } from 'commons/constants'
import { Box } from '@mui/material'
import { CancelButtonOutlined } from 'components/atoms/ButtonComponenets'
import { ApplyButton } from 'components/atoms/buttons/ApplyButton'
import { useNavigate } from 'react-router-dom'

type CareerProps = {
  control: Control<Driver>
  register: UseFormRegister<Driver>
  errors: FieldErrors<Driver>
  getValues: UseFormGetValues<Driver>
  mode: FormMode
  handleModeChange: (name: DriverFormModeMapKey, mode: FormMode) => void
}

const Career = (props: CareerProps) => {
  const navigate = useNavigate()

  const reload = () => {
    if (
      window.confirm('保存していないデータは削除されます。よろしいですか？')
    ) {
      navigate(0)
    }
  }

  return (
    <BackGroundWhite>
      <FlexBox
        justifyContent="space-between"
        flexWrap="wrap"
        marginBottom="16px"
      >
        <HeadingText>{DRIVERS.LABELS.CAREER}</HeadingText>
        <Box>
          {/* 一時的にコメントアウト */}
          {/* {props.mode == 'show' && (
            <EditButtonOutlined
              onClick={() => {
                props.handleModeChange('career', 'edit')
              }}
            >
              編集
            </EditButtonOutlined>
          )} */}
          {props.mode == 'edit' && (
            <FlexBox>
              <ApplyButton pattern="marginRight8" type="submit">
                保存する
              </ApplyButton>
              <CancelButtonOutlined onClick={reload}>
                キャンセル
              </CancelButtonOutlined>
            </FlexBox>
          )}
        </Box>
      </FlexBox>
      {/* 基本情報（入社日） */}
      <FlexBox alignItems="center" flexWrap="wrap">
        <CustomLabel sx={{ marginRight: '32px' }}>
          {CAREERS.LABELS.JOINED_DATE}
          <ConvertibleCustomDateSelect
            mode={props.mode}
            formKeys={{
              year: `careers.${CAREERS.MAPPING[CAREERS.CATEGORIES.JOINED]}.recordedYear`,
              month: `careers.${CAREERS.MAPPING[CAREERS.CATEGORIES.JOINED]}.recordedMonth`,
              day: `careers.${CAREERS.MAPPING[CAREERS.CATEGORIES.JOINED]}.recordedDay`,
            }}
            control={props.control}
            getValues={props.getValues}
          />
        </CustomLabel>
      </FlexBox>

      {/* 専任情報（貸切・乗合） */}
      <FlexBox alignItems="center" flexWrap="wrap">
        {[CAREERS.CATEGORIES.CHARTERED_VEHICLE_DRIVER, CAREERS.CATEGORIES.SHARED_VEHICLE_DRIVER].map((category) => {
          const label = category === CAREERS.CATEGORIES.CHARTERED_VEHICLE_DRIVER
            ? CAREERS.LABELS.CHARTERED_DRIVER_DATE
            : CAREERS.LABELS.SHARED_DRIVER_DATE
            return (
              <CustomLabel sx={{ marginRight: '32px' }} key={`career-${category}`}>
                {label}
                <ConvertibleCustomDateSelect
                  mode={props.mode}
                  formKeys={{
                    year: `careers.${CAREERS.MAPPING[category]}.recordedYear`,
                    month: `careers.${CAREERS.MAPPING[category]}.recordedMonth`,
                    day: `careers.${CAREERS.MAPPING[category]}.recordedDay`,
                  }}
                  control={props.control}
                  getValues={props.getValues}
                />
              </CustomLabel>
            )
          }
        )}
      </FlexBox>
      {/* ステータス情報（退職・死亡） */}
      {CAREERS.DISPLAY_GROUPS.STATUS.map((category) => {
        const label = category === CAREERS.CATEGORIES.RETIRED
          ? CAREERS.LABELS.RETIRED_DATE
          : CAREERS.LABELS.DIED_DATE
          return (
            <Box key={`career-${category}`}>
              <CustomLabel sx={{ marginBottom: '0px' }}>
                {label}
                <ConvertibleCustomDateSelect
                  mode={props.mode}
                  formKeys={{
                    year: `careers.${CAREERS.MAPPING[category]}.recordedYear`,
                    month: `careers.${CAREERS.MAPPING[category]}.recordedMonth`,
                    day: `careers.${CAREERS.MAPPING[category]}.recordedDay`,
                  }}
                  control={props.control}
                  getValues={props.getValues}
                />
              </CustomLabel>
              <CustomLabel>
                {CAREERS.LABELS.REASON}
                <ConvertibleCustomTextArea
                  mode={props.mode}
                  formKey={`careers.${CAREERS.MAPPING[category]}.reason`}
                  register={props.register}
                  getValues={props.getValues}
                />
              </CustomLabel>
            </Box>
          )
      })}
    </BackGroundWhite>
  )
}

export default Career
